import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import React from 'react';
import { ILegacyMachineStatusItemData } from '../../interfaces/Machine.types';
import { StyledLegacyMachineStatusIndicatorTooltip } from './LegacyMachineStatusIndicatorTooltip.styles';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';

interface MachineStatusIndicatorTooltipProps {
  statusItem: ILegacyMachineStatusItemData;
}
export const LegacyMachineStatusIndicatorTooltip = ({
  statusItem,
}: MachineStatusIndicatorTooltipProps): React.JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledLegacyMachineStatusIndicatorTooltip className="legacy-machine-status-indicator-tooltip">
      <SvgIcon
        name={statusItem.icon}
        className={classnames(
          'legacy-machine-status-indicator-tooltip__icon',
          `legacy-machine-status-indicator-tooltip__icon--${statusItem.icon}`
        )}
      />
      <div className="legacy-machine-status-indicator-tooltip__info">
        <div className="legacy-machine-status-indicator-tooltip__title">{t(statusItem.title)}</div>
        <div className="legacy-machine-status-indicator-tooltip__description">{t(statusItem.description)}</div>
      </div>
    </StyledLegacyMachineStatusIndicatorTooltip>
  );
};
