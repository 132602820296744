import styled from 'styled-components';

export const StyledMachineClassificationIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .machine-classification-indicator__tooltip-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 30px;
      }
    }
  }

  .machine-classification-indicator__icon {
    color: ${(props): string => props.theme.colors.black};
    font-size: 16px;

    &::before {
      margin-left: 0;
    }
  }

  .machine-classification-indicator__icon--classification-non_iot {
    color: ${(props): string => props.theme.colors.darkGrey};
    opacity: 0.4;
  }

  .machine-classification-indicator__text {
    color: ${(props): string => props.theme.colors.darkGrey};
    font-family: ${(props): string => props.theme.fonts.p1Medium};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    font-weight: ${(props): number => props.theme.fontWeights.p1Medium};
    margin-left: 4px;
    margin-bottom: -2px;
  }
`;
