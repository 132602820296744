import { Col, Row, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useMemo, useEffect } from 'react';
import * as machineDetailsPanelRobotSelector from '../../../../state/machineDetailsPanelRobot/machineDetailsPanelRobotSelectors';
import { StyledMachineDetailsTaskCompletedHistory } from './MachineDetailsTaskCompletionHistory.styles';
import { MachineDetailsPanelRobotActions } from 'app/modules/machine-inventory/machine-details-panel/state/machineDetailsPanelRobot/machineDetailsPanelRobotSlice';
import { OperatingHoursChartUtils } from 'app/modules/cleaning/utils/OperatingHoursChartUtils';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';

ChartJS.register(ArcElement, Tooltip, Legend);

export enum TaskCompletionHistoryColor {
  COMPLETED = '#238C96',
  INTERRUPTED = '#E9F4F5',
  STOPPED = '#D4D4D4',
}

interface MachineDetailsTaskCompletedHistoryProps {
  robot: Machine;
}

export const MachineDetailsTaskCompletedHistory = ({ robot }: MachineDetailsTaskCompletedHistoryProps): JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      MachineDetailsPanelRobotActions.machineDetailsRobotTaskCompletionHistoryRequest({
        filter: {
          machineIds: [robot.id],
          period: {
            endAt: OperatingHoursChartUtils.prepareEndDate(new Date()).toISOString(),
            startAt: OperatingHoursChartUtils.prepareStartDate(
              new Date(new Date().setDate(new Date().getDate() - 30))
            ).toISOString(),
          },
        },
      })
    );
  }, [dispatch, robot.id]);

  const taskCompletionHistory = useSelector(machineDetailsPanelRobotSelector.selectTaskCompletionData);

  const isLoading = useSelector(machineDetailsPanelRobotSelector.selectTaskCompletionIsLoading);

  const taskCompletionHistoryData = useMemo(
    () => ({
      labels: [
        `${t('machineDetails.taskCompletionHistory.labels.completed')} ${taskCompletionHistory?.completed || 0}%`,
        `${t('machineDetails.taskCompletionHistory.labels.interrupted')} ${taskCompletionHistory?.interrupted || 0}%`,
        `${t('machineDetails.taskCompletionHistory.labels.stopped')} ${taskCompletionHistory?.stopped || 0}%`,
      ],
      datasets: [
        {
          data: [
            taskCompletionHistory?.completed || 0,
            taskCompletionHistory?.interrupted || 0,
            taskCompletionHistory?.stopped || 0,
          ],
          backgroundColor: [
            TaskCompletionHistoryColor.COMPLETED,
            TaskCompletionHistoryColor.INTERRUPTED,
            TaskCompletionHistoryColor.STOPPED,
          ],
          hoverBackgroundColor: [
            TaskCompletionHistoryColor.COMPLETED,
            TaskCompletionHistoryColor.INTERRUPTED,
            TaskCompletionHistoryColor.STOPPED,
          ],
        },
      ],
    }),
    [t, taskCompletionHistory?.completed, taskCompletionHistory?.interrupted, taskCompletionHistory?.stopped]
  );

  return (
    <StyledMachineDetailsTaskCompletedHistory
      title={<h3 tabIndex={0}>{t('machineDetails.taskCompletionHistory.title')}</h3>}
    >
      <Row gutter={24} className="machine-details-task-completion-history__content">
        <Col span={24} className="machine-details-task-completion-history__content-pie-chart">
          {isLoading && (
            <div className="machine-details-task-completion-history__loading-overlay">
              <Spin size="large" />
            </div>
          )}
          <Pie
            data={taskCompletionHistoryData}
            height="217px"
            options={{
              maintainAspectRatio: false,
              rotation: 90,
              plugins: {
                legend: {
                  position: 'bottom',
                  labels: {
                    boxWidth: 8,
                    boxHeight: 8,
                  },
                },
              },
            }}
          />
        </Col>
      </Row>
    </StyledMachineDetailsTaskCompletedHistory>
  );
};
