import styled from 'styled-components';

export const StyledMachineDetailsRobotCleanedAreaChart = styled.div`
  padding-bottom: 32px;

  .robot-cleaned-area-chart__spin {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 32px;
  }

  .robot-cleaned-area-chart__unit {
    display: flex;
    justify-content: space-between;
    color: ${(props): string => props.theme.colors.grey};
    ${(props): string => props.theme.fontStyles.caption.news};
  }

  .machine-robots-cleaning-data-chart__chart-note {
    margin-top: ${(props): string => props.theme.spacing.base};
  }
`;
