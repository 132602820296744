import { useTranslation } from 'react-i18next';
import { Col, Row, Spin } from 'antd';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useDispatch, useSelector } from 'react-redux';
import { noop } from 'lodash-es';
import { useMemo } from 'react';
import * as machineDetailsPanelSelectors from '../../../../state/machineDetailsPanelSelectors';
import { MachineRobotCleaningStatus } from './MachineRobotCleaningStatus/MachineRobotCleaningStatus';
import { StyledMachineRobotStatus } from './MachineRobotStatus.styles';
import { MachineRobotStatusPowerInfo } from './MachineRobotPowerInfo/MachineRobotPowerInfo';
import { MachineRobotCleaningProgress } from './MachineRobotCleaningProgress/MachineRobotCleaningProgress';
import { MachineRobotCleaningRoute } from './MachineRobotCleaningRoute/MachineRobotCleaningRoute';
import { RobotViewModel } from 'app/modules/cleaning/widgets/robot-dashboard/RobotViewModel';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { ThemeConstants } from 'config/theme';
import { MachineDetailsPanelRobotActions } from 'app/modules/machine-inventory/machine-details-panel/state/machineDetailsPanelRobot/machineDetailsPanelRobotSlice';
import { selectCustomerId } from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { RobotBatteryPercentIcon } from 'app/modules/cleaning/widgets/robot-dashboard/components/RobotDashboard/RobotBatteryIcon/RobotBatteryIcon';
import { RobotWaterLevelIcon } from 'app/modules/cleaning/widgets/robot-dashboard/components/RobotDashboard/RobotWaterLevel/WaterLevelIcon';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { RobotDocking } from 'app/modules/cleaning/widgets/robot-dashboard/components/RobotDashboard/RobotDocking/RobotDocking';
import { RobotStatus } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { RobotRoutine } from 'app/components/RobotRoutine/RobotRoutine';
import { RobotUtils } from 'app/utils/robot/RobotUtils';
import { ConvertUnitUtils } from 'app/utils/convert-unit/ConvertUnitUtils';

interface MachineRobotStatusProps {
  robot: Machine;
}

export const MachineRobotStatus = ({ robot }: MachineRobotStatusProps): React.JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const robotModelView = new RobotViewModel(robot);

  const customerId = useSelector(selectCustomerId);
  const isTelemetryLoading = useSelector(machineDetailsPanelSelectors.selectIsTelemetryLoading);
  const isLatestCtrLoading = useSelector(machineDetailsPanelSelectors.selectIsLatestCtrLoading);

  const isDocking = robot.robotStatus === RobotStatus.Docking;
  const isCharging = useMemo(() => robotModelView.getCharging, [robotModelView.getCharging]);
  const isUSusers = false;

  const waterLevel = isUSusers
    ? ConvertUnitUtils.convertLitersToGallons(Number(robotModelView.getFreshWaterLevel))
    : robotModelView.getFreshWaterLevel;

  const waterUnit = isUSusers ? 'gal' : '&#76;';

  useDeepCompareEffect(() => {
    if (robot.id && customerId) {
      dispatch(
        MachineDetailsPanelRobotActions.subscribeToMachineUpdate({
          customerId,
          machineId: robot.id,
        })
      );

      return () => {
        dispatch(
          MachineDetailsPanelRobotActions.unsubscribeFromMachineUpdate({
            customerId,
            machineId: robot.id,
          })
        );
      };
    }

    // No cleanup necessary as long as no subscriptions have been registered
    return noop;
  }, [dispatch]);

  return (
    <StyledMachineRobotStatus
      className="machine-robot-status"
      title={<h3 tabIndex={0}>{t('machineDetails.generalInfo.robotStatus')}</h3>}
    >
      <Spin spinning={!!isTelemetryLoading || !!isLatestCtrLoading}>
        <Row gutter={[ThemeConstants.BASE_GAP, ThemeConstants.BASE_GAP]} className="machine-robot-status__card-body">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            className="machine-robot-status__info machine-robot-status__info-wrapper"
          >
            <div className="machine-robot-status__cleaning-status">
              <MachineRobotCleaningStatus robotStatus={robot.robotStatus} connectionStatus={robot.connectionStatus} />
            </div>
            <div className="machine-robot-status__cleaning-routine">
              <RobotRoutine robot={robot} />
            </div>
            <div className="machine-robot-status__cleaning-router">
              <MachineRobotCleaningRoute robot={robot} />
            </div>
            <div className="machine-robot-status__progress-wrapper">
              <MachineRobotCleaningProgress robot={robot} />
            </div>
            <Row justify="space-between" className="machine-robot-status__power-info-wrapper">
              {RobotUtils.getRobotType(robot) === 'B50' ? (
                <>
                  {isDocking && (
                    <Col className="machine-robot-status__docking">
                      <RobotDocking />
                    </Col>
                  )}
                  <Col>
                    <MachineRobotStatusPowerInfo
                      icon={
                        isCharging ? (
                          <SvgIcon name="batteryCharging" />
                        ) : (
                          <RobotBatteryPercentIcon
                            batteryPercentageNumber={Number(robotModelView.getBatteryChargeLevel.toString())}
                          />
                        )
                      }
                      value={robotModelView.getBatteryChargeLevel.toString()}
                      label="robotDashboard.battery"
                      unit={'&#37;'}
                    />
                  </Col>
                  <Col>
                    <MachineRobotStatusPowerInfo
                      icon={<RobotWaterLevelIcon waterPercent={robotModelView.getWaterPercentage} />}
                      value={waterLevel.toString()}
                      label="robotDashboard.waterLevel"
                      unit={waterUnit}
                    />
                  </Col>
                </>
              ) : (
                <>
                  {isDocking && (
                    <Col>
                      <RobotDocking />
                    </Col>
                  )}
                  <Col>
                    <MachineRobotStatusPowerInfo
                      icon={
                        isCharging ? (
                          <SvgIcon name="batteryCharging" />
                        ) : (
                          <RobotBatteryPercentIcon
                            batteryPercentageNumber={Number(
                              robotModelView.getBatteryDataCV50.batteryPack1.stateOfCharge.toString()
                            )}
                          />
                        )
                      }
                      value={robotModelView.getBatteryDataCV50.batteryPack1.stateOfCharge.toString()}
                      label="robotDashboard.battery"
                      unit={'&#37;'}
                    />
                  </Col>
                  <Col>
                    <MachineRobotStatusPowerInfo
                      icon={
                        isCharging ? (
                          <SvgIcon name="batteryCharging" />
                        ) : (
                          <RobotBatteryPercentIcon
                            batteryPercentageNumber={Number(
                              robotModelView.getBatteryDataCV50.batteryPack2.stateOfCharge.toString()
                            )}
                          />
                        )
                      }
                      value={robotModelView.getBatteryDataCV50.batteryPack2.stateOfCharge.toString()}
                      label="robotDashboard.battery"
                      unit={'&#37;'}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Spin>
    </StyledMachineRobotStatus>
  );
};
