import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import { useTheme } from 'styled-components';
import { ColorUtils } from '../../../../../lib/utils/colors/ColorUtils';

export type MachinePictureLoaderProps = Partial<IContentLoaderProps>;

export const MachinePictureLoader = (props: MachinePictureLoaderProps): JSX.Element => {
  const theme = useTheme();

  return (
    <ContentLoader
      speed={1.5}
      viewBox="0 0 400 400"
      backgroundColor={ColorUtils.getCssVariableValue(theme.colors.darkGrey)}
      foregroundColor={ColorUtils.getCssVariableValue(theme.colors.grey300)}
      {...props}
    >
      <path
        d="M 275.034 26.457 
    c -1.201 1.01 -25.763 2.752 -50.591 4.814 c 
    -25.023 2.08 -50.31 4.474 -50.31 4.474 l 0.145 
    2.792 l 3.045 0.942 l -0.133 3.25 s -26.086 6.261 
    -35.896 10.098 c -8.694 3.401 -29.022 13.96 -44.58 
    25.016 c -20.333 14.45 -34.008 25.89 -39.065 31.804 
    c -6.894 8.694 -14.792 65.21 -14.267 82.12 c 0.152 
    4.908 5.305 16.715 5.305 16.715 l 0.21 24.641 l 10.672 
    3.14 l 0.079 23.48 L 17.75 273.01 S 2.998 278.504 0.8 
    285.96 c -0.784 4.254 -1.46 17.767 0.36 19.541 c 1.963 
    3.14 17.689 9.574 13.53 7.141 c -4.16 -2.432 24.43 12.763 
    63.274 22.828 c 0 0 47.144 13.528 68.882 13.842 c 21.737 
    0.314 39.236 -10.513 46.614 -13.655 c 28.016 -11.928 77.677 
    -40.779 107.969 -77.114 l 85.17 36.341 l 0.63 -32.677 l 
    -51.548 -26.179 S 347.617 98.424 345.89 83.042 c 0 0 -3.66 
    -23.577 -9.992 -33.497 c 9.386 -2.51 16.127 -11.832 17.498 
    -16.405 c 2.355 -7.847 1.35 -16.827 -0.147 -19.19 c -3.181 
    -5.02 -9.151 -9.229 -14.251 -10.799 c -5.101 -1.569 -16.92 
    -2.84 -24.94 -3.076 c -7.702 -0.226 -22.077 0.105 -25.426 
    0.377 c -2.58 0.21 -12.85 2.096 -14.982 3.678 c -1.07 0.793 
    0.085 0.273 -0.918 1.564 c -1.475 1.9 -1.675 8.54 -1.13 12.917 c 1.73 2.542 2.735 5.49 3.432 7.846 z"
      />
    </ContentLoader>
  );
};
