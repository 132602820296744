import { Row } from 'antd';
import styled from 'styled-components';

export const StyledMachineRobotPowerInfo = styled(Row)`
  &.machine-robot-status-power-info {
    .ant-col:first-child {
      margin: 8px 0;
    }

    .odometer-wrapper {
      ${(props): string => props.theme.fontStyles.redesign.base.normal}
      height: 14px;
      line-height: 14px;
      margin-right: 4px;
    }

    .machine-robot-status-power-info__unit,
    .machine-robot-status-power-info__label {
      ${(props): string => props.theme.fontStyles.redesign.base.normal}
      margin: 0;
    }
  }
`;
