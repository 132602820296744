import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Image, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { Machine } from '../../../../../interfaces/Machine.types';
import { MachinePictureLoader } from '../../../../../components/MachinePictureLoader/MachinePictureLoader';
import { StyledMachineRobotNameBanner } from './MachineRobotNameBanner.styles';
import { MachineRobotConnectionStatusIndicator } from './MachineRobotConnectionStatusIndicator/MachineRobotConnectionStatusIndicator';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { InfoTooltip } from 'lib/components/Tooltip/InfoTooltip/InfoTooltip';
import { MachineConnectionStatus } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import {
  LOCAL_STORAGE_VNC_KEY,
  LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY,
  LOCAL_STORAGE_VNC_SERIAL_NUMBER,
} from 'config/constants';
import { InfoTooltipGlobalStyles } from 'lib/components/Tooltip/InfoTooltip/InfoTooltip.global.styles';
import { VncModalActions } from 'app/components/Vnc/modals/state/vncModalSlice';
import { VncSelectors } from 'app/components/Vnc/state/vncSelectors';
import { VncActions } from 'app/components/Vnc/state/vncSlice';
import { VncUtils } from 'app/components/Vnc/util/VncUtils';
import { VncWarningOneIsOpenAlreadyModal } from 'app/components/Vnc/modals/components/VncWarningOneIsOpenAlreadyModal/VncWarningOneIsOpenAlreadyModal';
import { VncModalSelectors } from 'app/components/Vnc/modals/state/vncModalSelectors';

interface MachineRobotNameBannerProps {
  robot: Machine;
}

export const MachineRobotNameBanner = ({ robot }: MachineRobotNameBannerProps): React.JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();

  const machineIdSelector = useSelector(VncSelectors.selectVncMachineId);
  const machineIdSelectorV2 = useSelector(VncSelectors.selectVncMachineIdV2);
  const vncUrl = useSelector(VncSelectors.selectVncUrl) || '';
  const vncToken = useSelector(VncSelectors.selectVncToken) || '';

  // Checking which vnc solution is using to check already opened
  const isOneVncOpen = useMemo(() => {
    if (!localStorage.getItem(LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY)) {
      return false;
    }

    if (VncUtils.isUsingVncV2Solution(localStorage.getItem(LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY))) {
      return vncToken;
    }

    return vncUrl;
  }, [vncToken, vncUrl]);

  const isVncWarningOneIsOpenAlreadyModalVisible = useSelector(
    VncModalSelectors.selectVncModalWarningOneIsOpenAlreadyIsVisible
  );

  const isRemoteControlAvailable = useMemo(
    () => VncUtils.isRemoteControllable(robot?.softwareVersion),
    [robot?.softwareVersion]
  );

  const handleEffectiveRequestVncSession = useCallback(() => {
    if (VncUtils.isUsingVncV2Solution(robot?.softwareVersion)) {
      dispatch(VncActions.vncShowWindowV2());
      localStorage.setItem('LOCAL_STORAGE_IS_USING_VNC_V2_KEY', 'true');

      return dispatch(
        VncActions.vncRequestUrlV2Request({ machineId: robot.id, softwareVersion: robot?.softwareVersion })
      );
    }

    dispatch(VncActions.vncHideWindowV2());
    return dispatch(VncActions.vncRequestUrlRequest({ machineId: robot.id, softwareVersion: robot?.softwareVersion }));
  }, [dispatch, robot.id, robot?.softwareVersion]);

  const handleGetEffectiveMachineIdSelector = useCallback(() => {
    if (!robot?.softwareVersion) {
      return null;
    }

    if (VncUtils.isUsingVncV2Solution(robot?.softwareVersion)) {
      return machineIdSelectorV2;
    }

    return machineIdSelector;
  }, [robot?.softwareVersion, machineIdSelectorV2, machineIdSelector]);

  /**
   * @context user click "Remote Control" button
   * @description Handle access VNC session when clicking on "Access remote control"
   * @usage when user proactively click the button to access VNC session
   */
  const handleRequestVncSession = useCallback(() => {
    if (isRemoteControlAvailable) {
      if (!isOneVncOpen && robot.id) {
        dispatch(VncActions.vncOpenWindow());
        handleEffectiveRequestVncSession();
        localStorage.setItem(LOCAL_STORAGE_VNC_KEY, robot.id);
        localStorage.setItem(LOCAL_STORAGE_VNC_SERIAL_NUMBER, robot.serialNumber);
        localStorage.setItem(LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY, robot?.softwareVersion || '');
      } else {
        dispatch(VncModalActions.vncModalWarningOneIsOpenAlreadyShow());
      }
    }
  }, [
    dispatch,
    robot.id,
    robot.serialNumber,
    robot?.softwareVersion,
    isRemoteControlAvailable,
    handleEffectiveRequestVncSession,
    isOneVncOpen,
  ]);

  return (
    <StyledMachineRobotNameBanner className="machine-robot-name-banner">
      <InfoTooltipGlobalStyles />
      <div className="machine-robot-name-banner__image">
        {robot.variant?.picture.product ? (
          <Image src={robot.variant?.picture.product} width={140} height={140} preview={false} />
        ) : (
          <MachinePictureLoader className="machine-robot-name-banner__image--loading" />
        )}
      </div>
      <div className="machine-robot-name-banner__info">
        <MachineRobotConnectionStatusIndicator
          lastActivityAt={robot?.lastActivityAt}
          connectionStatus={robot?.connectionStatus}
        />
        <div className="machine-robot-name-banner__name">{robot.name}</div>
        <div className="machine-robot-name-banner__type">{robot.type?.name}</div>
        {robot.id !== handleGetEffectiveMachineIdSelector() && isRemoteControlAvailable && (
          <>
            <PrimaryButton
              size="m"
              key="request-button"
              className="machine-robot-name-banner__remote-control-button"
              onClick={handleRequestVncSession}
              disabled={robot.connectionStatus !== MachineConnectionStatus.Online}
              icon={
                robot.connectionStatus !== MachineConnectionStatus.Online && (
                  <Tooltip
                    className="tooltip"
                    title={<InfoTooltip content={t(`machineDetails.tooltip.vncButtonDisabled`)} />}
                    overlayClassName="tooltip-overlay"
                    placement="bottomLeft"
                  >
                    <div>
                      <SvgIcon name="info" className="machine-robot-name-banner__info-icon" />
                    </div>
                  </Tooltip>
                )
              }
            >
              <span className="machine-robot-name-banner__remote-control-button--label">{t('vnc.title')}</span>
            </PrimaryButton>
          </>
        )}
      </div>
      {isVncWarningOneIsOpenAlreadyModalVisible && <VncWarningOneIsOpenAlreadyModal robotIdParams={id || ''} />}
    </StyledMachineRobotNameBanner>
  );
};
