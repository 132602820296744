import styled from 'styled-components';

export const StyledMachineNoteList = styled.div`
  .machine-note-list__note-icon-avatar-initials {
    width: 100%;
    height: 100%;
  }

  .machine-note-list__create-button {
    width: 18px;
    height: 18px;
    color: ${(props): string => props.theme.colors.black};
    cursor: pointer;
  }

  .machine-note-list__note-edit-button,
  .machine-note-list__note-delete-button {
    width: 15px;
    height: 17px;
    padding: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;
