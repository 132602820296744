import styled from 'styled-components';

export const StyledLegacyMachineStatus = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  .legacy-machine-connection-status__tooltip-wrapper {
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 32px;
      svg {
        width: unset;
        height: unset;
      }
    }
  }

  .legacy-machine-connection-status__icon {
    color: ${(props): string => props.theme.colors.darkGrey};
    font-size: 16px;

    &::before {
      margin-left: 0;
    }
  }

  .legacy-machine-connection-status__text {
    color: ${(props): string => props.theme.colors.darkGrey};
    font-family: ${(props): string => props.theme.fonts.p1Medium};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    font-weight: ${(props): number => props.theme.fontWeights.p1Medium};
    margin-left: 4px;
    margin-bottom: -2px;
  }
`;
