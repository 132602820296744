import styled from 'styled-components';

export const StyledMachineOperatingHoursChart = styled.div`
  color: ${(props): string => props.theme.colors.black};

  .machine-operating-hours-chart__hours-unit {
    font-size: ${(props): string => props.theme.fontSizes.captionSmall};
    margin: 0 0 0 33px;
    color: ${(props): string => props.theme.colors.darkGrey};
  }

  .machine-operating-hours-chart__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
  }

  .machine-operating-hours-chart__sums {
    display: flex;
  }

  .machine-operating-hours-chart__operating-label {
    display: flex;
    text-transform: uppercase;
    font-family: ${(props): string => props.theme.fonts.p1Bold};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    font-weight: ${(props): number => props.theme.fontWeights.p0};
    line-height: ${(props): string => props.theme.lineHeights.caption};
  }

  .machine-operating-hours-chart__operating-value {
    font-family: ${(props): string => props.theme.fonts.heading1};
    font-size: ${(props): string => props.theme.fontSizes.heading1};
    font-weight: ${(props): number => props.theme.fontWeights.heading1};
    line-height: ${(props): string => props.theme.lineHeights.heading1};
    margin-bottom: 0;
  }

  .machine-operating-hours-chart__total-operating-label {
    margin: 0 0 0 40px;
    opacity: 0.5;
  }

  .machine-operating-hours-chart__chart-note {
    display: flex;
  }

  .machine-operating-hours-chart__chart-note-text {
    font-family: ${(props): string => props.theme.fonts.captionNews};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    font-weight: ${(props): number => props.theme.fontWeights.p1News};
    line-height: ${(props): string => props.theme.lineHeights.caption};
  }

  .machine-operating-hours-chart__chart-note-square-left,
  .machine-operating-hours-chart__chart-note-square-right {
    height: 8px;
    width: 8px;
    margin: 0 10px 0 0;
    border-radius: 1px;
  }

  .machine-operating-hours-chart__chart-note-left {
    margin: 0 30px 0 0;
    display: flex;
    align-items: baseline;
  }

  .machine-operating-hours-chart__chart-note-right {
    display: flex;
    align-items: baseline;
  }

  .machine-operating-hours-chart__chart-note-square-left {
    background-color: ${(props): string => props.theme.colors.celadonGreen};
  }

  .machine-operating-hours-chart__chart-note-square-right {
    background-color: ${(props): string => props.theme.colors.lightCeladonGreen};
  }

  .machine-operating-hours-chart__chart {
    display: flex;
    flex-direction: row;
  }

  .machine-operating-hours-chart__bar-stacked-chart {
    margin: 0 0 0 0;
    width: 100%;
    overflow: hidden;
  }

  .machine-operating-hours-chart__bar-stacked-chart-wrapper {
    height: 330px;
  }

  .machine-operating-hours-chart__chart-arrow {
    width: 20px;
    display: flex;
    align-items: flex-end;
    padding: 0px 0px 7px;
    user-select: none;
  }

  .machine-operating-hours-chart__chart-arrow-icon {
    font-size: ${(props): string => props.theme.fontSizes.heading4};
  }
`;
