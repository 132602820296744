import { produce } from 'immer';
import { AnyAction } from 'redux';
import { Optional } from '../../../../lib/types/Optional';
import { FooActions } from './fooActions';

export interface FooState {
  bar: Optional<string>;
}

const initialState: FooState = {
  bar: null,
};

export const fooReducer = (state = initialState, action: AnyAction): FooState =>
  produce(state, draft => {
    switch (action.type) {
      case FooActions.GET_FOO_SUCCESS: {
        const { foo } = action.payload;

        draft.bar = foo;
        return draft;
      }
      default:
        return draft;
    }
  });
