import { createGlobalStyle } from 'styled-components';

export const MachineCategoryIndicatorGlobalStyles = createGlobalStyle`
  .machine-category-indicator-tooltip__overlay {
    &.ant-tooltip {
      .ant-tooltip-content {
        width: 100%;

        .ant-tooltip-inner {
          color: ${(props): string => props.theme.colors.white};
          background-color: ${(props): string => props.theme.colors.black};;
          padding: calc(${(props): string => props.theme.spacing.xxs} + 1px) ${(props): string =>
  props.theme.spacing.xs} calc(${(props): string => props.theme.spacing.xxs} - 1px);
        }
      }
    }
  }
`;
