import { ApolloQueryResult, gql } from '@apollo/client';
import { inject, injectable } from 'tsyringe';
import { GraphQlClient } from '../../cross-cutting-concerns/communication/clients/GraphQlClient';
import { Foo } from './interfaces/Foo';

export interface FooClientListOptions {
  nextToken?: string;
}

@injectable()
export class FooClient {
  constructor(@inject('GraphQlClient') private client: GraphQlClient) {}

  public list = async ({ nextToken = undefined }: FooClientListOptions): Promise<ApolloQueryResult<Foo[]>> =>
    this.client.query({
      query: gql`
        query AllTasks {
          allTasks {
            items {
              id
              title
              description
              taskStatus
              owner
            }
            nextToken
          }
        }
      `,
      variables: {
        nextToken,
      },
    });
}
