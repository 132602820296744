import styled from 'styled-components';

export const StyledMachineDetailsPanelRobot = styled.div`
  ${(props): string => props.theme.mixins.layout.addHeaderContentClass('machine-details-robot')}
  ${(props): string => props.theme.mixins.layout.addBodyContentClass('machine-details-robot')}
  ${(props): string => props.theme.mixins.layout.addContainerClasses('machine-details-robot')}

  min-height: inherit;

  .machine-panel-robot__tabs {
    width: 100%;

    .ant-tabs-nav {
      margin-bottom: ${(props): string => props.theme.spacing.lg};
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: ${(props): string => props.theme.colors.black};
        ${(props): string => props.theme.fontStyles.redesign.base.strong}
        font-weight: 500;
      }
    }

    .ant-tabs-ink-bar {
      background-color: ${(props): string => props.theme.colors.black};
      height: 4px;
    }
  }
`;
