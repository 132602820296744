import { singleton } from 'tsyringe';
import { Optional } from '../../../lib/types/Optional';
import {
  MachineAttachmentCheckNameResponse,
  MachineAttachmentDeleteResponse,
  MachineAttachmentDownloadResponse,
  MachineAttachmentGetUploadLinkResponse,
} from './interfaces/MachineAttachment.types';
import {
  MachineAttachmentClient,
  MachineAttachmentClientCheckNameOptions,
  MachineAttachmentClientDeleteOptions,
  MachineAttachmentClientDownloadOptions,
  MachineAttachmentClientGetUploadLinkOptions,
  MachineAttachmentClientUploadByUrlOptions,
} from './MachineAttachmentClient';

export type MachineAttachmentServiceDeleteOptions = MachineAttachmentClientDeleteOptions;
export type MachineAttachmentServiceGetUploadLinkOptions = MachineAttachmentClientGetUploadLinkOptions;
export type MachineAttachmentServiceUploadByUrlOptions = MachineAttachmentClientUploadByUrlOptions;
export type MachineAttachmentServiceDownloadOptions = MachineAttachmentClientDownloadOptions;
export type MachineAttachmentServiceCheckNameOptions = MachineAttachmentClientCheckNameOptions;

@singleton()
export class MachineAttachmentService {
  constructor(private readonly machineAttachmentClient: MachineAttachmentClient) {}

  public delete = async ({
    machineId,
    attachmentId,
  }: MachineAttachmentServiceDeleteOptions): Promise<Optional<MachineAttachmentDeleteResponse>> => {
    const { data } = await this.machineAttachmentClient.delete({ machineId, attachmentId });

    return data;
  };

  public getUploadLink = async ({
    machineId,
    attachmentName,
    mimeType,
  }: MachineAttachmentServiceGetUploadLinkOptions): Promise<MachineAttachmentGetUploadLinkResponse> => {
    const { data } = await this.machineAttachmentClient.getUploadLink({ machineId, attachmentName, mimeType });

    return data;
  };

  public uploadByUrl = async ({ url, formData }: MachineAttachmentServiceUploadByUrlOptions): Promise<void> => {
    await this.machineAttachmentClient.uploadByUrl({ url, formData });
  };

  public download = async ({
    machineId,
    attachmentId,
  }: MachineAttachmentServiceDownloadOptions): Promise<Optional<MachineAttachmentDownloadResponse>> => {
    const { data } = await this.machineAttachmentClient.download({ machineId, attachmentId });

    return data;
  };

  public checkName = async ({
    machineId,
    attachmentName,
  }: MachineAttachmentServiceCheckNameOptions): Promise<Optional<MachineAttachmentCheckNameResponse>> => {
    const { data } = await this.machineAttachmentClient.checkName({ machineId, attachmentName });

    return data;
  };
}
