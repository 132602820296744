import { Empty } from 'antd';
import classnames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LegacyTextButton } from '../../../../../../lib/components/LegacyButton/LegacyTextButton/LegacyTextButton';
import { MessageList } from '../../../../../../lib/components/MessageList/MessageList';
import { SvgIcon } from '../../../../../../lib/components/SvgIcon/SvgIcon';
import { DateTime } from '../../../../../../lib/utils/date-handling/DateTime';
import { AddNoteModal } from '../../../../note/modals/components/AddNoteModal/AddNoteModal';
import { EditNoteModal } from '../../../../note/modals/components/EditNoteModal/EditNoteModal';
import { DeleteNoteModal } from '../../../../note/modals/components/DeleteNoteModal/DeleteNoteModal';
import { NoteModalsActions } from '../../../../note/modals/state/noteModalsSlice';
import { Machine } from '../../../interfaces/Machine.types';
import { IMachineNote } from '../../../interfaces/MachineNote.types';
import { MachineDetailsActions } from '../../state/machineDetailsActions';
import {
  selectNotesData,
  selectNotesIsInitialLoad,
  selectNotesIsLoadingMore,
  selectNotesPage,
  selectNotesPaginationTokens,
  selectNotesTotalCount,
} from '../../state/machineDetailsSelectors';
import { StyledMachineNoteList } from './MachineNoteList.styles';
import { MACHINE_NOTE_LIST_PAGE_SIZE } from 'app/modules/machine-inventory/utils/constants';
import { IMessage } from 'lib/components/MessageList/MessageList.types';
import { AvatarInitials } from 'lib/components/AvatarInitials/AvatarInitials';

export interface MachineNoteListProps {
  machine: Machine;
}

export const MachineNoteList = ({ machine }: MachineNoteListProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const notes = useSelector(selectNotesData) || [];
  const isInitialLoad = !!useSelector(selectNotesIsInitialLoad);
  const isLoadingMore = !!useSelector(selectNotesIsLoadingMore);
  const totalCount = useSelector(selectNotesTotalCount) || 0;
  const paginationTokens = useSelector(selectNotesPaginationTokens);
  const page = useSelector(selectNotesPage);

  const nextPagePaginationToken = paginationTokens[page + 1];
  const hasNextPage = paginationTokens[page + 1] !== null;

  const messages = notes.map<IMessage<IMachineNote>>(note => ({
    id: note.id,
    icon: (
      <AvatarInitials fullName={note.createdBy.displayName} className="machine-note-list__note-icon-avatar-initials" />
    ),
    title: note.createdBy.displayName,
    subtitle: (
      <div
        className={classnames('machine-note-list__note-details')}
        // Sry about this monster, it's the last day before vacation and late (ˊ•͈ ◡ •͈ˋ)
        title={`${DateTime.formatDateByLocale(i18n.language, note.createdAt)}${
          note.isUpdated
            ? `, ${t('machineNoteList.editedOn')} ${DateTime.formatDateByLocale(i18n.language, note.updatedAt)}`
            : ''
        }`}
      >
        <span className="machine-note-list__note-created-at">
          {DateTime.formatDateByLocale(i18n.language, note.createdAt)}
        </span>
        {note.isUpdated && (
          <>
            {`, ${t('machineNoteList.editedOn')} `}
            <span className="machine-note-list__note-updated-at">
              {DateTime.formatDateByLocale(i18n.language, note.updatedAt)}
            </span>
          </>
        )}
      </div>
    ),
    description: note.description,
    originalEntity: note,
  }));

  const loadMore = useCallback(() => {
    dispatch(
      MachineDetailsActions.getMachineNotesRequest({
        machineId: machine.id,
        paginationOptions: {
          paginationToken: nextPagePaginationToken,
          limit: MACHINE_NOTE_LIST_PAGE_SIZE,
        },
        append: true,
      })
    );
  }, [dispatch, machine.id, nextPagePaginationToken]);

  const handleCreateButtonClick = (): void => {
    dispatch(NoteModalsActions.showAddNoteModal());
  };

  const handleEditButtonClick = (note: IMachineNote): void => {
    dispatch(
      NoteModalsActions.showEditNoteModal({
        noteToEdit: note,
      })
    );
  };

  const handleDeleteButtonClick = (note: IMachineNote): void => {
    dispatch(
      NoteModalsActions.showDeleteNoteModal({
        noteToDelete: note,
      })
    );
  };

  useEffect(() => {
    dispatch(
      MachineDetailsActions.getMachineNotesRequest({
        machineId: machine.id,
        paginationOptions: {
          paginationToken: '',
          limit: MACHINE_NOTE_LIST_PAGE_SIZE,
        },
        append: false,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <StyledMachineNoteList className={classnames('machine-note-list')}>
      <MessageList<IMachineNote>
        title={t('machineNoteList.subHeader')}
        messages={messages}
        isInitialLoad={isInitialLoad}
        isLoadingMore={isLoadingMore}
        hasNextPage={hasNextPage}
        totalCount={totalCount}
        loadMore={loadMore}
        listButtonViewRenderer={(_notes: IMachineNote[]): React.ReactElement => (
          <>
            <div className="machine-note-list__create-button" onClick={handleCreateButtonClick}>
              <SvgIcon name="plus" />
            </div>
          </>
        )}
        messageButtonViewRenderer={(note: IMachineNote): React.ReactElement => (
          <>
            <LegacyTextButton
              className={classnames('machine-note-list__note-edit-button', 'message__button')}
              onClick={(): void => handleEditButtonClick(note)}
            >
              <SvgIcon name="edit" />
            </LegacyTextButton>
            <LegacyTextButton
              className={classnames('machine-note-list__note-delete-button', 'message__button')}
              onClick={(): void => handleDeleteButtonClick(note)}
            >
              <SvgIcon name="delete" />
            </LegacyTextButton>
          </>
        )}
        modals={
          <>
            <AddNoteModal machine={machine} />
            <EditNoteModal />
            <DeleteNoteModal />
          </>
        }
        emptyView={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('machineNoteList.emptyText')} />}
      />
    </StyledMachineNoteList>
  );
};
