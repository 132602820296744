import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import * as machineDetailsSelectors from '../../state/machineDetailsPanelSelectors';
import { StyledMachineDetailsNotificationList } from './MachineDetailsNotificationList.styles';
import { getMachineNotificationListColumns } from './columns/MachineDetailsNotificationList.columns';
import { Notification, SortOrders } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Table } from 'lib/components/Table/Table';
import { Optional } from 'lib/types/Optional';
import { Translations } from 'app/cross-cutting-concerns/translations/Translations';
import { FeatureFlagSelectors } from 'app/cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { MachineDetailsPanelActions } from 'app/modules/machine-inventory/machine-details-panel/state/machineDetailsPanelActions';

export const MachineDetailsNotificationList = ({ machine }: { machine: Machine }): JSX.Element => {
  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  const data = useSelector(machineDetailsSelectors.selectMachineNotificationsData) || [];
  const isLoading = !!useSelector(machineDetailsSelectors.selectIsMachineNotificationsLoading);
  const totalCount = useSelector(machineDetailsSelectors.selectMachineNotificationsTotalCount) || 0;
  const paginationTokens = useSelector(machineDetailsSelectors.selectMachineNotificationsPaginationTokens);
  const page = useSelector(machineDetailsSelectors.selectMachineNotificationsPage);
  const pageSize = useSelector(machineDetailsSelectors.selectMachineNotificationsPageSize);

  const isSortingEnabled = features.NOTIFICATION_FILTERS_AND_SORTING;
  const sortField = useSelector(machineDetailsSelectors.selectMachineNotificationsSortField) || 'timestamp';
  const sortOrder = useSelector(machineDetailsSelectors.selectMachineNotificationsSortOrder) || SortOrders.Desc;

  useEffect(() => {
    dispatch(
      MachineDetailsPanelActions.getMachineNotificationListRequest({
        paginationOptions: {
          limit: pageSize,
          paginationToken: paginationTokens[page] || '',
        },
        filter: {
          machineIds: [machine.id],
        },
        sortOptions: isSortingEnabled
          ? {
              field: sortField,
              order: sortOrder,
            }
          : undefined,
        lang: Translations.getSupportedLanguageCode(i18n.language).toLowerCase(),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, machine.id, page, pageSize, sortField, sortOrder]);

  const onPageChange = (value: number): void => {
    dispatch(MachineDetailsPanelActions.changeMachineNotificationListTablePage({ page: value }));
  };

  const onTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Notification> | SorterResult<Notification>[]
  ): void => {
    if (Array.isArray(sorter)) return;
    const sorterField = sorter.field;
    const sorterOrder = sorter.order === 'descend' ? SortOrders.Desc : SortOrders.Asc;

    dispatch(MachineDetailsPanelActions.setMachineNotificationListActiveSortField(sorterField as Optional<string>));
    dispatch(MachineDetailsPanelActions.setMachineNotificationListActiveSortOrder(sorterOrder as Optional<SortOrders>));
  };

  const tableColumns = getMachineNotificationListColumns({ t, i18n, isSortingEnabled });

  return (
    <StyledMachineDetailsNotificationList className="machine-details-notification-list">
      <div className="machine-details-notification-list__header">
        <h3 tabIndex={0} className="machine-details-notification-list__title">
          {t('machineDetails.notificationList.title')}
        </h3>
      </div>

      <div className="machine-details-notification-list__body">
        <Table
          dataSource={data}
          loading={isLoading}
          className="machine-details-notification-list__table"
          columns={tableColumns}
          rowKey="id"
          onChange={onTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
          pagination={{
            total: totalCount,
            pageSize,
            page,
            onPageChange,
            isLoading,
            showPageSizeDropdown: false,
          }}
        />
      </div>
    </StyledMachineDetailsNotificationList>
  );
};
