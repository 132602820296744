import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { StyledMachineRobotCleaningStatus } from './MachineRobotCleaningStatus.styles';
import { RobotUtils } from 'app/utils/robot/RobotUtils';
import {
  MachineConnectionStatus,
  RobotStatus,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

export const MachineRobotCleaningStatus = ({
  robotStatus,
  connectionStatus,
}: {
  robotStatus: Optional<RobotStatus>;
  connectionStatus: MachineConnectionStatus;
}): JSX.Element => {
  const { t } = useTranslation();
  const robotStatusDisplayName = RobotUtils.getRobotStatusDisplayName(robotStatus, connectionStatus);
  const className = classnames(
    robotStatusDisplayName.toLowerCase().concat('-status'),
    'machine-robot-cleaning-status__data'
  );
  return (
    <StyledMachineRobotCleaningStatus title={t(`robotDashboard.${robotStatusDisplayName}`)} className={className}>
      {t(`robotDashboard.${robotStatusDisplayName}`)}
    </StyledMachineRobotCleaningStatus>
  );
};
