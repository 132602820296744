import styled from 'styled-components';

export const StyledMachineClassificationIndicatorTooltip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  .machine-classification-indicator-tooltip__icon {
    color: ${(props): string => props.theme.colors.white};
    margin: 0 8px 0 0;
    width: 32px;
    height: 32px;
    text-align: center;

    svg {
      width: auto;
      height: auto;
    }
  }

  .machine-classification-indicator-tooltip__info {
    flex: 1;
  }

  .machine-classification-indicator-tooltip__title {
    ${(props): string => props.theme.fontStyles.redesign.sm.strong};
    margin-bottom: 4px;
  }

  .machine-classification-indicator-tooltip__description {
    ${(props): string => props.theme.fontStyles.redesign.sm.normal};
    margin-bottom: 0;
  }
`;
