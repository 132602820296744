import styled from 'styled-components';

export const StyledMachineDetailsOverviewGcd = styled.div`
  min-height: inherit;

  .machine-details-panel-gcd__main-data {
    display: flex;
    min-height: 450px;
    align-items: stretch;
    gap: ${(props): string => props.theme.spacing.xl};
  }

  .machine-details-panel-gcd__main-data-left {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: ${(props): string => props.theme.spacing.base};
  }

  .machine-details-panel-gcd__main-data-right {
    flex: 1;
  }

  .machine-details-panel-gcd__status {
    margin-top: auto;
  }

  .machine-details-panel-gcd__map {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    align-self: stretch;
  }

  .machine-details-panel-gcd__sub-header {
    ${(props): string => props.theme.fontStyles.redesign.xl.strong};
  }

  .machine-details-panel-gcd__info-header {
    display: flex;
    justify-content: space-between;
  }

  .machine-cleaning-data__filter {
    width: 100%;
  }

  .machine-operating-hours-chart {
    .machine-operating-hours-chart__bar-stacked-chart-wrapper {
      width: 100% !important;
    }
  }
`;
