import styled from 'styled-components';

export const StyledMachineRobotNameBanner = styled.div`
  display: flex;
  align-items: top;
  gap: ${(props): string => props.theme.spacing.lg};
  margin-top: ${(props): string => props.theme.spacing.lg};

  .machine-robot-name-banner__image {
    width: 140px;
    height: 140px;
  }

  .machine-robot-name-banner__info {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    align-items: flex-start;
    gap: ${(props): string => props.theme.spacing.xs};

    .machine-robot-name-banner__remote-control-button {
      text-transform: capitalize;
      margin-top: 8px;
      display: flex;

      .machine-robot-name-banner__remote-control-icon {
        svg {
          width: 16px;
          height: 16px;
          margin-bottom: -4px;
        }
      }

      :active {
        .machine-robot-name-banner__remote-control-icon {
          path {
            fill: ${(props): string => props.theme.colors.primary};
          }
        }
      }

      :disabled {
        margin: 8px 0;
        pointer-events: none;

        &.button-base {
          .ant-btn-icon {
            pointer-events: auto;
            margin-inline-end: 4px;

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }

        .machine-robot-name-banner__remote-control-icon {
          path {
            fill: ${(props): string => props.theme.colors.grey};
          }
        }
      }
    }
  }

  .machine-robot-name-banner__name {
    ${(props): string => props.theme.fontStyles.redesign.heading2};
    word-break: break-word;
  }

  .machine-robot-name-banner__type {
    ${(props): string => props.theme.fontStyles.redesign.base.strong};
  }
`;
