import { ForkEffect, getContext, GetContextEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { IDependencies } from '../../../cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { Foo } from '../interfaces/Foo';
import { IGetFooErrorAction, IGetFooSuccessAction, FooActions } from './fooActions';

export function* getFooSaga(): Generator<
  GetContextEffect | Promise<Foo[]> | PutEffect<IGetFooSuccessAction> | PutEffect<IGetFooErrorAction>,
  void,
  IDependencies
> {
  const { fooService }: IDependencies = yield getContext('dependencies');

  try {
    const response = yield fooService.list();

    yield put(
      FooActions.getFooSuccess({
        foo: response,
      })
    );
  } catch (error) {
    yield put(
      FooActions.getFooError({
        error,
      })
    );
  }
}

export function* fooSaga(): Generator<ForkEffect<never>, void> {
  yield takeLatest(FooActions.GET_FOO_REQUEST, getFooSaga);
}
