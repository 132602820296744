import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { MachineRobotsCleaningDataChartHeader } from '../MachineDetailsRobotsCleaningDataChartHeader/MachineDetailsRobotsCleaningDataChartHeader';
import MachineDetailsRobotsBarChart from '../MachineDetailsRobotsBarChart';
import * as machineDetailsPanelRobotSelectors from '../../../../../state/machineDetailsPanelRobot/machineDetailsPanelRobotSelectors';
import { DateTime } from '../../../../../../../../../lib/utils/date-handling/DateTime';
import { StyledMachineDetailsRobotCleanedHoursChart } from './MachineDetailsRobotCleanedHoursChart.styles';
import { MachineDetailsPanelRobotActions } from 'app/modules/machine-inventory/machine-details-panel/state/machineDetailsPanelRobot/machineDetailsPanelRobotSlice';
import { getDatesByPeriod } from 'lib/utils/date-handling/getTimeByPeriod';
import { Numbers } from 'lib/utils/number-formatting/Numbers';
import { CleaningTaskCleanedHrsByDay } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { DateFormats } from 'app/modules/machine-inventory/interfaces/Robot.types';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';

const DEFAULT_VALUE = {
  cleanedHrs: 0,
  finishedAt: '',
};

interface MachineDetailsRobotCleanedHoursChartProps {
  robot: Machine;
}

export const MachineDetailsRobotCleanedHoursChart = ({
  robot,
}: MachineDetailsRobotCleanedHoursChartProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const startAt = useSelector(machineDetailsPanelRobotSelectors.selectPeriodStartDate);
  const endAt = useSelector(machineDetailsPanelRobotSelectors.selectPeriodEndDate);
  const routeName = useSelector(machineDetailsPanelRobotSelectors.selectActiveRoutesName);

  const cleaningDataCleanedHrsByDay = useSelector(machineDetailsPanelRobotSelectors.selectCleaningDataCleanedHrsByDay);
  const periodCleanedHrs = useSelector(machineDetailsPanelRobotSelectors.selectCleaningDataPeriodCleanedHrs);
  const totalCleanedHrs = useSelector(machineDetailsPanelRobotSelectors.selectCleaningDataTotalCleanedHrs);
  const cleanedHrsIsLoading = useSelector(machineDetailsPanelRobotSelectors.selectCleaningDataCleanedHrsIsLoading);

  const timeZone = DateTime.getBrowserTimeZone();

  const startTime = startAt ? dayjs(startAt) : null;
  const endTime = endAt ? dayjs(endAt) : null;

  const dates = getDatesByPeriod(startTime, endTime).map(d => d.format(DateFormats.DATE_IN_DAY_MONTH));

  useEffect(() => {
    if (!startAt || !endAt) return;
    dispatch(
      MachineDetailsPanelRobotActions.machineDetailsRobotListCleaningDataCleanedHrsRequest({
        filter: {
          machineId: robot.id || '',
          period: {
            endAt,
            startAt,
          },
          routeName,
          timeZone,
        },
      })
    );
  }, [dispatch, endAt, robot.id, startAt, routeName, timeZone]);

  const periodData = useMemo(() => {
    const period = getDatesByPeriod(startTime, endTime).map(d => d.format(DateFormats.DATE_IN_YEAR_MONTH_DAY));
    const result: CleaningTaskCleanedHrsByDay[] = [];
    period.forEach(date => {
      const data = cleaningDataCleanedHrsByDay.find(
        item => dayjs(item.date).format(DateFormats.DATE_IN_YEAR_MONTH_DAY) === date
      );
      if (!data) {
        result.push({ ...DEFAULT_VALUE, date });
      } else {
        result.push(data);
      }
    });
    return result;
  }, [cleaningDataCleanedHrsByDay, endTime, startTime]);

  const cleanedHrs = periodData.map(item => Numbers.formatNumberDecimalRounded(item.cleanedHrs, 1));
  const finishDates = periodData.map(item => item.date);

  return (
    <StyledMachineDetailsRobotCleanedHoursChart>
      <Spin size="large" className="robot-cleaned-hrs-chart__spin" spinning={cleanedHrsIsLoading}>
        <MachineRobotsCleaningDataChartHeader
          labelNoteSquare={t('machineDetails.robotCleaningData.cleanedHours.note')}
          periodLabel={t('machineDetails.robotCleaningData.cleanedHours.periodCleanedHours')}
          totalLabel={t('machineDetails.robotCleaningData.cleanedHours.totalCleanedHours')}
          periodValue={Numbers.formatNumberDecimalRounded(periodCleanedHrs, 1)}
          totalValue={Numbers.formatNumberDecimalRounded(totalCleanedHrs, 1)}
          unit={t('common.hUnit')}
        />
        <div className="robot-cleaned-hours-chart__wrapper">
          <div className="robot-cleaned-hours-chart__unit">
            <p>
              {t('machineDetails.robotCleaningData.cleanedHours.hrs')}&nbsp;{t('common.hUnit')}
            </p>
          </div>
          <MachineDetailsRobotsBarChart
            period={dates}
            cleaningValues={cleanedHrs}
            unit={t('common.hUnit')}
            finishDates={finishDates}
          />
        </div>
      </Spin>
    </StyledMachineDetailsRobotCleanedHoursChart>
  );
};
