import { Action } from 'redux';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from '../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';

export type IGetFooRequestAction = Action<typeof FooActions.GET_FOO_REQUEST>;

export interface IGetFooSuccessOptions {
  foo: any;
}

export interface IGetFooSuccessAction extends Action<typeof FooActions.GET_FOO_SUCCESS> {
  payload: {
    foo: string;
  };
}

export type IGetFooErrorAction = IErrorAction<typeof FooActions.GET_FOO_ERROR>;

export class FooActions {
  public static GET_FOO_REQUEST = 'FOO::GET_FOO_REQUEST';
  public static GET_FOO_SUCCESS = 'FOO::GET_FOO_SUCCESS';
  public static GET_FOO_ERROR = 'FOO::GET_FOO_ERROR';

  public static getFooRequest(): IGetFooRequestAction {
    return {
      type: FooActions.GET_FOO_REQUEST,
    };
  }

  public static getFooSuccess({ foo }: IGetFooSuccessOptions): IGetFooSuccessAction {
    return {
      type: FooActions.GET_FOO_SUCCESS,
      payload: {
        foo,
      },
    };
  }

  public static getFooError({ error }: IActionCreatorErrorOptions): IGetFooErrorAction {
    return {
      type: FooActions.GET_FOO_ERROR,
      error,
    };
  }
}
