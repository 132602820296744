import { createGlobalStyle } from 'styled-components';

export const MachineMapGlobalStyles = createGlobalStyle`
  // Since this is a global style, defining this from the MachineMapPin would result in this style being added
  // multiple times (once per pin, instead of once overall). Therefore this is defined on the map instead
  .machine-map-pin__overlay {
    // to avoid the popover from floating on the modal of machine details - modal's z-index: 1000
    z-index: 999;

    .ant-popover-arrow {
      display: none;
    }
  }
`;
