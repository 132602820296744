import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MachineDetailsRobotCleanedAreaChart } from '../MachineDetailsRobotsCleaningDataChart/MachineDetailsRobotCleanedAreaChart/MachineDetailsRobotCleanedAreaChart';
import { MachineDetailsRobotConsumablesChart } from '../MachineDetailsRobotsCleaningDataChart/MachineDetailsRobotConsumablesChart/MachineDetailsRobotConsumablesChart';
import { MachineDetailsRobotDistanceDrivenChart } from '../MachineDetailsRobotsCleaningDataChart/MachineDetailsRobotDistanceDrivenChart/MachineDetailsRobotDistanceDrivenChart';
import { MachineDetailsRobotCleanedHoursChart } from '../MachineDetailsRobotsCleaningDataChart/MachineDetailsRobotCleanedHoursChart/MachineDetailsRobotCleanedHoursChart';
import { MachineDetailsRobotFilter } from '../MachineDetailsRobotFilter/MachineDetailsRobotFilter';
import { StyledMachineDetailsRobotCleaningData } from './MachineDetailsRobotCleaningData.styles';
import { FeatureFlagSelectors } from 'app/cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { LegacyTabs } from 'lib/components/LegacyTabs/LegacyTabs';

export enum MachineDetailsRobotCleaningDataTabs {
  CLEANED_AREA = 'cleaning-area',
  CLEANED_HOURS = 'cleaning-hours',
  DISTANCE_DRIVEN = 'distance-driven',
  CONSUMABLES = 'consumables',
}

export const MachineDetailsRobotCleaningData = (): JSX.Element => {
  const { t } = useTranslation();
  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const isRobotDetailsEnabled = features.ROBOTICS_DETAILS_PAGE;

  const tabs = [
    {
      label: t('machineDetails.robotCleaningData.tabs.cleanedArea'),
      key: MachineDetailsRobotCleaningDataTabs.CLEANED_AREA,
      children: <MachineDetailsRobotCleanedAreaChart />,
    },
    {
      label: t('machineDetails.robotCleaningData.tabs.cleanedHours'),
      key: MachineDetailsRobotCleaningDataTabs.CLEANED_HOURS,
      children: <MachineDetailsRobotCleanedHoursChart />,
    },
  ];

  if (isRobotDetailsEnabled) {
    tabs.push(
      {
        label: t('machineDetails.robotCleaningData.tabs.distanceDriven'),
        key: MachineDetailsRobotCleaningDataTabs.DISTANCE_DRIVEN,
        children: <MachineDetailsRobotDistanceDrivenChart />,
      },
      {
        label: t('machineDetails.robotCleaningData.tabs.consumables'),
        key: MachineDetailsRobotCleaningDataTabs.CONSUMABLES,
        children: <MachineDetailsRobotConsumablesChart />,
      }
    );
  }

  return (
    <StyledMachineDetailsRobotCleaningData className="machine-details-robot-cleaning-data">
      <div className="machine-details-robot-cleaning-data__bar">
        <LegacyTabs
          destroyInactiveTabPane={true}
          items={tabs}
          className="machine-details-robot-cleaning-data__tabs"
          tabBarExtraContent={{
            left: <MachineDetailsRobotFilter />,
          }}
        />
      </div>
    </StyledMachineDetailsRobotCleaningData>
  );
};
