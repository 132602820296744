import { useTranslation } from 'react-i18next';
import { StyledMachineRobotCleaningRoute } from './MachineRobotCleaningRoute.styles';
import { RobotViewModel } from 'app/modules/cleaning/widgets/robot-dashboard/RobotViewModel';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';

export const MachineRobotCleaningRoute = ({ robot }: { robot: Machine }): JSX.Element => {
  const { t } = useTranslation();
  const robotModelView = new RobotViewModel(robot);

  return (
    <StyledMachineRobotCleaningRoute>
      <p className="robot-cleaning-route__label">{t(robotModelView.getCleaningRouteLabel)}</p>
      <span className="robot-cleaning-route__details">
        {robotModelView.getRobotCleaningRouteNameAndDate.routeName || t('common.NA')}
      </span>
    </StyledMachineRobotCleaningRoute>
  );
};
