import { Image } from 'antd';
import React from 'react';
import { Machine } from '../../interfaces/Machine.types';
import { MachinePictureLoader } from '../MachinePictureLoader/MachinePictureLoader';
import { StyledMachineNameBanner } from './MachineNameBanner.styles';

export interface MachineNameBannerProps {
  machine: Machine;
}

export const MachineNameBanner = ({ machine }: MachineNameBannerProps): React.JSX.Element => {
  const machineImage = machine.variant?.picture.product;

  return (
    <StyledMachineNameBanner className="machine-name-banner">
      <div className="machine-name-banner__image">
        {machineImage ? (
          <Image src={machineImage} width={140} height={140} preview={false} />
        ) : (
          <MachinePictureLoader className="machine-name-banner__image--loading" />
        )}
      </div>
      <div className="machine-name-banner__info">
        <div className="machine-name-banner__name">{machine.name}</div>
        <div className="machine-name-banner__type">{machine.type?.name}</div>
      </div>
    </StyledMachineNameBanner>
  );
};
