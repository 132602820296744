import classnames from 'classnames';
import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { get, isNil, groupBy, pickBy, isArray, intersection, isNumber, flattenDeep } from 'lodash-es';
import SuperCluster, { AnyProps } from 'supercluster';
// eslint-disable-next-line import/no-unresolved
import * as GeoJSON from 'geojson';
import GoogleMapReact, { Coords } from 'google-map-react';
import { MachineMapPin, MachineMapPinProps, MachineMarkerType } from '../MachineMapPin/MachineMapPin';
import { Machine } from '../../interfaces/Machine.types';
import { MachineMapGlobalStyles } from './MachineMap.global.styles';
import { StyledMachineMap } from './MachineMap.styles';
import { theme } from 'config/theme';
import { Location } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { MAP_BOUNDING_BOX } from 'config/constants';
import { MapUtils } from 'lib/utils/map/MapUtils';
import { GeoJson } from 'lib/utils/map/GeoJson';

const CONSTANTS = {
  boundingBox: [MAP_BOUNDING_BOX.sw.lng, MAP_BOUNDING_BOX.sw.lat, MAP_BOUNDING_BOX.ne.lng, MAP_BOUNDING_BOX.ne.lat],
  clusterRadius: 60,
  maxZoom: 16,
  enableZoomPadding: true,
  initSize: { width: 1, height: 1 },
};

export interface MachineMapProps {
  className?: string;
  machines: Machine[];
  isLoading?: boolean;
}

interface MachineWithNonOptionalLocation extends Machine {
  location: Location;
}

export type ClusterOrPointFeature =
  | SuperCluster.ClusterFeature<SuperCluster.AnyProps>
  | SuperCluster.PointFeature<SuperCluster.AnyProps>;

// Only interfaces declarations can be self-referencing, type declarations cannot.
// This is why an empty interface is necessary here
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NestedClusterOrPointFeatureArray
  extends Array<ClusterOrPointFeature | NestedClusterOrPointFeatureArray> {}

const hasLocationData = (machine: Machine): boolean =>
  !isNil(get(machine, 'location.latitude')) && !isNil(get(machine, 'location.longitude'));

const isClusterMarker = (
  clusterOrPointFeature: ClusterOrPointFeature
): clusterOrPointFeature is SuperCluster.ClusterFeature<SuperCluster.AnyProps> =>
  clusterOrPointFeature?.properties?.cluster === true;

export const MachineMap = ({ className, machines, isLoading }: MachineMapProps): JSX.Element => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const mapInstanceRef = useRef<any>(null);
  const mapApisRef = useRef<any>(null);
  const [isViewportInitialized, setIsViewportInitialized] = useState(false);
  const [googleApisInitialized, setGoogleApisInitialized] = useState(false);
  const [activePinId, setActivePinId] = useState<string | number | null>(null);
  const [superCluster, setSuperCluster] = useState<SuperCluster<AnyProps, AnyProps>>();
  const [size, setSize] = useState<{ width: number; height: number }>(CONSTANTS.initSize);
  const [center, setCenter] = useState<GoogleMapReact.Coords | undefined>(undefined);
  const [zoom, setZoom] = useState<number | undefined>(undefined);
  const machinesWithLocations = machines.filter(hasLocationData) as MachineWithNonOptionalLocation[];

  // Cluster can have other clusters as children. In order to get all the machines that are part of a cluster
  // we need to check all nested clusters recursively
  const recursivelyGetClusterChildren = useCallback(
    function recursivelyGetClusterChildren(
      clusterOrPointFeature: ClusterOrPointFeature
    ): NestedClusterOrPointFeatureArray | NestedClusterOrPointFeatureArray[] {
      if (!isClusterMarker(clusterOrPointFeature)) {
        return [clusterOrPointFeature];
      }

      if (!isNumber(clusterOrPointFeature.id)) {
        return [];
      }

      const directClusterChildren = superCluster?.getChildren(clusterOrPointFeature.id as unknown as number) ?? [];
      const recursiveClusterChildren = directClusterChildren.map(child => recursivelyGetClusterChildren(child));

      return recursiveClusterChildren;
    },
    [superCluster]
  );

  const machinesGroupedByLocation = useMemo(
    () =>
      groupBy(machinesWithLocations, (machine: MachineWithNonOptionalLocation) =>
        JSON.stringify({
          lat: machine.location.latitude,
          lng: machine.location.longitude,
        })
      ),
    [machinesWithLocations]
  );

  const points: Coords[] = useMemo(
    () =>
      machinesWithLocations.map(machine => ({
        lat: machine.location?.latitude ?? 0,
        lng: machine.location?.longitude ?? 0,
      })),
    [machinesWithLocations]
  );

  const boundingBox = useMemo(
    () => MapUtils.calculateBoundingBox({ googleApisInitialized, mapApisRef, points }),
    [googleApisInitialized, points]
  );

  const initialCenter = useMemo(
    () => MapUtils.calculateCenter({ points, boundingBox, size }),
    [boundingBox, points, size]
  );

  const initialZoom = useMemo(
    () => MapUtils.calculateZoom({ points, boundingBox, size, enableZoomPadding: CONSTANTS.enableZoomPadding }),
    [points, boundingBox, size]
  );

  const handleClick = useCallback(
    (newActivePinId: string): void => {
      if (activePinId === newActivePinId) {
        setActivePinId(null);
        return;
      }

      setActivePinId(newActivePinId);
    },
    [activePinId]
  );

  const closeAllPins = useCallback(() => setActivePinId(null), []);

  const onZoomAnimationEnd = (newZoom: number): void => {
    setZoom(newZoom);
  };

  const onChange = useCallback(
    ({ center: newCenter, zoom: newZoom }: { center: Coords | undefined; zoom: number | undefined }): void => {
      if (isViewportInitialized) {
        setCenter(newCenter);
        setZoom(newZoom);
        return;
      }

      if (initialCenter !== undefined && initialZoom !== undefined) {
        setCenter(initialCenter);
        setZoom(initialZoom);
        setIsViewportInitialized(true);
      }
    },
    [initialCenter, initialZoom, isViewportInitialized]
  );

  const onClickCluster = useCallback(
    (clusterId?: string | number): void => {
      const id = typeof clusterId === 'string' ? parseInt(clusterId, 10) : clusterId;
      if (id && superCluster) {
        // Set new zoom value and center based on the locations of the cluster's children
        const children = superCluster.getChildren(id);
        const pointsOfChildren = children.map(child => ({
          lng: GeoJson.getLongitude(child.geometry.coordinates),
          lat: GeoJson.getLatitude(child.geometry.coordinates),
        }));
        const boundingBoxOfChildren = MapUtils.calculateBoundingBox({
          googleApisInitialized,
          mapApisRef,
          points: pointsOfChildren,
        });
        const centerOfChildren = MapUtils.calculateCenter({
          points: pointsOfChildren,
          boundingBox: boundingBoxOfChildren,
          size,
        });
        const zoomForChildren = MapUtils.calculateZoom({
          points: pointsOfChildren,
          boundingBox: boundingBoxOfChildren,
          size,
          enableZoomPadding: CONSTANTS.enableZoomPadding,
        });
        setCenter(centerOfChildren);
        setZoom(zoomForChildren);
      }
    },
    [googleApisInitialized, size, superCluster]
  );

  const onGoogleApisInitialized = ({ mapInstance, mapApis }: { mapInstance: any; mapApis: any }): void => {
    mapInstanceRef.current = mapInstance;
    mapApisRef.current = mapApis;
    setGoogleApisInitialized(true);
  };

  useEffect(() => {
    setSuperCluster(
      new SuperCluster({
        radius: CONSTANTS.clusterRadius,
        maxZoom: CONSTANTS.maxZoom,
      })
    );
  }, []);

  useEffect(
    () =>
      setSize({
        width: mapRef.current?.clientWidth ?? 1,
        height: mapRef.current?.clientHeight ?? 1,
      }),
    []
  );

  useEffect(() => {
    // Super hacky way to trigger setting the initial viewport (zoom and center). Don't do this at home (☉_ ☉)
    // Since there was no suitable callback we are triggering the initial onChange after a small timeout.
    if (!isViewportInitialized) {
      setTimeout(() => {
        onChange({ center: undefined, zoom: undefined });
      }, 100);
    }
  }, [isViewportInitialized, onChange]);

  const mapPins: MachineMapPinProps[] = useMemo((): MachineMapPinProps[] => {
    const initMachineMapPins = {
      backgroundColor: theme.colors.celadonGreen,
      textColor: theme.colors.white,
      onClick: handleClick,
    };

    const unclusteredMapPins = machinesWithLocations.map(machine => ({
      machines: [machine],
      lat: machine.location?.latitude,
      lng: machine.location?.longitude,
      backgroundColor: theme.colors.celadonGreen,
      textColor: theme.colors.white,
      visible: machine.id === activePinId,
      onClick: handleClick,
      type: MachineMarkerType.SINGLE,
    }));
    const machineMapPins: MachineMapPinProps[] = [];
    const clusteredMachineIds: string[] = [];

    if (
      !googleApisInitialized ||
      isNil(mapApisRef) ||
      isNil(mapApisRef.current) ||
      isNil(mapInstanceRef) ||
      isNil(mapInstanceRef.current)
    ) {
      return unclusteredMapPins;
    }

    const clusterCalculationBoundingBox: google.maps.LatLngBounds | undefined = mapInstanceRef.current.getBounds();

    if (!clusterCalculationBoundingBox) return unclusteredMapPins;

    const multiMachineMarkerGroups = pickBy(machinesGroupedByLocation, value => isArray(value) && value.length >= 2);
    const singleMachineMarkerGroups = pickBy(
      machinesGroupedByLocation,
      value => isArray(value) && value.length < 2 && value.length > 0
    );

    const multiMachineMarkerPointFeatures: SuperCluster.PointFeature<SuperCluster.AnyProps>[] = Object.values(
      multiMachineMarkerGroups
    ).map((group: any) => {
      const coordinates = GeoJson.createPosition({
        longitude: group[0].location?.longitude ?? 0,
        latitude: group[0].location?.latitude ?? 0,
      });

      return {
        type: 'Feature',
        geometry: { type: 'Point', coordinates },
        properties: { machines: group, count: group.length, type: MachineMarkerType.MULTI },
      };
    });

    const singleMachineMarkerPointFeatures: SuperCluster.PointFeature<SuperCluster.AnyProps>[] = Object.values(
      singleMachineMarkerGroups
    ).map((group: any) => {
      const coordinates = GeoJson.createPosition({
        longitude: group[0].location?.longitude ?? 0,
        latitude: group[0].location?.latitude ?? 0,
      });

      return {
        type: 'Feature',
        geometry: { type: 'Point', coordinates },
        properties: { machines: group, count: group.length, type: MachineMarkerType.SINGLE },
      };
    });

    const allPointFeatures = [...multiMachineMarkerPointFeatures, ...singleMachineMarkerPointFeatures];

    // Get map pin of cluster
    if (superCluster && zoom) {
      superCluster.load(allPointFeatures);

      const clusters = superCluster.getClusters(
        [
          MAP_BOUNDING_BOX.sw.lng,
          MAP_BOUNDING_BOX.sw.lat,
          MAP_BOUNDING_BOX.ne.lng,
          MAP_BOUNDING_BOX.ne.lat,
        ] as GeoJSON.BBox,
        zoom
      );

      clusters.forEach(cluster => {
        const id = cluster.id;

        if (isNil(id)) return;
        const clusterId = typeof id === 'number' ? id : parseInt(id, 10);

        // `reduce` will return an array with arrays nested inside. In order to get a flat list of children we
        // need to flatten the array recursively
        const clusterChildren = flattenDeep(
          superCluster
            .getChildren(clusterId)
            .reduce((accumulator: NestedClusterOrPointFeatureArray, clusterChild: ClusterOrPointFeature) => {
              accumulator.push(recursivelyGetClusterChildren(clusterChild));

              return accumulator;
            }, [])
        );

        const clusterMachines: Machine[] = [];

        clusterChildren.forEach(clusterChild => {
          clusterChild?.properties?.machines?.forEach((machine: Machine) => {
            clusterMachines.push(machine);
            clusteredMachineIds.push(machine.id);
          });
        });

        machineMapPins.push({
          id: clusterId,
          type: MachineMarkerType.CLUSTER,
          machines: clusterMachines,
          lng: GeoJson.getLongitude(cluster.geometry?.coordinates),
          lat: GeoJson.getLatitude(cluster.geometry?.coordinates),
          visible: clusterId === activePinId,
          clusterInfo: {
            pointCount: clusterMachines.length,
            clusterId,
            onClickCluster,
          },
          ...initMachineMapPins,
        });
      });
    }

    multiMachineMarkerPointFeatures.forEach(multiMachineMarker => {
      const machineIds = multiMachineMarker.properties.machines.map((machine: Machine) => machine.id);

      // If machine is already part of a cluster do not display a multi machine marker for it
      if (intersection(machineIds, clusteredMachineIds).length > 0) {
        return;
      }

      machineMapPins.push({
        id: multiMachineMarker.properties.machines[0].id,
        type: MachineMarkerType.MULTI,
        machines: multiMachineMarker.properties.machines,
        lng: GeoJson.getLongitude(multiMachineMarker.geometry?.coordinates),
        lat: GeoJson.getLatitude(multiMachineMarker.geometry?.coordinates),
        visible: multiMachineMarker.properties.machines[0].id === activePinId,
        ...initMachineMapPins,
      });
    });

    singleMachineMarkerPointFeatures.forEach(singleMachineMarker => {
      const machineIds = singleMachineMarker.properties.machines.map((machine: Machine) => machine.id);

      // If machine is already part of a cluster do not display a machine marker for it
      if (intersection(machineIds, clusteredMachineIds).length > 0) {
        return;
      }

      machineMapPins.push({
        id: singleMachineMarker.properties.machines[0].id,
        type: MachineMarkerType.SINGLE,
        machines: singleMachineMarker.properties.machines,
        lng: GeoJson.getLongitude(singleMachineMarker.geometry?.coordinates),
        lat: GeoJson.getLatitude(singleMachineMarker.geometry?.coordinates),
        visible: singleMachineMarker.properties.machines[0].id === activePinId,
        ...initMachineMapPins,
      });
    });

    return machineMapPins;
  }, [
    activePinId,
    googleApisInitialized,
    handleClick,
    machinesGroupedByLocation,
    machinesWithLocations,
    onClickCluster,
    recursivelyGetClusterChildren,
    superCluster,
    zoom,
  ]);

  return (
    <>
      <MachineMapGlobalStyles />
      <StyledMachineMap
        className={classnames('machine-map', className)}
        ref={mapRef}
        isLoading={isLoading}
        // Close all popups when map is dragged or zoomed, since popup position will not update
        onDrag={closeAllPins}
        onChange={onChange}
        onZoomAnimationStart={closeAllPins}
        onZoomAnimationEnd={onZoomAnimationEnd}
        onGoogleApisInitialized={onGoogleApisInitialized}
        zoom={zoom}
        center={center}
      >
        {mapPins.map((pinProps, i) => (
          <MachineMapPin
            key={`machine-map-pin-${pinProps.id}-${i}`}
            popupContainerRef={mapRef.current ?? undefined}
            {...pinProps}
          />
        ))}
      </StyledMachineMap>
    </>
  );
};
