import styled from 'styled-components';

export const StyledMachineDetailsRobotsCleaningDataChartHeader = styled.div`
  .machine-robots-cleaning-data-chart__chart-note {
    margin-top: ${(props): string => props.theme.spacing.base};
  }

  .machine-robots-cleaning-data-chart__sums {
    display: flex;
    justify-content: flex-end;
  }

  .machine-robots-cleaning-data-chart__chart-note-square {
    height: 8px;
    width: 8px;
    margin: 0 10px 0 0;
    border-radius: 1px;
    background-color: ${(props): string => props.theme.colors.celadonGreen};
  }

  .machine-robots-cleaning-data-chart__chart-note {
    margin: 0 30px 0 0;
    display: flex;
    align-items: baseline;
  }

  .machine-robots-cleaning-data-chart__chart-note-text {
    ${(props): string => props.theme.fontStyles.p1.news};
  }

  .machine-robots-cleaning-data-chart__sums {
    height: 100%;
  }

  .machine-robots-cleaning-data-chart__period {
    margin: 0 32px 0 0;
  }

  .machine-robots-cleaning-data-chart__label {
    ${(props): string => props.theme.fontStyles.redesign.sm.delete};
    text-transform: uppercase;
    margin: 0;
  }

  .machine-robots-cleaning-data-chart__unit {
    ${(props): string => props.theme.fontStyles.redesign.heading4};
  }

  .machine-robots-cleaning-data-chart__value {
    ${(props): string => props.theme.fontStyles.redesign.heading1};
    margin-bottom: 0px;
  }

  .machine-robots-cleaning-data-chart__total {
    color: ${(props): string => props.theme.colors.grey200};
  }
`;
