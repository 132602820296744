import styled from 'styled-components';

export const StyledMachineDetailsRobotConsumablesChartHeader = styled.div`
  .robot-consumables-chart__chart-note {
    margin-top: ${(props): string => props.theme.spacing.base};
    display: flex;
    justify-content: flex-start;
  }

  .robot-consumables-chart__chart-info {
    display: flex;
    justify-content: flex-end;
  }

  .robot-consumables-chart__chart-note-square-left,
  .robot-consumables-chart__chart-note-square-between,
  .robot-consumables-chart__chart-note-square-right {
    height: 8px;
    width: 8px;
    margin: 0 10px 0 0;
    border-radius: 1px;
  }

  .robot-consumables-chart__chart-note-left,
  .robot-consumables-chart__chart-note-between {
    margin: 0 30px 0 0;
    display: flex;
    align-items: baseline;
  }

  .robot-consumables-chart__chart-note-right {
    display: flex;
    align-items: baseline;
  }

  .robot-consumables-chart__chart-note-square-left {
    background-color: ${(props): string => props.theme.colors.semanticSuccess};
  }

  .robot-consumables-chart__chart-note-square-between {
    background-color: ${(props): string => props.theme.colors.celadonGreen};
  }

  .robot-consumables-chart__chart-note-square-right {
    background-color: ${(props): string => props.theme.colors.lightCeladonGreen};
  }

  .robot-consumables-chart__chart-note-text {
    font-family: ${(props): string => props.theme.fonts.p1News};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    font-weight: ${(props): number => props.theme.fontWeights.p1News};
  }

  .robot-consumables-chart__chart-info-left,
  .robot-consumables-chart__chart-info-between {
    margin: 0 32px 0 0;
  }

  .robot-consumables-chart__chart-info-title {
    ${(props): string => props.theme.fontStyles.redesign.sm.delete};
    text-transform: uppercase;
  }

  .robot-consumables-chart__chart-info-content {
    ${(props): string => props.theme.fontStyles.redesign.heading1};
    margin-bottom: 0px;
  }

  .robot-consumables-chart__chart-info-unit {
    ${(props): string => props.theme.fontStyles.redesign.heading4};
  }
`;
