import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Spin, Button } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { DateTime } from '../../../../../../lib/utils/date-handling/DateTime';
import { DATE_FORMAT_PATTERN } from '../../../../../../lib/utils/date-handling/DateTime.types';
import { DeleteReminderModal } from '../../../../reminder/modals/components/DeleteReminderModal/DeleteReminderModal';
import { Machine } from '../../../interfaces/Machine.types';
import { EditMachineNameModal } from '../../../modals/components/EditMachineNameModal/EditMachineNameModal';
import { StyledMachineDetailsInfo } from './MachineDetailsInfo.styles';
import { ThemeConstants } from 'config/theme';
import { isUserPermitted } from 'app/cross-cutting-concerns/authentication/utils/isUserPermitted';
import { selectPermissions } from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { Permission } from 'config/permissions';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { MachineModalsActions } from 'app/modules/machine-inventory/modals/state/machineModalsSlice';
import { selectIsMetadataLoading } from 'app/modules/machine-inventory/machine-details-panel/state/machineDetailsPanelSelectors';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';
import { RoutePaths } from 'config/route-paths';

const CONSTANTS = { defaultValue: '-' };

export const MachineDetailsInfo = ({ machine }: { machine: Machine }): JSX.Element => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const userPermissions = useSelector(selectPermissions);
  const isMetadataLoading = useSelector(selectIsMetadataLoading);

  const showEditMachineInfoModal = (): void => {
    dispatch(MachineModalsActions.showEditMachineInfoModal());
  };

  const handleClickSiteName = (siteId?: string): void => {
    if (!siteId) return;
    if (location.pathname.startsWith(RoutePaths.MACHINES)) {
      navigate(RoutePaths.MACHINES);
    }
    dispatch(DrawersActions.showSiteDetailsDrawer({ siteId }));
  };

  return (
    <StyledMachineDetailsInfo className="machine-details-info">
      <Spin spinning={!!isMetadataLoading}>
        <div className="machine-details-info__info-header">
          <h3 tabIndex={0} className="machine-details-info__sub-header">
            {t('machineDetails.generalInfo.machineInfo')}
          </h3>
          <Button
            className="machine-details-info__action"
            hidden={!isUserPermitted(userPermissions, [Permission.Machine.UPDATE])}
            size="small"
            type="text"
            icon={<SvgIcon name="edit" />}
            onClick={showEditMachineInfoModal}
          />
        </div>
        <Row gutter={[ThemeConstants.BASE_GAP, ThemeConstants.BASE_GAP]}>
          <Col span={8}>
            <div>
              <h4 className="machine-details-info__sub-title">{t('machineDetails.info.name')}</h4>
              {machine.name}
            </div>
            <div className="machine-details-info__block">
              <h4 className="machine-details-info__sub-title">{t('machineDetails.info.material')}</h4>
              {machine.materialNumber || CONSTANTS.defaultValue}
            </div>
            <div className="machine-details-info__block">
              <h4 className="machine-details-info__sub-title">{t('machineDetails.info.serviceType')}</h4>
              {machine.metadata?.service?.servicePackage
                ? t(`machineModals.editMachineInfo.servicePackage.${machine.metadata?.service?.servicePackage}`)
                : CONSTANTS.defaultValue}
            </div>
            <div className="machine-details-info__block">
              <h4 className="machine-details-info__sub-title">{t('machineDetails.info.software')}</h4>
              {machine.softwareVersion || CONSTANTS.defaultValue}
            </div>
          </Col>
          <Col span={8}>
            <div>
              <h4 className="machine-details-info__sub-title">{t('machineDetails.info.type')}</h4>
              {machine.type?.name || CONSTANTS.defaultValue}
            </div>
            <div className="machine-details-info__block">
              <h4 className="machine-details-info__sub-title">{t('machineDetails.info.serial')}</h4>
              {machine.serialNumber || CONSTANTS.defaultValue}
            </div>
            <div className="machine-details-info__block">
              <h4 className="machine-details-info__sub-title">{t('machineDetails.info.financeType')}</h4>
              {machine.metadata?.finance?.financeType
                ? t(`machineModals.editMachineInfo.financeType.${machine.metadata?.finance?.financeType}`)
                : CONSTANTS.defaultValue}
            </div>
            <div className="machine-details-info__activation-date machine-details-info__block">
              <h4 className="machine-details-info__sub-title">{t('machineDetails.info.activationDate')}</h4>
              {DateTime.formatDateByLocale(i18n.language, machine.activationDate, DATE_FORMAT_PATTERN.DATE)}
            </div>
          </Col>
          <Col span={8}>
            <div className="machine-details-info__site">
              <h4 className="machine-details-info__sub-title">{t('machineDetails.info.site')}</h4>
              {machine.site ? (
                <Button
                  className="machine-details-info__button--no-styling"
                  type="text"
                  onClick={(): void => handleClickSiteName(machine.site?.id)}
                >
                  {machine.site?.name || CONSTANTS.defaultValue}
                </Button>
              ) : (
                CONSTANTS.defaultValue
              )}
            </div>
            <div className="machine-details-info__block">
              <h4 className="machine-details-info__sub-title">{t('machineDetails.info.inventoryNo')}</h4>
              {machine.metadata?.inventoryNumber || CONSTANTS.defaultValue}
            </div>
            <div className="machine-details-info__block">
              <h4 className="machine-details-info__sub-title">{t('machineDetails.info.financeContractNo')}</h4>
              {machine.metadata?.finance?.contractNumber || CONSTANTS.defaultValue}
            </div>
          </Col>
        </Row>
      </Spin>
      <EditMachineNameModal machine={machine} />
      <DeleteReminderModal />
    </StyledMachineDetailsInfo>
  );
};
