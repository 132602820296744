import styled from 'styled-components';

export const StyledMachineDetailsNotificationList = styled.div`
  border: 1px solid ${(props): string => props.theme.colors.brightGrey};
  padding: ${(props): string => props.theme.spacing.lg};

  .machine-details-notification-list__header {
    .machine-details-notification-list__title {
      ${(props): string => props.theme.fontStyles.redesign.xl.strong};
      font-weight: 700;
      margin-bottom: 0;
    }

    margin-bottom: ${(props): string => props.theme.spacing.md};
  }

  .machine-details-notification-list__timestamp-container {
    display: flex;
    gap: 8px;
  }

  .machine-details-notification-list__body {
    .machine-details-notification-list__table {
      .ant-table-container table .ant-table-thead > tr > th {
        ${(props): string => props.theme.fontStyles.redesign.sm.delete};
        font-weight: 700;
      }

      .ant-table-tbody {
        .ant-table-row {
          .ant-table-cell {
            ${(props): string => props.theme.fontStyles.redesign.sm.normal};

            svg {
              width: 32px;
              height: 32px;
            }
          }
        }
      }

      .ant-table-footer {
        padding: ${(props): string => props.theme.spacing.xs};
      }
    }
  }
`;
