import React from 'react';
import { StyledMachineDetailsRobotsCleaningDataChartHeader } from './MachineDetailsRobotsCleaningDataChartHeader.styles';

interface MachineRobotsCleaningDataChartHeaderProps {
  labelNoteSquare: string;
  periodLabel: string;
  totalLabel: string;
  periodValue: number;
  totalValue: number;
  unit: string;
}

export const MachineRobotsCleaningDataChartHeader = ({
  labelNoteSquare,
  periodLabel,
  totalLabel,
  periodValue,
  totalValue,
  unit,
}: MachineRobotsCleaningDataChartHeaderProps): JSX.Element => (
  <StyledMachineDetailsRobotsCleaningDataChartHeader>
    <div className="machine-robots-cleaning-data-chart__sums">
      <div className="machine-robots-cleaning-data-chart__period">
        <p className="machine-robots-cleaning-data-chart__label">{periodLabel}</p>
        <p className="machine-robots-cleaning-data-chart__value">
          {periodValue}&nbsp;<span className="machine-robots-cleaning-data-chart__unit">{unit}</span>
        </p>
      </div>
      <div className="machine-robots-cleaning-data-chart__total">
        <p className="machine-robots-cleaning-data-chart__label">{totalLabel}</p>
        <p className="machine-robots-cleaning-data-chart__value">
          {totalValue}&nbsp;<span className="machine-robots-cleaning-data-chart__unit">{unit}</span>
        </p>
      </div>
    </div>
    <div className="machine-robots-cleaning-data-chart__legend">
      <div className="machine-robots-cleaning-data-chart__chart-note">
        <div className="machine-robots-cleaning-data-chart__chart-note-square" />
        <p className="machine-robots-cleaning-data-chart__chart-note-text">{labelNoteSquare}</p>
      </div>
    </div>
  </StyledMachineDetailsRobotsCleaningDataChartHeader>
);
