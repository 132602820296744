import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { isObject, isString } from 'lodash-es';
import {
  ILegacyMachineStatusItemData,
  Machine,
  LegacyMachineStatus,
  LegacyMachineStatusItemData,
} from '../../interfaces/Machine.types';
import { getLegacyMachineStatus } from '../../utils/getLegacyMachineStatus';
import { StyledLegacyMachineStatus } from './LegacyMachineStatusIndicator.styles';
import { LegacyMachineStatusIndicatorTooltip } from './LegacyMachineStatusIndicatorTooltip';
import { LegacyMachineStatusIndicatorGlobalStyles } from './LegacyMachineStatusIndicator.global.styles';
import { Tooltip } from 'lib/components/Tooltip/Tooltip';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { Optional } from 'lib/types/Optional';

export interface LegacyMachineStatusIndicatorProps {
  machine?: Machine;
  status?: LegacyMachineStatus;
  showText?: boolean;
}

export const LegacyMachineStatusIndicator = ({
  machine,
  status,
  showText,
}: LegacyMachineStatusIndicatorProps): React.JSX.Element | null => {
  const { t } = useTranslation();

  const statusItem = useMemo((): Optional<ILegacyMachineStatusItemData> => {
    let machineStatus = null;

    if (isString(status)) return LegacyMachineStatusItemData[status];

    if (!isObject(machine)) {
      throw Error('One of the props `machine` and `statusValues` needs to be specified.');
    }

    machineStatus = getLegacyMachineStatus(machine);

    if (!machineStatus) {
      return null;
    }

    return machineStatus;
  }, [machine, status]);

  if (!statusItem) {
    return null;
  }

  return (
    <StyledLegacyMachineStatus className="legacy-machine-connection-status">
      <LegacyMachineStatusIndicatorGlobalStyles />
      <Tooltip
        title={<LegacyMachineStatusIndicatorTooltip statusItem={statusItem} />}
        overlayClassName="legacy-machine-connection-status-tooltip__overlay"
      >
        <div className="legacy-machine-connection-status__tooltip-wrapper">
          <SvgIcon name={statusItem.icon} className="legacy-machine-connection-status__icon" />
          {showText && <span className="legacy-machine-connection-status__text">&nbsp; {t(statusItem.title)}</span>}
        </div>
      </Tooltip>
    </StyledLegacyMachineStatus>
  );
};
