import classnames from 'classnames';
import addDays from 'date-fns/addDays';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LegacyTextButton } from '../../../../../../lib/components/LegacyButton/LegacyTextButton/LegacyTextButton';
import { MessageList } from '../../../../../../lib/components/MessageList/MessageList';
import { SvgIcon } from '../../../../../../lib/components/SvgIcon/SvgIcon';
import { DateTime } from '../../../../../../lib/utils/date-handling/DateTime';
import { Reminder } from '../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Machine } from '../../../interfaces/Machine.types';
import { MachineDetailsActions } from '../../state/machineDetailsActions';
import * as machineDetailsSelectors from '../../state/machineDetailsSelectors';
import { StyledMachineReminderList } from './MachineReminderList.styles';
import { MACHINE_REMINDER_LIST_PAGE_SIZE } from 'app/modules/machine-inventory/utils/constants';
import { ReminderModalsActions } from 'app/modules/reminder/modals/state/reminderModalsActions';
import { IMessage } from 'lib/components/MessageList/MessageList.types';
import { CreateReminderModal } from 'app/modules/reminder/modals/components/CreateReminderModal/CreateReminderModal';

export interface MachineReminderListProps {
  machine: Machine;
}

export const MachineReminderList = ({ machine }: MachineReminderListProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const reminders = useSelector(machineDetailsSelectors.selectRemindersData) || [];
  const isInitialLoad = !!useSelector(machineDetailsSelectors.selectRemindersIsInitialLoad);
  const isLoadingMore = !!useSelector(machineDetailsSelectors.selectRemindersIsLoadingMore);
  const totalCount = useSelector(machineDetailsSelectors.selectRemindersTotalCount) || 0;
  const paginationTokens = useSelector(machineDetailsSelectors.selectRemindersPaginationTokens);
  const page = useSelector(machineDetailsSelectors.selectRemindersPage);
  const nextPagePaginationToken = paginationTokens[page + 1];

  const hasNextPage = paginationTokens[page + 1] !== null;

  const isApproachingDueDate = (date: Date): boolean => {
    const dateTimeToCountAsDue = addDays(date, -7);
    const now = new Date();

    return now >= dateTimeToCountAsDue;
  };

  const messages = reminders.map<IMessage<Reminder>>(reminder => ({
    id: reminder.id,
    icon: <SvgIcon name="bellCircle" />,
    title: reminder.topic,
    subtitle: (
      <div
        className={classnames('machine-reminder-list__reminder-details', {
          'machine-reminder-list__reminder-details--due-date-soon': isApproachingDueDate(
            new Date(reminder.nextRemindAt)
          ),
        })}
        title={`${DateTime.formatDateByLocale(i18n.language, reminder.nextRemindAt)}, ${t(
          `machineReminder.repetition.${reminder.repetition}`
        )}`}
      >
        <span className="machine-reminder-list__reminder-next-date">
          {DateTime.formatDateByLocale(i18n.language, reminder.nextRemindAt)}
        </span>
        {', '}
        <span className="machine-reminder-list__reminder-repetition">
          {t(`machineReminder.repetition.${reminder.repetition}`)}
        </span>
      </div>
    ),
    description: reminder.description,
    originalEntity: reminder,
  }));

  const loadMore = useCallback(() => {
    dispatch(
      MachineDetailsActions.getMachineRemindersRequest({
        machineId: machine.id,
        paginationOptions: {
          paginationToken: nextPagePaginationToken,
          limit: MACHINE_REMINDER_LIST_PAGE_SIZE,
        },
        append: true,
      })
    );
  }, [dispatch, machine.id, nextPagePaginationToken]);

  const handleDeleteButtonClick = useCallback(
    (reminder: Reminder): void => {
      dispatch(ReminderModalsActions.showDeleteReminderModal({ reminderToDelete: reminder }));
    },
    [dispatch]
  );

  const handleCreateButtonClick = useCallback(() => {
    dispatch(ReminderModalsActions.showReminderCreateModal());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      MachineDetailsActions.getMachineRemindersRequest({
        machineId: machine.id,
        paginationOptions: {
          paginationToken: '',
          limit: MACHINE_REMINDER_LIST_PAGE_SIZE,
        },
        append: false,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <StyledMachineReminderList className={classnames('machine-reminder-list')}>
      <MessageList<Reminder>
        title={t('machineReminderList.subHeader')}
        messages={messages}
        isInitialLoad={isInitialLoad}
        isLoadingMore={isLoadingMore}
        hasNextPage={hasNextPage}
        totalCount={totalCount}
        loadMore={loadMore}
        listButtonViewRenderer={(_reminders: Reminder[]): React.ReactElement => (
          <>
            <div className="machine-reminder-list__create-button" onClick={handleCreateButtonClick}>
              <SvgIcon name="plus" />
            </div>
          </>
        )}
        messageButtonViewRenderer={(reminder): React.ReactElement => (
          <LegacyTextButton
            className={classnames('machine-reminder-list__reminder-delete-button', 'message__button')}
            onClick={(): void => handleDeleteButtonClick(reminder)}
          >
            <SvgIcon name="delete" />
          </LegacyTextButton>
        )}
        modals={
          <>
            <CreateReminderModal machine={machine} />
          </>
        }
        emptyView={
          <>
            <div className="machine-reminder-list__empty-icon">
              <SvgIcon name="bell" />
            </div>
            <div className="machine-reminder-list__empty-text">{t('machineReminderList.emptyText')}</div>
          </>
        }
      />
    </StyledMachineReminderList>
  );
};
