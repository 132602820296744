import Col from 'antd/lib/grid/col';
import Row from 'antd/lib/grid/row';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledMachineDetailsMapLegendPopover } from './MachineDetailsMapLegendPopover.styles';

export const MachineDetailsMapLegendPopover = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledMachineDetailsMapLegendPopover>
      <Row gutter={12} className="machine-details-map-legend-popover__item">
        <Col span={3} className="machine-details-map-legend-popover__item-icon-column">
          <div
            className={`
            machine-details-map-legend-popover__item-icon
            machine-details-map-legend-popover__item-icon-geofence
            `}
          />
        </Col>
        <Col span={21}>
          <div className="machine-details-map-legend-popover__item-heading">
            {t('machineDetails.map.legend.geofence.heading')}
          </div>
          <div className="machine-details-map-legend-popover__item-body">
            {t('machineDetails.map.legend.geofence.body')}
          </div>
        </Col>
      </Row>
      <Row gutter={12} className="machine-details-map-legend-popover__item">
        <Col span={3} className="machine-details-map-legend-popover__item-icon-column">
          <div
            className={`
            machine-details-map-legend-popover__item-icon
            machine-details-map-legend-popover__item-icon-accuracy
            `}
          />
        </Col>
        <Col span={21}>
          <div className="machine-details-map-legend-popover__item-heading">
            {t('machineDetails.map.legend.accuracy.heading')}
          </div>
          <div className="machine-details-map-legend-popover__item-body">
            {t('machineDetails.map.legend.accuracy.body')}
          </div>
        </Col>
      </Row>
    </StyledMachineDetailsMapLegendPopover>
  );
};
