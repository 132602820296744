import classnames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SvgIcon } from '../../../../../../lib/components/SvgIcon/SvgIcon';
import { SortOrders } from '../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Machine } from '../../../interfaces/Machine.types';
import { AddMachineAttachmentModal } from '../../../modals/components/AddMachineAttachmentModal/AddMachineAttachmentModal';
import { DeleteMachineAttachmentModal } from '../../../modals/components/DeleteMachineAttachmentModal/DeleteMachineAttachmentModal';
import { MachineModalsActions } from '../../../modals/state/machineModalsSlice';
import { MachineDetailsActions } from '../../state/machineDetailsActions';
import {
  selectAttachmentsData,
  selectAttachmentsIsLoading,
  selectAttachmentsPage,
  selectAttachmentsPaginationTokens,
  selectAttachmentsSortField,
  selectAttachmentsSortOrder,
  selectAttachmentsTotalCount,
} from '../../state/machineDetailsSelectors';
import { MachineAttachmentSize } from './MachineAttachment';
import { StyledMachineAttachmentList } from './MachineAttachmentList.styles';
import { MachineAttachmentListContent } from './MachineAttachmentListContent';
import { LegacyTextButton } from 'lib/components/LegacyButton/LegacyTextButton/LegacyTextButton';
import {
  MACHINE_ATTACHMENT_LIST_DEFAULT_SORT_FIELD,
  MACHINE_ATTACHMENT_LIST_PAGE_SIZE_FULL_WIDTH,
  MACHINE_ATTACHMENT_LIST_PAGE_SIZE_LARGE,
  MACHINE_ATTACHMENT_LIST_PAGE_SIZE_MEDIUM,
  MACHINE_ATTACHMENT_LIST_PAGE_SIZE_SMALL,
} from 'app/modules/machine-inventory/utils/constants';
import { SortIcon } from 'app/components/SortIcon/SortIcon';
import { Pagination } from 'lib/components/LegacyTable/Pagination/Pagination';

export interface MachineAttachmentListProps {
  machine: Machine;
  size?: MachineAttachmentSize;
}

export const MachineAttachmentList = ({ machine, size = 'large' }: MachineAttachmentListProps): React.JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const attachments = useSelector(selectAttachmentsData) || [];
  const totalCount = useSelector(selectAttachmentsTotalCount) || 0;
  const isLoading = !!useSelector(selectAttachmentsIsLoading);
  const paginationTokens = useSelector(selectAttachmentsPaginationTokens);
  const page = useSelector(selectAttachmentsPage);
  const sortField = useSelector(selectAttachmentsSortField) || MACHINE_ATTACHMENT_LIST_DEFAULT_SORT_FIELD;
  const sortOrder = useSelector(selectAttachmentsSortOrder) || SortOrders.Desc;

  const hasAttachments = attachments.length > 0;
  const pageSize = useMemo(() => {
    if (size === 'full-width') {
      return MACHINE_ATTACHMENT_LIST_PAGE_SIZE_FULL_WIDTH;
    }

    if (size === 'large') {
      return MACHINE_ATTACHMENT_LIST_PAGE_SIZE_LARGE;
    }

    if (size === 'medium') {
      return MACHINE_ATTACHMENT_LIST_PAGE_SIZE_MEDIUM;
    }

    return MACHINE_ATTACHMENT_LIST_PAGE_SIZE_SMALL;
  }, [size]);

  const handleSortOrderChange = (): void => {
    dispatch(
      MachineDetailsActions.setMachineAttachmentsActiveSortOrder(
        sortOrder === SortOrders.Asc ? SortOrders.Desc : SortOrders.Asc
      )
    );
  };

  const onPageChange = (pageValue: number): void => {
    dispatch(MachineDetailsActions.changeMachineAttachmentsListTablePage({ page: pageValue }));
  };

  const showAddMachineAttachmentModal = (): void => {
    dispatch(MachineModalsActions.showAddMachineAttachmentModal());
  };

  useEffect(() => {
    dispatch(
      MachineDetailsActions.getMachineAttachmentsRequest({
        machineId: machine.id,
        paginationOptions: {
          limit: pageSize,
          paginationToken: paginationTokens[page] || '',
        },
        sortOptions: {
          field: sortField,
          order: sortOrder,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, machine.id, page, pageSize, sortField, sortOrder]);

  const pagination = {
    total: totalCount,
    pageSize,
    page,
    onPageChange,
  };

  return (
    <StyledMachineAttachmentList
      className={classnames('machine-attachment-list', `machine-attachment-list--size-${size?.toLowerCase()}`)}
    >
      <div className="machine-attachment-list__header">
        <h3 tabIndex={0} className="machine-attachment-list__title">
          {t('machineAttachmentList.subHeader')}
        </h3>
        <div className="machine-attachment-list__buttons">
          <LegacyTextButton
            className="machine-attachment-list__create-button machine-attachment-list__button"
            onClick={showAddMachineAttachmentModal}
            icon={<SvgIcon name="plus" />}
          />
        </div>
      </div>
      <div
        className={classnames('machine-attachment-list__body', {
          'machine-attachment-list__body--empty': !hasAttachments,
        })}
      >
        <div className="machine-attachment-list__button-bar">
          <LegacyTextButton
            className={classnames('machine-attachment-list__sort-by-date-button', 'machine-attachment-list__button')}
            onClick={handleSortOrderChange}
          >
            {t('machineAttachmentList.date')}
            <div className="machine-attachment-list__sort-direction-indicator">
              <SortIcon sortOrder={sortOrder} />
            </div>
          </LegacyTextButton>
        </div>
        <MachineAttachmentListContent
          machine={machine}
          attachments={attachments}
          size={size}
          sortOrder={sortOrder}
          onSortOrderChange={handleSortOrderChange}
          isLoading={isLoading}
        />
      </div>
      <div className="machine-attachment-list__footer">
        <Pagination className="machine-attachment-list__pagination" {...pagination} showPageSizeDropdown={false} />
      </div>
      <AddMachineAttachmentModal machine={machine} pageSize={pageSize} />
      <DeleteMachineAttachmentModal pageSize={pageSize} />
    </StyledMachineAttachmentList>
  );
};
