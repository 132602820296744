import { get, isNil, isNumber } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import GoogleMapReact, { Coords } from 'google-map-react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ColorUtils } from '../../../../../lib/utils/colors/ColorUtils';
import { Machine } from '../../interfaces/Machine.types';
import { MachineMapPin, MachineMarkerType } from '../MachineMapPin/MachineMapPin';
import { StyledMachineDetailsMap } from './MachineDetailsMap.styles';
import { MachineDetailsMapLegendPopover } from './MachineDetailsMapLegend/MachineDetailsMapLegendPopover/MachineDetailsMapLegendPopover';
import { MachineDetailsMapGlobalStyled } from './MachineDetailsMap.global.styles';
import { Site } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { MapUtils } from 'lib/utils/map/MapUtils';
import { GEOFENCE_MINIMUM_RADIUS, Map } from 'app/components/Map/Map';
import { Optional } from 'lib/types/Optional';
import { Tooltip } from 'lib/components/Tooltip/Tooltip';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';

const CONSTANTS = {
  enableZoomPadding: true,
  initSize: { width: 1, height: 1 },
};

export interface MachineDetailsMapProps {
  machine: Optional<Machine>;
}

export interface OptionalCoords {
  lat: Optional<number>;
  lng: Optional<number>;
}

const hasLocationData = (machineCoords: OptionalCoords): machineCoords is Coords =>
  isNumber(get(machineCoords, 'lat')) && isNumber(get(machineCoords, 'lng'));

export const MachineDetailsMap = ({ machine }: MachineDetailsMapProps): JSX.Element => {
  const { t } = useTranslation();
  const mapInstanceRef = useRef<any>(null);
  const mapApisRef = useRef<any>(null);
  const mapRef = useRef<HTMLDivElement>(null);
  const geofenceCircleRef = useRef<any>(null);
  const accuracyCircleRef = useRef<any>(null);
  const [isViewportInitialized, setIsViewportInitialized] = useState(false);
  const [googleApisInitialized, setGoogleApisInitialized] = useState(false);
  const [size, setSize] = useState(CONSTANTS.initSize);
  const [center, setCenter] = useState<GoogleMapReact.Coords | undefined>(undefined);
  const [zoom, setZoom] = useState<number | undefined>(undefined);
  const theme = useTheme();
  const site: Optional<Site> = machine?.site;

  const isNonIoT = !machine?.isIoTDevice;
  const isNonIoTNoSite = isNonIoT && !site;
  const isNonIoTWithSite = isNonIoT && !!site;

  useEffect(
    () =>
      setSize({
        width: mapRef.current?.clientWidth ?? 1,
        height: mapRef.current?.clientHeight ?? 1,
      }),
    []
  );

  const machineCoords: OptionalCoords = useMemo((): OptionalCoords => {
    if (isNonIoTWithSite) {
      return {
        lat: site?.location?.latitude,
        lng: site?.location?.longitude,
      };
    }

    return {
      lat: machine?.location?.latitude,
      lng: machine?.location?.longitude,
    };
  }, [
    isNonIoTWithSite,
    machine?.location?.latitude,
    machine?.location?.longitude,
    site?.location?.latitude,
    site?.location?.longitude,
  ]);

  const points: Coords[] = useMemo(() => {
    const pointsArray = [];

    if (hasLocationData(machineCoords)) {
      pointsArray.push(machineCoords);
    }

    return pointsArray;
  }, [machineCoords]);

  const boundingBox = useMemo(
    () => MapUtils.calculateBoundingBox({ googleApisInitialized, mapApisRef, points }),
    [googleApisInitialized, points]
  );

  const initialCenter = useMemo(
    () => MapUtils.calculateCenter({ points, boundingBox, size }),
    [boundingBox, points, size]
  );

  const initialZoom = useMemo(
    () => MapUtils.calculateZoom({ points, boundingBox, size, enableZoomPadding: CONSTANTS.enableZoomPadding }),
    [points, boundingBox, size]
  );

  const onZoomAnimationEnd = (args: number): void => {
    if (isNonIoTNoSite) return;
    setZoom(args);
  };

  const handleGoogleApisInitialized = ({ mapInstance, mapApis }: { mapInstance: any; mapApis: any }): void => {
    if (isNonIoTNoSite) return;
    mapInstanceRef.current = mapInstance;
    mapApisRef.current = mapApis;
    setGoogleApisInitialized(true);
  };

  const onChange = useCallback(
    ({ center: newCenter, zoom: newZoom }: { center: Coords | undefined; zoom: number | undefined }): void => {
      if (isNonIoTNoSite) return;
      if (isViewportInitialized) {
        setCenter(newCenter);
        setZoom(newZoom);
        return;
      }

      if (initialCenter !== undefined && initialZoom !== undefined) {
        setCenter(initialCenter);
        setZoom(initialZoom);
        setIsViewportInitialized(true);
      }
    },
    [initialCenter, initialZoom, isNonIoTNoSite, isViewportInitialized]
  );

  useEffect(() => {
    // Super hacky way to trigger setting the initial viewport (zoom and center). Don't do this at home (☉_ ☉)
    // Since there was no suitable callback we are triggering the initial onChange after a small timeout.
    if (!isViewportInitialized && !isNonIoTNoSite) {
      setTimeout(() => {
        onChange({ center: undefined, zoom: undefined });
      }, 100);
    }
  }, [isNonIoTNoSite, isViewportInitialized, onChange]);

  useEffect(() => {
    if (isNonIoTNoSite) return;
    if (!googleApisInitialized) return;
    let geofenceCircleData;

    const hasSiteGeofence =
      !isNil(site?.geofence?.centerPoint?.latitude) &&
      !isNil(site?.geofence?.centerPoint?.longitude) &&
      isNumber(site?.geofence?.radius);

    if (hasSiteGeofence) {
      geofenceCircleData = {
        center: {
          lat: site?.geofence?.centerPoint?.latitude ?? 0,
          lng: site?.geofence?.centerPoint?.longitude ?? 0,
        },
        radius: site?.geofence?.radius ?? 0,
      };
    }

    if (!isNil(geofenceCircleData)) {
      const geofenceCircle: google.maps.Circle = new mapApisRef.current.Circle({
        strokeColor: ColorUtils.getCssVariableValue(theme.colors.primary),
        fillColor: ColorUtils.getCssVariableValue(theme.colors.primary),
        strokeOpacity: 1,
        fillOpacity: 0.3,
        strokeWeight: 2,
        editable: false,
        draggable: false,
        center: geofenceCircleData.center,
        radius: geofenceCircleData.radius,
        suppressUndo: true,
      });

      mapApisRef.current?.event.addListener(
        geofenceCircle,
        'radius_changed',
        function handleCircleRadiusChanged(this: google.maps.Circle): void {
          if (this.getRadius() < GEOFENCE_MINIMUM_RADIUS) {
            this.setRadius(GEOFENCE_MINIMUM_RADIUS);
          }
        }
      );

      // Remove old circle from map
      geofenceCircleRef.current?.setMap(null);
      geofenceCircleRef.current = geofenceCircle;

      // Add new circle to map
      geofenceCircle.setMap(mapInstanceRef.current);
    }
  }, [
    googleApisInitialized,
    theme.colors.primary,
    site?.geofence?.centerPoint?.latitude,
    site?.geofence?.centerPoint?.longitude,
    site?.geofence?.radius,
    isNonIoTNoSite,
  ]);

  useEffect(() => {
    if (isNonIoTNoSite) return;
    if (!googleApisInitialized) return;
    let accuracyCircleData;

    if (hasLocationData(machineCoords) && machine?.location?.accuracy) {
      accuracyCircleData = {
        center: {
          lat: machineCoords.lat ?? 0,
          lng: machineCoords.lng ?? 0,
        },
        radius: machine?.location?.accuracy ?? 0,
      };
    }

    if (!isNil(accuracyCircleData)) {
      const accuracyCircle: google.maps.Circle = new mapApisRef.current.Circle({
        strokeColor: ColorUtils.getCssVariableValue(theme.colors.black),
        fillColor: ColorUtils.getCssVariableValue(theme.colors.black),
        strokeOpacity: 0.7,
        fillOpacity: 0.2,
        strokeWeight: 2,
        editable: false,
        draggable: false,
        center: accuracyCircleData.center,
        radius: accuracyCircleData.radius,
        suppressUndo: true,
      });

      mapApisRef.current?.event.addListener(
        accuracyCircle,
        'radius_changed',
        function handleCircleRadiusChanged(this: google.maps.Circle): void {
          if (this.getRadius() < GEOFENCE_MINIMUM_RADIUS) {
            this.setRadius(GEOFENCE_MINIMUM_RADIUS);
          }
        }
      );

      // Remove old circle from map
      accuracyCircleRef.current?.setMap(null);
      accuracyCircleRef.current = accuracyCircle;

      // Add new circle to map
      accuracyCircle.setMap(mapInstanceRef.current);
    }
  }, [
    googleApisInitialized,
    theme.colors.black,
    machineCoords,
    machineCoords.lat,
    machineCoords.lng,
    machine?.location?.accuracy,
    isNonIoTNoSite,
  ]);

  return (
    <StyledMachineDetailsMap className="machine-details-map">
      <MachineDetailsMapGlobalStyled />
      <Tooltip overlayClassName="tooltip-overlay" placement="bottomRight" title={<MachineDetailsMapLegendPopover />}>
        <div className="machine-details-map-legend__info-icon-container">
          <SvgIcon className="machine-details-map-legend__info-icon" name="info" />
        </div>
      </Tooltip>
      <Map
        className="machine-details-map__map"
        ref={mapRef}
        onChange={onChange}
        onGoogleApisInitialized={handleGoogleApisInitialized}
        onZoomAnimationEnd={onZoomAnimationEnd}
        zoom={zoom}
        center={center}
      >
        {points.length > 0 && machine && machineCoords.lat && machineCoords.lng ? (
          <MachineMapPin
            key={`machine-details-map-pin-${machine.id}`}
            lat={machineCoords.lat}
            lng={machineCoords.lng}
            machines={[machine]}
            visible={false}
            backgroundColor={theme.colors.celadonGreen}
            textColor={theme.colors.white}
            type={MachineMarkerType.SINGLE}
            popupContainerRef={mapRef.current ?? undefined}
          />
        ) : (
          <></>
        )}
      </Map>
      {isNonIoTNoSite && (
        <div className="machine-details-map__map-overlay">
          <p className="machine-details-map__map-overlay-text">{t('machineDetails.generalInfo.noMachineLocation')}</p>
        </div>
      )}
    </StyledMachineDetailsMap>
  );
};
