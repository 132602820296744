import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAnalyticsSetPageInfo } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetPageInfo';
import { useAnalyticsSetProductInfo } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetProductInfo';
import { FeatureFlagSelectors } from '../../../../../cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { MachineAttachmentList } from '../../../machine-details/components/MachineAttachmentList/MachineAttachmentList';
import { selectData } from '../../state/machineDetailsPanelSelectors';
import { StyledMachineDetailsPanelGcd } from './MachineDetailsPanelGcd.styles';
import { MachineDetailsOverviewGcd } from './MachineDetailsOverviewGcd/MachineDetailsOverviewGcd';
import { PageNamePrefix } from 'config/pages';
import { Tabs } from 'lib/components/Tabs/Tabs';

export enum MachineDetailsTab {
  OVERVIEW = 'OVERVIEW',
  DOCUMENTS = 'DOCUMENTS',
}

export interface MachineDetailsPanelGcdProps {
  id: string;
}

export const MachineDetailsPanelGcd = ({ id }: MachineDetailsPanelGcdProps): React.JSX.Element => {
  const [activeTab, setActiveTab] = useState(MachineDetailsTab.OVERVIEW);
  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const { t } = useTranslation();
  const machine = useSelector(selectData);

  const showMachineAttachments = features.MACHINE_ATTACHMENTS;

  useAnalyticsSetProductInfo({
    productName: machine?.name,
    productMaterialNo: machine?.materialNumber,
    productSerialNo: machine?.serialNumber,
  });
  useAnalyticsSetPageInfo({
    // eslint-disable-next-line max-len
    pageName: `${PageNamePrefix.MACHINE_DETAILS}${machine?.name} ${machine?.classification} (${machine?.materialNumber} / ${machine?.serialNumber})`,
  });

  const tabs = [
    {
      label: t('machineDetails.generalInfo.overview'),
      key: MachineDetailsTab.OVERVIEW,
      children: MachineDetailsOverviewGcd(machine, id),
    },
    {
      label: t('machineDetails.generalInfo.documents'),
      key: MachineDetailsTab.DOCUMENTS,
      children: showMachineAttachments && machine && <MachineAttachmentList machine={machine} size="full-width" />,
    },
  ];

  const handleTabChange = (activeTabKey: string): void => {
    setActiveTab(activeTabKey as MachineDetailsTab);
  };

  return (
    <StyledMachineDetailsPanelGcd>
      <Tabs destroyInactiveTabPane={true} items={tabs} onChange={handleTabChange} activeKey={activeTab} />
    </StyledMachineDetailsPanelGcd>
  );
};
