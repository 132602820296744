import classnames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EllipsisOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import { LegacyTextButton } from '../../../../../../lib/components/LegacyButton/LegacyTextButton/LegacyTextButton';
import { SvgIcon } from '../../../../../../lib/components/SvgIcon/SvgIcon';
import { MachineModalsActions } from '../../../modals/state/machineModalsSlice';
import { MachineDetailsActions } from '../../state/machineDetailsActions';
import { selectAttachmentsDownloadsIsLoading } from '../../state/machineDetailsSelectors';
import { StyledMachineAttachment } from './MachineAttachment.styles';
import { IMachineAttachment } from 'app/modules/machine-inventory/interfaces/MachineAttachment.types';
import { MimeTypes } from 'lib/utils/mime-type/MimeTypes';
import { Icon } from 'lib/components/Icon/Icon';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { Dropdown } from 'lib/components/Dropdown/Dropdown';

export type MachineAttachmentSize = 'large' | 'medium' | 'small' | 'full-width';

export interface MachineAttachmentProps {
  machine: Machine;
  attachment: IMachineAttachment;
  size: MachineAttachmentSize;
  showPreviewIcon?: boolean;
  showExtensionInTitle?: boolean;
}

export const MachineAttachment = ({
  machine,
  attachment,
  size,
  showPreviewIcon = false,
  showExtensionInTitle = false,
}: MachineAttachmentProps): JSX.Element => {
  // For now, we will just use the buttons on the attachment card itself.
  // We might use dropdown menu as an overflow in future if more buttons need to be added
  const ENABLE_DROPDOWN_MENU = false;

  const dispatch = useDispatch();
  const isAttachmentDownloadsLoading = useSelector(selectAttachmentsDownloadsIsLoading) || false;
  const showDeleteMachineAttachmentModal = (): void => {
    dispatch(
      MachineModalsActions.showDeleteMachineAttachmentModal({
        machine,
        attachment,
      })
    );
  };

  const handleDownload = (): void => {
    dispatch(
      MachineDetailsActions.downloadMachineAttachmentRequest({
        machineId: machine.id,
        attachmentId: attachment.id,
      })
    );
  };

  const items: MenuProps['items'] = [
    {
      label: <LegacyTextButton icon={<SvgIcon name="download" />} disabled={isAttachmentDownloadsLoading} />,
      key: '1',
      onClick: handleDownload,
    },
    {
      label: <LegacyTextButton icon={<SvgIcon name="delete" />} className="machine-attachment-menu__delete-button" />,
      key: '2',
      onClick: showDeleteMachineAttachmentModal,
    },
  ];

  const menuProps = {
    items,
  };

  return (
    <StyledMachineAttachment
      className={classnames('machine-attachment', `machine-attachment--size-${size.toLowerCase()}`)}
    >
      <div className="machine-attachment__card">
        <div className="machine-attachment__card-content">
          <div className="machine-attachment__card-extension">{MimeTypes.getFileExtension(attachment.mimeType)}</div>
          <div className="machine-attachment__button-bar">
            {showPreviewIcon && (
              // TODO: Implement preview functionality once react-media-previewer has been evaluated,
              // if suitable
              <LegacyTextButton
                className="machine-attachment__preview-button machine-attachment__button"
                icon={<Icon name="icon-a_037_visible_line" />}
              />
            )}
            <LegacyTextButton
              className="machine-attachment__download-button machine-attachment__button"
              onClick={handleDownload}
              disabled={isAttachmentDownloadsLoading}
              icon={<SvgIcon name="download" />}
            />
            <LegacyTextButton
              className="machine-attachment__delete-button machine-attachment__button"
              onClick={showDeleteMachineAttachmentModal}
              icon={<SvgIcon name="delete" />}
            />
          </div>
          {ENABLE_DROPDOWN_MENU && (
            <Dropdown menu={menuProps} placement="bottom" trigger={['click']} className="machine-attachment__card-menu">
              <EllipsisOutlined className="machine-attachment__card-menu-icon" />
            </Dropdown>
          )}
        </div>
      </div>
      <div className="machine-attachment__info">
        {showExtensionInTitle && (
          <div className="machine-attachment__icon">
            <div className="machine-attachment__icon-extension">{MimeTypes.getFileExtension(attachment.mimeType)}</div>
          </div>
        )}
        <div className="machine-attachment__title" title={attachment.fileName}>
          {attachment.fileName}
        </div>
      </div>
    </StyledMachineAttachment>
  );
};
