import React, { useRef, useEffect, useState, useMemo } from 'react';
import Chart, { ChartTypeRegistry, TooltipItem } from 'chart.js/auto';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import * as machineDetailsPanelRobotSelectors from '../../../../state/machineDetailsPanelRobot/machineDetailsPanelRobotSelectors';
import { CHART_STYLE, ChartColor, DateFormats } from 'app/modules/machine-inventory/interfaces/Robot.types';
import { getMonthsByPeriod } from 'lib/utils/date-handling/getTimeByPeriod';
import { ChevronBar } from 'lib/components/ChevronBar/ChevronBar';

interface MachineDetailsRobotsBarChartProps {
  period: string[];
  cleaningValues: number[];
  unit: string;
  finishDates: string[];
}

const MachineDetailsRobotsBarChart = ({
  period,
  cleaningValues,
  unit,
  finishDates,
}: MachineDetailsRobotsBarChartProps): JSX.Element => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const [chartInstance, setChartInstance] = useState<Chart | null>(null);

  const startDate = useSelector(machineDetailsPanelRobotSelectors.selectPeriodStartDate);
  const endDate = useSelector(machineDetailsPanelRobotSelectors.selectPeriodEndDate);

  const titleArray = useMemo(() => getMonthsByPeriod(startDate, endDate), [startDate, endDate]);
  const [titleIndex, setTitleIndex] = useState<number>(0);

  const changeTitleIndex = (i: number): void => {
    setTitleIndex(i);
  };

  const currentPeriod = useMemo(() => {
    // if total days in period less than 31, show all days
    if (period.length <= 31) {
      return period;
    }
    // if total days in period more than 31, show only days in current month
    return period.filter(item => {
      const [_, month] = item.split('.');
      return Number(month) === dayjs(titleArray[titleIndex]).month() + 1;
    });
  }, [period, titleArray, titleIndex]);

  const currentValue = useMemo(() => {
    const result: number[] = [];
    period.map((item, index) => {
      const [_, month] = item.split('.');
      if (Number(month) === dayjs(titleArray[titleIndex]).month() + 1) {
        result.push(cleaningValues[index]);
      }
      return item;
    });
    return result;
  }, [cleaningValues, period, titleArray, titleIndex]);

  const currentFinishedDates = useMemo(
    () => finishDates.filter(item => dayjs(item).month() === dayjs(titleArray[titleIndex]).month()),
    [finishDates, titleArray, titleIndex]
  );

  const titleCallback = (context: TooltipItem<keyof ChartTypeRegistry>[]): string => `${context[0].raw} ${unit}`;
  const labelCallback = (context: TooltipItem<keyof ChartTypeRegistry>): string =>
    dayjs(currentFinishedDates[context.dataIndex]).format(DateFormats.DATE_IN_MONTH_DAY_YEAR);

  useEffect(() => {
    if (chartInstance) {
      chartInstance.destroy();
    }
    const myChartRef = chartRef.current?.getContext('2d');

    if (myChartRef) {
      const chart = new Chart(myChartRef, {
        type: 'bar',
        data: {
          labels: currentPeriod,
          datasets: [
            {
              data: currentValue,
              type: 'bar',
              backgroundColor: ChartColor.CELADON_GREEN,
              order: 1,
              barThickness: 24,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              backgroundColor: ChartColor.WHITE,
              titleColor: ChartColor.BLACK,
              bodyColor: ChartColor.BLACK,
              cornerRadius: 0,
              displayColors: false,
              padding: 8,
              titleFont: CHART_STYLE.TITLE_FONT,
              bodyFont: CHART_STYLE.BODY_FONT,
              callbacks: {
                title: titleCallback,
                label: labelCallback,
              },
              borderColor: ChartColor.BLACK,
              borderWidth: 1,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: ChartColor.BLACK,
                font: CHART_STYLE.X_TICKS_FONT,
              },
            },
            y: {
              ticks: {
                count: 5,
                stepSize: 5,
                color: ChartColor.GREY,
                font: CHART_STYLE.Y_TICKS_FONT,
              },
              border: {
                display: false,
              },
            },
          },
        },
      });
      setChartInstance(chart);
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  return (
    <div className="chart-wrapper">
      <canvas className="bar-chart" ref={chartRef} />
      {period.length > 31 && (
        <ChevronBar
          startDate={startDate}
          endDate={endDate}
          titleArray={titleArray}
          titleIndex={titleIndex}
          changeTitleIndex={changeTitleIndex}
        />
      )}
    </div>
  );
};

export default MachineDetailsRobotsBarChart;
