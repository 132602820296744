import { createGlobalStyle } from 'styled-components';

export const LegacyMachineStatusIndicatorGlobalStyles = createGlobalStyle`
  .legacy-machine-connection-status-tooltip__overlay {
    .ant-tooltip-content {
      width: 100%;
    }

    .ant-tooltip-inner {
      padding: 16px;
    }
  }
`;
