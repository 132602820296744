import isNil from 'lodash-es/isNil';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import { useAnalyticsLinkActivated } from '../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { useAnalyticsSetFilter } from '../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetFilter';
import {
  AnalyticsLink,
  AnalyticsMachineDetailsFilter,
  IAnalyticsFilter,
} from '../../../../cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { OperatingHoursChartData, OperatingHoursChartUtils } from '../../../cleaning/utils/OperatingHoursChartUtils';
import { Machine } from '../../interfaces/Machine.types';
import { MachineOperatingHoursChart } from '../../machine-details/components/MachineOperatingHoursChart/MachineOperatingHoursChart';
import { MachineDetailsActions } from '../../machine-details/state/machineDetailsActions';
import { selectOperatingTime, selectOperatingTimeLoading } from '../../machine-details/state/machineDetailsSelectors';
import { StyledMachineCleaningData } from './MachineCleaningData.styles';
import { MachineCleaningDataHeader } from './MachineCleaningDataHeader/MachineCleaningDataHeader';
import { LoadingPage } from 'lib/components/LoadingPage/LoadingPage';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { InputPeriod } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface MachineCleaningDataProps {
  id: string | undefined;
  machine: Machine;
  period?: InputPeriod;
}

export const MachineCleaningData = ({ id, machine, period }: MachineCleaningDataProps): JSX.Element | null => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [initStartDate, initEndDate] = OperatingHoursChartUtils.getStartDateEndDateByPeriod(period);
  const [startAt, setStartAt] = useState<Date>(initStartDate);
  const [endAt, setEndAt] = useState<Date>(initEndDate);
  const data = useSelector(selectOperatingTime);
  const isLoading = useSelector(selectOperatingTimeLoading);

  // Operating hours
  const periodOperatingTime = data?.actualTotalOperatingTimeMs || 0;

  const totalOperatingTime = (machine?.totalOperatingTime || 0) * 1000;
  const operatingHoursData: OperatingHoursChartData[] =
    OperatingHoursChartUtils.convertOperatingTimeIntervalsToChartData(data?.operatingTimeIntervals ?? []);

  const getActiveFiltersCallback = useCallback((): IAnalyticsFilter[] => {
    const activeFilters: IAnalyticsFilter[] = [];

    if (!isNil(startAt) && !isNil(endAt)) {
      activeFilters.push(AnalyticsMachineDetailsFilter.DATE_RANGE);
    }

    return activeFilters;
  }, [endAt, startAt]);

  useAnalyticsSetFilter({
    getActiveFiltersCallback,
  });

  useEffect(() => {
    if (id && startAt && endAt) {
      const periodToString = {
        startAt: dayjs(startAt).toISOString(),
        endAt: dayjs(endAt).toISOString(),
      };
      dispatch(
        MachineDetailsActions.getMachineOperatingTimeRequest({
          id,
          period: periodToString,
          timezone: DateTime.getBrowserTimeZone(),
        })
      );
    }
  }, [dispatch, id, startAt, endAt]);

  const onDatePickerChange = (dates: [Dayjs | null, Dayjs | null] | null): void => {
    if (dates && dates[0] && dates[1]) {
      setStartAt(OperatingHoursChartUtils.prepareStartDate(dates[0].toDate()));
      setEndAt(OperatingHoursChartUtils.prepareEndDate(dates[1].toDate()));
    }

    analyticsLinkActivated({
      linkName: AnalyticsLink.MACHINE_DETAILS_OPERATING_HOURS_FILTER,
    });
  };

  return (
    <StyledMachineCleaningData className="machine-cleaning-data">
      <div className="machine-cleaning-data__content">
        <h3 tabIndex={0} className="machine-cleaning-data__title">
          {t('machineDetails.operatingHoursChart.title')}
        </h3>
        <div className="machine-cleaning-data__header">
          <div className="machine-cleaning-data__filter">
            <MachineCleaningDataHeader onRangePickerChange={onDatePickerChange} startAt={startAt} endAt={endAt} />
          </div>
          <div className="machine-operating-hours-chart__sums">
            <div>
              <p className="machine-operating-hours-chart__operating-label">
                {t('machineDetails.operatingHoursChart.periodOperatingHours')}
              </p>
              <p
                className="machine-operating-hours-chart__operating-value"
                title={[
                  DateTime.formatDurationByMilliseconds({ ms: periodOperatingTime || 0, showSeconds: true }),
                  t('machineDetails.operatingHoursChart.hUnit'),
                ].join('')}
              >
                {[
                  DateTime.formatDurationByMilliseconds({ ms: periodOperatingTime || 0, showSeconds: false }),
                  t('machineDetails.operatingHoursChart.hUnit'),
                ].join('')}
              </p>
            </div>
            <div className="machine-operating-hours-chart__total-operating-label">
              <p className="machine-operating-hours-chart__operating-label">
                {t('machineDetails.operatingHoursChart.totalOperatingHours')}
              </p>
              <p
                className="machine-operating-hours-chart__operating-value"
                title={[
                  DateTime.formatDurationByMilliseconds({ ms: totalOperatingTime || 0, showSeconds: true }),
                  t('machineDetails.operatingHoursChart.hUnit'),
                ].join('')}
              >
                {[
                  DateTime.formatDurationByMilliseconds({ ms: totalOperatingTime || 0, showSeconds: false }),
                  t('machineDetails.operatingHoursChart.hUnit'),
                ].join('')}
              </p>
            </div>
          </div>
        </div>
        {isLoading ? (
          <LoadingPage className="machine-cleaning-data__loading-indicator" />
        ) : (
          <div className="machine-cleaning-data__body">
            <MachineOperatingHoursChart data={operatingHoursData} />
          </div>
        )}
      </div>
    </StyledMachineCleaningData>
  );
};
