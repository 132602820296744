import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { ReactElement } from 'react';
import { StyledMachineRobotPowerInfo } from './MachineRobotPowerInfo.styles';
import { ThemeConstants } from 'config/theme';
import { Odometer } from 'lib/components/Odometer/Odometer';

interface MachineRobotStatusPowerInfoProps {
  icon: ReactElement;
  value: string;
  label: string;
  unit: string;
}

export const MachineRobotStatusPowerInfo = ({
  value,
  icon,
  label,
  unit,
}: MachineRobotStatusPowerInfoProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledMachineRobotPowerInfo
      className="machine-robot-status-power-info"
      align="top"
      gutter={[ThemeConstants.BASE_GAP, ThemeConstants.BASE_GAP]}
    >
      <Col>
        <div className="machine-robot-status-power-info__icon-wrapper">{icon}</div>
      </Col>
      <Col>
        <Row align="middle">
          <Odometer value={value} />
          <div className="machine-robot-status-power-info__unit" dangerouslySetInnerHTML={{ __html: unit }} />
        </Row>
        <p className="machine-robot-status-power-info__label">{t(label)}</p>
      </Col>
    </StyledMachineRobotPowerInfo>
  );
};
