import styled from 'styled-components';

export const StyledMachineBatteryDischargeStatusIndicator = styled.div`
  .machine-battery-status {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .machine-battery-status__pill {
    display: flex;
    align-items: center;
    width: max-content;

    padding: 12px 12px;
  }

  .machine-battery-status__pill--short-text {
    width: 106px;
    height: 30px;
    justify-content: center;
  }

  .machine-battery-status__tooltip-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 18px;
      }
    }
  }

  .machine-battery-status__icon {
    color: ${(props): string => props.theme.colors.black};
    font-size: 16px;

    &::before {
      margin-left: 0;
    }
  }

  .machine-battery-status__text {
    color: ${(props): string => props.theme.colors.black};
    margin-left: 4px;
    margin-bottom: -2px;
  }

  &.machine-battery-status--size-small {
    .machine-battery-status__icon {
      min-width: unset;
      svg {
        height: 16px;
        width: 20px;
      }
    }

    .notAvailable {
      svg {
        height: 16px;
        width: 32px;
      }
    }
  }

  .machine-battery-status__description {
    margin-top: 8px;
  }
`;
