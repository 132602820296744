import { createGlobalStyle } from 'styled-components';

export const MachineStatusIndicatorGlobalStyles = createGlobalStyle`
  .machine-connection-status-tooltip__overlay {
    &.ant-tooltip {
      .ant-tooltip-content {
        width: 100%;

        .ant-tooltip-inner {
          color: ${(props): string => props.theme.colors.white};
          background-color: ${(props): string => props.theme.colors.black};;
          padding: 16px;
        }
      }
    }
  }
`;
