import styled from 'styled-components';

export const StyledMachineReminderList = styled.div`
  .machine-reminder-list__create-button {
    width: 18px;
    height: 18px;
    color: ${(props): string => props.theme.colors.black};
    cursor: pointer;
  }

  .machine-reminder-list__empty-icon {
    width: 40px;
    height: 50px;
  }

  .machine-reminder-list__empty-text {
    ${(props): string => props.theme.fontStyles.p1.medium};
  }

  .machine-reminder-list__reminder-delete-button {
    width: 15px;
    height: 17px;
    padding: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .machine-reminder-list__reminder-details--due-date-soon {
    color: ${(props): string => props.theme.colors.secondary};
  }
`;
