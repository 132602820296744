import { createGlobalStyle } from 'styled-components';

export const MachineOperatingHoursChartGlobalStyles = createGlobalStyle`
  .machine-operating-hours-chart__tooltip {
    width: 264px;
    background-color: ${(props): string => props.theme.colors.white};
    padding: 24px 20px;
    box-shadow: 0px 0px 16px ${(props): string => props.theme.colors.darkGrey};
  }

  .machine-operating-hours-chart__tooltip-title {
    color: ${(props): string => props.theme.colors.darkGrey};
    font-family: ${(props): string => props.theme.fonts.captionNews};
    font-size: ${(props): string => props.theme.fontSizes.p0};
    font-weight: ${(props): number => props.theme.fontWeights.p0};
    line-height: ${(props): string => props.theme.lineHeights.p0};
    opacity: 0.5;
  }

  .machine-operating-hours-chart__tooltip-item.border {
    border-top: 1px solid ${(props): string => props.theme.colors.grey};
    padding-top: 5px;
  }

  .machine-operating-hours-chart__tooltip-item {
    display: flex;
    align-items: baseline;
  }

  .machine-operating-hours-chart__tooltip-item-square-icon {
    height: 8px;
    width: 8px;
    margin: 0 10px 0 0;
    border-radius: 1px;
  }

  .machine-operating-hours-chart__tooltip-item-text-block {
    margin: 0 0 5px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;

    p.machine-operating-hours-chart__tooltip-item-seriesLabel {
      margin: 0 0 0 0;
    }
    
    .machine-operating-hours-chart__tooltip-item-hours-percent{
      text-align: left;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      width: 110px;

      p.machine-operating-hours-chart__tooltip-item-hours {
        margin: 0 0 0 0;
        font-family: ${(props): string => props.theme.fonts.captionMedium};
        font-size: ${(props): string => props.theme.fontSizes.p0};
        font-weight: ${(props): number => props.theme.fontWeights.captionMedium};
        line-height: ${(props): string => props.theme.lineHeights.p0};
      }
    
      p.machine-operating-hours-chart__tooltip-item-percent {
        margin: 0 0 0 0;
        color: ${(props): string => props.theme.colors.darkGrey};
        font-family: ${(props): string => props.theme.fonts.captionMedium};
        font-size: ${(props): string => props.theme.fontSizes.p0};
        font-weight: ${(props): number => props.theme.fontWeights.captionMedium};
        line-height: ${(props): string => props.theme.lineHeights.p0};
        opacity: 0.5;
      }
    }
  }
`;
