import styled from 'styled-components';

export const StyledMachineDetailsNonIotBanner = styled.div`
  .machine-details-non-iot-banner {
    background: ${(props): string => props.theme.colors.focusBorder};
    padding: 12px;
  }

  .machine-details-non-iot-banner__content {
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin: 0 auto;
    align-items: center;
    color: ${(props): string => props.theme.colors.white};
  }

  .machine-details-non-iot-banner__icon {
    width: 26px;
    height: 18px;
    margin: 0 16px 0 0;
  }

  .machine-details-non-iot-banner__text {
    ${(props): string => props.theme.fontStyles.caption.news}
    margin-bottom: 0;
    width: 456px;
  }
`;
