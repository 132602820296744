import styled from 'styled-components';

export const StyledMachineNameBanner = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props): string => props.theme.spacing.lg};

  .machine-name-banner__image {
    width: 140px;
    height: 140px;
  }

  .machine-name-banner__info {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    align-items: flex-start;
    gap: ${(props): string => props.theme.spacing.xs};
  }

  .machine-name-banner__name {
    ${(props): string => props.theme.fontStyles.redesign.heading2};
    word-break: break-word;
  }

  .machine-name-banner__type {
    ${(props): string => props.theme.fontStyles.redesign.base.normal};
  }
`;
