import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Image, Space, Spin } from 'antd';
import {
  DownloadOutlined,
  SwapOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomOutOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';
import * as machineDetailsPanelRobotSelectors from '../../../../../state/machineDetailsPanelRobot/machineDetailsPanelRobotSelectors';
import { StyledMachineDetailsRobotRouteImage } from './MachineDetailsRobotRouteImage.styles';
import { MachineDetailsRobotRouteImageGlobalStyles } from './MachineDetailsRobotRouteImage.global.styles';
import { MachineDetailsPanelRobotActions } from 'app/modules/machine-inventory/machine-details-panel/state/machineDetailsPanelRobot/machineDetailsPanelRobotSlice';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { DATE_FORMAT_PATTERN } from 'lib/utils/date-handling/DateTime.types';
import { getImageDownloaded } from 'app/modules/machine-inventory/utils/getImageDownloaded';

export const MachineDetailsRobotRouteImage = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [isPreview, setIsPreview] = useState(false);
  const [isShowFallbackImage, setIsShowFallbackImage] = useState(false);

  const cleaningTaskReportLatestData = useSelector(machineDetailsPanelRobotSelectors.selectCTRListRobotLatestData);
  const cleaningTaskReportsLoading = useSelector(machineDetailsPanelRobotSelectors.selectCTRListIsInitialLoad);
  const imageAsDataUrl = useSelector(machineDetailsPanelRobotSelectors.selectRobotRouteImageAsDataUrl);
  const robotRouteImageLoading = useSelector(machineDetailsPanelRobotSelectors.selectRobotRouteImageIsLoading);
  const cleaningRouteDate = DateTime.formatDateByLocale(
    i18n.language,
    cleaningTaskReportLatestData?.finishedAt || '',
    DATE_FORMAT_PATTERN.DATE
  );

  const imageTitle = `${t('machineDetails.robotRouteImage.title', {
    robotName: cleaningTaskReportLatestData?.machine.name,
    date: cleaningRouteDate,
  })}`;

  const onVisible = (): void => {
    setIsPreview(true);
  };

  const onInvisible = (): void => {
    setIsPreview(false);
  };

  const handleDownload = useCallback(() => {
    if (imageAsDataUrl) {
      getImageDownloaded({ imageAsDataUrl, imageTitle });
    }
  }, [imageAsDataUrl, imageTitle]);

  useEffect(() => {
    if (cleaningTaskReportLatestData) {
      dispatch(
        MachineDetailsPanelRobotActions.machineDetailsRobotCleaningTaskReportRouteImageRequest({
          machineId: cleaningTaskReportLatestData?.machine.id || '',
          finishedAt: cleaningTaskReportLatestData?.finishedAt || '',
        })
      );
    }

    return () => {
      dispatch(MachineDetailsPanelRobotActions.machineDetailsRobotCleaningTaskReportRouteImageResetState());
      if (imageAsDataUrl) URL.revokeObjectURL(imageAsDataUrl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cleaningTaskReportLatestData?.finishedAt,
    cleaningTaskReportLatestData?.machine.id,
    dispatch,
    cleaningTaskReportLatestData,
  ]);

  return (
    <StyledMachineDetailsRobotRouteImage>
      <MachineDetailsRobotRouteImageGlobalStyles />
      {robotRouteImageLoading || cleaningTaskReportsLoading ? (
        <div className="robot-ctr-image__preview-route-image-loading-indicator">
          <Spin size="large" />
        </div>
      ) : (
        <>
          {!isShowFallbackImage ? (
            <>
              <Image
                src={imageAsDataUrl || ''}
                className="robot-ctr-image__preview-route-image"
                wrapperClassName="robot-ctr-image__preview-route-image-wrapper"
                preview={{
                  visible: isPreview,
                  mask: false,
                  onVisibleChange: (): void => onInvisible(),
                  src: imageAsDataUrl || '',
                  imageRender: originalNode => (
                    <div className="robot-ctr-image__preview-route-image-render">
                      <>
                        <div className="robot-ctr-image__preview-route-image-title">{imageTitle}</div>
                        {originalNode}
                      </>
                    </div>
                  ),
                  toolbarRender: (
                    _,
                    {
                      transform: { scale },
                      actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                    }
                  ) => (
                    <Space size={12} className="toolbar-wrapper">
                      <DownloadOutlined onClick={handleDownload} />
                      <SwapOutlined rotate={90} onClick={onFlipY} />
                      <SwapOutlined onClick={onFlipX} />
                      <RotateLeftOutlined onClick={onRotateLeft} />
                      <RotateRightOutlined onClick={onRotateRight} />
                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                    </Space>
                  ),
                }}
                onError={(): void => {
                  setIsShowFallbackImage(true);
                }}
              />
              <Button className="robot-ctr-image__preview-btn" icon={<SvgIcon name="eyeSee" />} onClick={onVisible} />
            </>
          ) : (
            <div className="robot-ctr-image__empty-container">
              <div className="robot-ctr-image__empty-icon">
                <SvgIcon name="emptyRouteImage" />
              </div>
              <div className="robot-ctr-image__empty-text">
                {t('machineDetails.cleaningTaskOverviewRobot.cleaningRouteImage.emptyMessage')}
              </div>
            </div>
          )}
        </>
      )}
    </StyledMachineDetailsRobotRouteImage>
  );
};
