import { Row } from 'antd';
import styled from 'styled-components';

export const StyledMachineDetailsRobotConsumablesChart = styled(Row)`
  .ant-spin-nested-loading {
    width: 100%;
  }
  .robot-consumables-chart {
    padding-bottom: 32px;

    .robot-consumables-chart__spin {
      display: flex;
      justify-content: center;
      width: 100%;
      padding-top: 32px;
    }

    .robot-consumables-chart__bar {
      display: flex;
      justify-content: space-between;
      padding-bottom: 32px;
    }

    .robot-consumables-chart__unit {
      display: flex;
      justify-content: space-between;
      color: ${(props): string => props.theme.colors.grey};
      font-family: ${(props): string => props.theme.fonts.captionNews};
      font-size: ${(props): string => props.theme.fontSizes.caption};
      line-height: ${(props): string => props.theme.lineHeights.caption};
      font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
    }
  }
`;
