import { i18n as i18next } from 'i18next';
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { TooltipRendererProps } from 'react-charts/types/components/TooltipRenderer';
import { TFunction } from 'react-i18next';
import { DefaultTheme } from 'styled-components';
import { DateTime } from '../../../../../../../lib/utils/date-handling/DateTime';
import { DATE_FORMAT_PATTERN } from '../../../../../../../lib/utils/date-handling/DateTime.types';
import {
  OPERATING_HOURS_CHART_COLUMN_LABEL_ONE,
  OPERATING_HOURS_CHART_COLUMN_LABEL_TWO,
  OperatingHoursDatum,
} from '../../../../../cleaning/utils/OperatingHoursChartUtils';

interface MachineOperatingHoursChartTooltipDataProps {
  seriesId: string;
  seriesLabel: string;
  hoursTitle: string;
  percent: string;
  isHighlight: boolean;
  colorSquareIcon: string;
  isTopBorder: boolean;
}

export const MachineOperatingHoursChartTooltip = (
  t: TFunction,
  i18n: i18next,
  theme: DefaultTheme,
  tooltipRendererProps: TooltipRendererProps<OperatingHoursDatum>
): React.ReactNode => {
  let plannedMs = 0;
  let actualMs = 0;
  let deviationMs = 0;
  let deviationPercent = t('common.NA');
  const { focusedDatum } = tooltipRendererProps;
  let tooltipGroup = focusedDatum?.tooltipGroup || [];
  const seriesIndex = focusedDatum?.seriesIndex;
  const title =
    focusedDatum?.originalDatum?.primary instanceof Date
      ? DateTime.formatDateByLocale(
          i18n.language,
          focusedDatum?.originalDatum?.primary.toISOString(),
          DATE_FORMAT_PATTERN.DATE_WITH_WEEKDAY_NAME
        )
      : '';
  let deviationNotAvailable = true;
  if (tooltipGroup.length) {
    actualMs = DateTime.roundMsDurationToMinutes({
      ms: tooltipGroup.find(e => e.seriesLabel === OPERATING_HOURS_CHART_COLUMN_LABEL_ONE)?.secondaryValue,
    });

    plannedMs = DateTime.roundMsDurationToMinutes({
      ms: tooltipGroup.find(e => e.seriesLabel === OPERATING_HOURS_CHART_COLUMN_LABEL_TWO)?.secondaryValue,
    });

    if (plannedMs) {
      deviationNotAvailable = false;
      deviationMs = actualMs - plannedMs;
      deviationPercent = [parseFloat(((deviationMs * 100) / plannedMs).toFixed(0)), '%'].join('');
    }
  }

  // Order tooltip properties, actual always a first item
  tooltipGroup = tooltipGroup.sort((a, b) => a.seriesLabel.localeCompare(b.seriesLabel));

  const tooltipData: MachineOperatingHoursChartTooltipDataProps[] = tooltipGroup.map(colData => {
    const { seriesLabel, seriesId, secondaryValue } = colData;
    const secondaryValueRoundedDown = DateTime.roundMsDurationToMinutes({ ms: secondaryValue });
    const isHighlight = colData.seriesIndex === seriesIndex;
    const isPlanned = seriesLabel === OPERATING_HOURS_CHART_COLUMN_LABEL_TWO;
    const hoursTitle = [
      DateTime.formatDurationByMilliseconds({
        ms: secondaryValueRoundedDown,
        showSeconds: false,
      }),
      t('machineDetails.operatingHoursChart.hUnit'),
    ].join('');

    const colorSquareIcon = isPlanned ? theme.colors.lightCeladonGreen : theme.colors.celadonGreen;
    let percent = '0%';
    if (!!secondaryValueRoundedDown && !!plannedMs) {
      percent = [parseFloat(((secondaryValueRoundedDown * 100) / plannedMs).toFixed(0)), '%'].join('');
    }
    return {
      seriesId,
      seriesLabel,
      hoursTitle,
      percent,
      isHighlight,
      colorSquareIcon,
      isTopBorder: false,
    };
  });

  // Add deviation row in tooltip
  tooltipData.push({
    seriesId: 'deviation',
    seriesLabel: 'machineDetails.operatingHoursChart.deviation',
    hoursTitle: deviationNotAvailable
      ? t('common.NA')
      : [
          DateTime.formatDurationByMilliseconds({ ms: deviationMs || 0, showSeconds: false }),
          t('machineDetails.operatingHoursChart.hUnit'),
        ].join(''),
    percent: deviationPercent,
    isHighlight: true,
    colorSquareIcon: 'transparent',
    isTopBorder: true,
  });

  return (
    <div
      className="machine-operating-hours-chart__tooltip"
      style={{
        fontFamily: theme.fonts.captionNews,
      }}
    >
      <p className="machine-operating-hours-chart__tooltip-title">{title}</p>
      <div>
        {tooltipData.map(
          ({ seriesId, seriesLabel, hoursTitle, percent, isHighlight, colorSquareIcon, isTopBorder }, i) => (
            <div
              key={`tooltip-item-${i}-${seriesId}`}
              className={`machine-operating-hours-chart__tooltip-item ${isTopBorder ? 'border' : ''}`}
            >
              <div
                className="machine-operating-hours-chart__tooltip-item-square-icon"
                style={{ backgroundColor: colorSquareIcon }}
              />
              <div
                className="machine-operating-hours-chart__tooltip-item-text-block"
                style={{
                  opacity: isHighlight ? 1 : 0.5,
                }}
              >
                <p className="machine-operating-hours-chart__tooltip-item-seriesLabel">{`${t(seriesLabel)}`}</p>

                <div className="machine-operating-hours-chart__tooltip-item-hours-percent">
                  <p className="machine-operating-hours-chart__tooltip-item-hours">{`${hoursTitle}`}</p>
                  <p className="machine-operating-hours-chart__tooltip-item-percent">{percent}</p>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};
