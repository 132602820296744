import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import { Form } from 'antd';
import { TimeRangePickerProps } from 'antd/lib/time-picker';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash-es';
import { Select } from '../../../../../../../../lib/components/Select/Select';
import { SelectUtils } from '../../../../../../../../lib/components/Select/SelectUtils';
import * as machineDetailsPanelRobotSelectors from '../../../../state/machineDetailsPanelRobot/machineDetailsPanelRobotSelectors';
import { MachineDetailsPanelRobotActions } from '../../../../state/machineDetailsPanelRobot/machineDetailsPanelRobotSlice';
import { StyledMachineRobotFilter } from './MachineRobotFilter.styles';
import { Tooltip } from 'lib/components/Tooltip/Tooltip';
import { RangePicker } from 'lib/components/RangePicker/RangePicker';
import { CleaningReportRequestDateRangeRestrictor } from 'app/modules/cleaning/CleaningReportRequestDateRangeRestrictor';
import { OperatingHoursChartUtils } from 'app/modules/cleaning/utils/OperatingHoursChartUtils';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { InfoTooltipGlobalStyles } from 'lib/components/Tooltip/InfoTooltip/InfoTooltip.global.styles';
import { InfoTooltip } from 'lib/components/Tooltip/InfoTooltip/InfoTooltip';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { selectActivePeriodFilter } from 'app/modules/machine-inventory/machine-list/state/machineListSelectors';
import { ALL_VALUE_SELECT_OPTION } from 'config/constants';

const { Option } = Select;

interface MachineRobotFilterProps {
  robot: Machine;
}

export const MachineRobotFilter = ({ robot }: MachineRobotFilterProps): JSX.Element => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const availableRoutes = useSelector(machineDetailsPanelRobotSelectors.selectAvailableRoutes);
  const unavailableRoutes = useSelector(machineDetailsPanelRobotSelectors.selectUnavailableRoutes);
  const isRoutesNameLoading = useSelector(machineDetailsPanelRobotSelectors.selectRoutesNameIsLoading);
  const activeRoutesNameFilter = useSelector(machineDetailsPanelRobotSelectors.selectActiveRoutesName);
  const [isRoutesNameFilterOpen, setIsRoutesNameFilterOpen] = useState(false);

  const startDate = useSelector(machineDetailsPanelRobotSelectors.selectPeriodStartDate);
  const endDate = useSelector(machineDetailsPanelRobotSelectors.selectPeriodEndDate);

  const period = useSelector(selectActivePeriodFilter);

  useEffect(() => {
    if (period && period.startAt && period.endAt) {
      dispatch(
        MachineDetailsPanelRobotActions.machineDetailsRobotFilterActivePeriod({
          startDate: period.startAt,
          endDate: period.endAt,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!startDate || !endDate) return;
    form.setFieldsValue({
      dateRangeLocal: [dayjs(startDate), dayjs(endDate)],
    });
  }, [endDate, form, startDate]);

  useEffect(() => {
    if (!startDate || !endDate) return;
    if (activeRoutesNameFilter !== ALL_VALUE_SELECT_OPTION) return;
    dispatch(
      MachineDetailsPanelRobotActions.machineDetailsRobotGetRoutesNameListRequest({
        filter: {
          machineId: robot.id || '',
          period: {
            startAt: OperatingHoursChartUtils.prepareStartDate(dayjs(startDate).toDate()).toISOString(),
            endAt: OperatingHoursChartUtils.prepareEndDate(dayjs(endDate).toDate()).toISOString(),
          },
        },
      })
    );
  }, [dispatch, endDate, robot.id, startDate, activeRoutesNameFilter]);

  useEffect(
    () => () => {
      dispatch(MachineDetailsPanelRobotActions.machineDetailsRobotFilterResetState());
    },
    [dispatch]
  );

  const onFinish = (values: { dateRangeLocal: [Dayjs, Dayjs] }): void => {
    const { dateRangeLocal: dates } = values;

    if (dates && dates[0] && dates[1]) {
      dispatch(MachineDetailsPanelRobotActions.machineDetailsRobotCleaningTaskReportsResetState());

      dispatch(
        MachineDetailsPanelRobotActions.machineDetailsRobotFilterActivePeriod({
          startDate: OperatingHoursChartUtils.prepareStartDate(dates[0].toDate()).toISOString(),
          endDate: OperatingHoursChartUtils.prepareEndDate(dates[1].toDate()).toISOString(),
        })
      );
    }
  };

  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]): void => {
    noop(dates, dateStrings);
  };

  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: t('machineDetails.cleaningTaskOverviewRobot.period.lastWeek'), value: [dayjs().add(-7, 'd'), dayjs()] },
    {
      label: t('machineDetails.cleaningTaskOverviewRobot.period.lastMonth'),
      value: [dayjs().add(-1, 'month'), dayjs()],
    },
  ];

  const onChangeRouteName = (value: string): void => {
    dispatch(MachineDetailsPanelRobotActions.machineDetailsRobotCleaningTaskReportsResetState());
    dispatch(MachineDetailsPanelRobotActions.machineDetailsRobotFilterActiveRoutesName(value));
  };

  if (!startDate || !endDate) return <></>;

  return (
    <StyledMachineRobotFilter>
      <InfoTooltipGlobalStyles />
      <Form
        form={form}
        className="machine-robot-filter__form"
        name="machine-robot-filter__form"
        layout="horizontal"
        onFinish={onFinish}
        onValuesChange={(): void => {
          form.submit();
        }}
        autoComplete="off"
        validateTrigger="onSubmit"
        initialValues={{
          dateRangeLocal: [dayjs(startDate), dayjs(endDate)],
        }}
      >
        <div className="machine-robot-filter__select-wrapper machine-robot-filter__select-wrapper--period">
          <Form.Item
            name="dateRangeLocal"
            required
            className="machine-robot-filter__select"
            rules={[
              {
                validator: CleaningReportRequestDateRangeRestrictor.validate,
              },
            ]}
          >
            <RangePicker
              className="machine-robot-filter__range-picker"
              placement="bottomRight"
              presets={rangePresets}
              onChange={onRangeChange}
              disabledDate={CleaningReportRequestDateRangeRestrictor.disabledDatePredicate}
            />
          </Form.Item>
        </div>
        <div className="machine-robot-filter__select-wrapper machine-robot-filter__select-wrapper--route-name">
          <Select
            className="machine-robot-filter__select"
            loading={isRoutesNameLoading}
            onChange={onChangeRouteName}
            optionFilterProp="value"
            {...SelectUtils.getSingleSelectionProps({
              showSearch: true,
              value: activeRoutesNameFilter,
              dropdownVisibleState: isRoutesNameFilterOpen,
              onDropdownVisibleChange: (isOpen: boolean): void => setIsRoutesNameFilterOpen(isOpen),
              dropdownLabel: t('machineDetails.cleaningTaskOverviewRobot.placeholder'),
            })}
          >
            {availableRoutes?.map(route => (
              <Option key={route?.routeName} value={route?.routeName}>
                <div className="machine-robot-filter__select-option__available">
                  <span title={route?.routeName} className="machine-robot-filter__title">
                    {route?.routeName}
                  </span>
                </div>
              </Option>
            ))}
            {unavailableRoutes?.map(route => (
              <Option key={route?.routeName} value={route?.routeName}>
                <div className="machine-robot-filter__select-option__unavailable">
                  <Tooltip title={route?.routeName}>
                    <span className="machine-robot-filter__title">{route?.routeName}</span>
                  </Tooltip>
                  <Tooltip
                    className="machine-robot-filter__tooltip"
                    title={<InfoTooltip content="machineDetails.tooltip.unavailableRoutes" />}
                    placement="topRight"
                    overlayClassName="tooltip-overlay"
                  >
                    <span className="machine-robot-filter__icon-info">
                      <SvgIcon name="info" className="machine-robot-filter__icon" />
                    </span>
                  </Tooltip>
                </div>
              </Option>
            ))}
          </Select>
        </div>
      </Form>
    </StyledMachineRobotFilter>
  );
};
