import { ApolloQueryResult, FetchResult, gql } from '@apollo/client';
import { GraphQLSubscription } from '@aws-amplify/api';
import { API } from 'aws-amplify';
import { inject, injectable } from 'tsyringe';
import Observable from 'zen-observable-ts';
import { IUsermachinesClassificationsCheckResponse } from '../../cross-cutting-concerns/authentication/interfaces/Authentication.types';
import { GraphQlSubscriptionMessage } from '../../cross-cutting-concerns/communication/interfaces/graphql.types';
import { GraphQlUtils } from '../../cross-cutting-concerns/communication/utils/GraphQlUtils';
import {
  FleetOperatingTimeResponse,
  GetMachineOperatingTimeForPeriod,
  MachineDetails,
  MachineList,
  MachinesAvailableToBeAssigned,
  MachinesAvailableToBeAssignedVariantData,
  MachinesAvailableToFilter,
  MachinesListWithCleaningReport,
  MachineTypesResponse,
  MachineUpdateResponse,
  MachineListVariantData,
  MachineVariantsResponse,
  MachineDetailsVariantDatum,
  MachinesExportRequest,
  MachinesExportGetFile,
  MachineRobotCleaningConsumptionSummaryResponse,
  SubscriptionMachineUpdateResult,
  MachineListAsReport,
  MachineListTelemetryData,
  MachineListLatestCtrData,
  MachineDetailsLatestCtrDatum,
  MachineDetailsTelemetriesDatum,
  MachinesReportExportRequest,
  MachineReportSubscriptionsListResponse,
  MachineReportSubscriptionsResetResponse,
  MachineFilterResponse,
  MachineDetailsLatestRoutineDatum,
} from './interfaces/Machine.types';
import { MachineAttachmentsListResponse } from './interfaces/MachineAttachment.types';
import { MachineSubscriptionType } from './interfaces/MachineSubscription.types';
import { GraphQlClient } from 'app/cross-cutting-concerns/communication/clients/GraphQlClient';
import {
  InputFilterMachinesList,
  InputPagingOptions,
  InputSortOptions,
  InputPeriod,
  QueryFleetOperatingTimeArgs,
  SupportedLanguage,
  InputFilterCleaningConsumption,
  SubscriptionOnMachineUpdateArgs,
  InputFilterMachinesReport,
  InputMachineUpdate,
  Column,
  MutationMachinesReportSubscriptionsResetArgs,
  QueryMachineFilterArgs,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface MachineClientListAsReportOptions {
  period: InputPeriod;
  search: string;
  filter?: InputFilterMachinesReport;
  sortOptions?: InputSortOptions;
  paginationOptions: InputPagingOptions;
  timezone: string;
}

export interface MachineClientListOptions {
  filter?: InputFilterMachinesList;
  sortOptions?: InputSortOptions;
  paginationOptions: InputPagingOptions;
}

export interface MachineClientListCoordinatesOptions {
  filter?: InputFilterMachinesList;
}

export interface MachineNestedParamsPeriodOptions {
  period: InputPeriod;
}

export interface MachineClientDetailsOptions {
  id: string;
}

export interface MachineClientOperatingTimeOptions extends MachineClientDetailsOptions {
  period: InputPeriod;
  timezone: string;
}

export interface MachineClientDetailsPictureOptions {
  id: string;
}

export interface MachineClientUpdateOptions extends InputMachineUpdate {
  id: string;
  siteName?: string;
}

export interface MachineClientListMachinesWithCleaningReportOptions extends MachineNestedParamsPeriodOptions {
  filter?: InputFilterMachinesList;
  sortOptions?: InputSortOptions;
  paginationOptions: InputPagingOptions;
}

export interface MachineClientListMachinesWithOperatingTimeOptions extends MachineNestedParamsPeriodOptions {
  filter?: InputFilterMachinesList;
  sortOptions?: InputSortOptions;
  paginationOptions: InputPagingOptions;
}

export type MachineClientFleetOperatingTimeOptions = QueryFleetOperatingTimeArgs;

export interface MachineClientRequestExportMachinesOptions {
  filter?: InputFilterMachinesList;
  sortOptions?: InputSortOptions;
  timezone: string;
  lang: SupportedLanguage;
}

export interface MachineClientRequestExportMachinesReportOptions {
  period: InputPeriod;
  filter: InputFilterMachinesReport;
  timezone: string;
  lang: SupportedLanguage;
  columns?: Column[];
  search?: string;
  sortOptions?: InputSortOptions;
}

export interface MachineClientGetExportMachinesFileOptions {
  requestId: string;
}

export interface MachineClientGetConsumptionsSummaryOptions {
  filter: InputFilterCleaningConsumption;
}

export interface MachineClientAttachmentsListOptions {
  machineId: string;
  paginationOptions: InputPagingOptions;
  sortOptions?: InputSortOptions;
}

export type MachineClientResetMachineReportSubscriptionsOptions = MutationMachinesReportSubscriptionsResetArgs;

export type MachineClientMachineFilterOptions = QueryMachineFilterArgs;

@injectable()
export class MachineClient {
  constructor(@inject('GraphQlClient') private client: GraphQlClient) {}

  public listRobots = async ({
    filter,
    sortOptions,
    paginationOptions,
  }: MachineClientListOptions): Promise<ApolloQueryResult<MachineList>> =>
    this.client.query({
      query: gql`
        query Robots(
          $filter: InputFilterMachinesList
          $sortOptions: InputSortOptions
          $paginationOptions: InputPagingOptions
        ) {
          machines(filter: $filter, sortOptions: $sortOptions, paginationOptions: $paginationOptions) {
            data {
              site {
                id
                name
              }
              type {
                name
              }
              id
              name
              classification
              materialNumber
              serialNumber
              lastActivityAt
              robotStatus
              connectionStatus
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        filter,
        sortOptions,
        paginationOptions,
      },
    });

  public robotsWithTelemetries = async ({
    filter,
    sortOptions,
    paginationOptions,
  }: MachineClientListOptions): Promise<ApolloQueryResult<MachineListTelemetryData>> =>
    this.client.query({
      query: gql`
        query MachinesWithTelemetries(
          $filter: InputFilterMachinesList
          $sortOptions: InputSortOptions
          $paginationOptions: InputPagingOptions
        ) {
          machines(filter: $filter, sortOptions: $sortOptions, paginationOptions: $paginationOptions) {
            data {
              id
              states {
                machineId
                stateName
                stateValue
              }
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        filter,
        sortOptions,
        paginationOptions,
      },
    });

  public listWithLatestCtr = async ({
    filter,
    sortOptions,
    paginationOptions,
  }: MachineClientListOptions): Promise<ApolloQueryResult<MachineListLatestCtrData>> =>
    this.client.query({
      query: gql`
        query MachinesWithLatestCtr(
          $filter: InputFilterMachinesList
          $sortOptions: InputSortOptions
          $paginationOptions: InputPagingOptions
        ) {
          machines(filter: $filter, sortOptions: $sortOptions, paginationOptions: $paginationOptions) {
            data {
              id
              cleaningTaskReportLatestGet {
                data {
                  routeName
                  finishedAt
                }
              }
            }
          }
        }
      `,
      variables: {
        filter,
        sortOptions,
        paginationOptions,
      },
    });

  public listWithLatestRoutine = async ({
    filter,
    sortOptions,
    paginationOptions,
  }: MachineClientListOptions): Promise<ApolloQueryResult<MachineListLatestCtrData>> =>
    this.client.query({
      query: gql`
        query MachinesWithLatestRoutine(
          $filter: InputFilterMachinesList
          $sortOptions: InputSortOptions
          $paginationOptions: InputPagingOptions
        ) {
          machines(filter: $filter, sortOptions: $sortOptions, paginationOptions: $paginationOptions) {
            data {
              id
              latestRoutine {
                data {
                  createdAt
                  currentRepeat
                  estimatedEndDate
                  executionId
                  machineId
                  name
                  numberOfRepeats
                  routineId
                  startDate
                  status
                }
              }
            }
          }
        }
      `,
      variables: {
        filter,
        sortOptions,
        paginationOptions,
      },
    });

  public listAsReport = async ({
    period,
    search,
    filter,
    sortOptions,
    paginationOptions,
    timezone,
  }: MachineClientListAsReportOptions): Promise<ApolloQueryResult<MachineListAsReport>> =>
    this.client.query({
      query: gql`
        query MachinesReport(
          $period: InputPeriod!
          $search: String
          $filter: InputFilterMachinesReport!
          $sortOptions: InputSortOptions
          $paginationOptions: InputPagingOptions
          $timezone: String!
        ) {
          machinesReport(
            period: $period
            search: $search
            filter: $filter
            sortOptions: $sortOptions
            paginationOptions: $paginationOptions
            timezone: $timezone
          ) {
            data {
              machineId
              materialNumber
              serialNumber
              activatedAt
              lastConnectedAt
              connectionStatus
              classification
              softwareVersion
              lastActivityAt
              isIoTDevice
              machineTypeName
              totalOperatingTimeInSeconds
              batteryDischargeStatus
              site {
                siteId
                name
                managers {
                  email
                  fullName
                  userId
                }
              }
              statistics {
                startAt
                endAt
                actualOperatingTimeInMs
                plannedOperatingTimeInMs
                deviationInMs
                deviationInPercentage
                cleaningDays
                avgWorkingTimePerDayInMs
              }
              metadata {
                name
                inventoryNumber
                finance {
                  contractNumber
                  financeType
                }
                service {
                  partNumber
                  servicePackage
                }
              }
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        period,
        search,
        filter,
        sortOptions,
        paginationOptions,
        timezone,
      },
    });

  public list = async ({
    filter,
    sortOptions,
    paginationOptions,
  }: MachineClientListOptions): Promise<ApolloQueryResult<MachineList>> =>
    this.client.query({
      query: gql`
        query Machines(
          $filter: InputFilterMachinesList
          $sortOptions: InputSortOptions
          $paginationOptions: InputPagingOptions
        ) {
          machines(filter: $filter, sortOptions: $sortOptions, paginationOptions: $paginationOptions) {
            data {
              id
              materialNumber
              serialNumber
              name
              classification
              lastActivityAt
              site {
                name
                id
              }
              type {
                name
              }
              isIoTDevice
              batteryDischargeStatus
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        filter,
        sortOptions,
        paginationOptions,
      },
    });

  public listPictures = async ({
    filter,
    sortOptions,
    paginationOptions,
  }: MachineClientListOptions): Promise<ApolloQueryResult<MachineListVariantData>> =>
    this.client.query({
      query: gql`
        query MachinesPictures(
          $filter: InputFilterMachinesList
          $sortOptions: InputSortOptions
          $paginationOptions: InputPagingOptions
        ) {
          machines(filter: $filter, sortOptions: $sortOptions, paginationOptions: $paginationOptions) {
            data {
              id
              variant {
                picture {
                  overview
                  product
                  thumbnail
                }
              }
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        filter,
        sortOptions,
        paginationOptions,
      },
    });

  public listAvailableToBeAssigned = async (): Promise<ApolloQueryResult<MachinesAvailableToBeAssigned>> =>
    this.client.query({
      query: gql`
        query MachinesAvailableToBeAssigned($filter: InputFilterMachinesList) {
          machines(filter: $filter) {
            data {
              id
              name
              type {
                name
              }
            }
          }
        }
      `,
      variables: {
        filter: {
          isStandalone: true,
        },
      },
    });

  public listAvailableToBeAssignedPictures = async (): Promise<
    ApolloQueryResult<MachinesAvailableToBeAssignedVariantData>
  > =>
    this.client.query({
      query: gql`
        query MachinesAvailableToBeAssignedPictures($filter: InputFilterMachinesList) {
          machines(filter: $filter) {
            data {
              id
              variant {
                picture {
                  overview
                  product
                  thumbnail
                }
              }
            }
          }
        }
      `,
      variables: {
        filter: {
          isStandalone: true,
        },
      },
    });

  public get = async ({ id }: MachineClientDetailsOptions): Promise<ApolloQueryResult<MachineDetails>> =>
    this.client.query({
      query: gql`
        query Machine($id: ID!) {
          machine(id: $id) {
            data {
              id
              materialNumber
              serialNumber
              activationDate
              connectionStatus
              name
              classification
              softwareVersion
              totalOperatingTime
              lastActivityAt
              robotStatus
              batteryDischargeStatus
              metadata {
                inventoryNumber
                finance {
                  contractNumber
                  financeType
                }
                service {
                  partNumber
                  servicePackage
                }
              }
              site {
                id
                name
                geofence {
                  centerPoint {
                    latitude
                    longitude
                  }
                  id
                  radius
                }
                location {
                  address
                  latitude
                  longitude
                }
              }
              location {
                latitude
                longitude
                address
                accuracy
              }
              type {
                materialNumber
                name
              }
              isIoTDevice
            }
          }
        }
      `,
      variables: {
        id,
      },
    });

  public getMetadata = async ({ id }: MachineClientDetailsOptions): Promise<ApolloQueryResult<MachineDetails>> =>
    this.client.query({
      query: gql`
        query Machine($id: ID!) {
          machine(id: $id) {
            data {
              id
              metadata {
                inventoryNumber
                finance {
                  contractNumber
                  financeType
                }
                service {
                  partNumber
                  servicePackage
                }
              }
            }
          }
        }
      `,
      variables: {
        id,
      },
    });

  public getPicture = async ({
    id,
  }: MachineClientDetailsPictureOptions): Promise<ApolloQueryResult<MachineDetailsVariantDatum>> =>
    this.client.query({
      query: gql`
        query MachinePicture($id: ID!) {
          machine(id: $id) {
            data {
              id
              variant {
                picture {
                  overview
                  product
                  thumbnail
                }
              }
            }
          }
        }
      `,
      variables: {
        id,
      },
    });

  public getTelemetries = async ({
    id,
  }: MachineClientDetailsOptions): Promise<ApolloQueryResult<MachineDetailsTelemetriesDatum>> =>
    this.client.query({
      query: gql`
        query Machine($id: ID!) {
          machine(id: $id) {
            data {
              id
              states {
                machineId
                stateName
                stateValue
              }
            }
          }
        }
      `,
      variables: {
        id,
      },
    });

  public getLatestCtr = async ({
    id,
  }: MachineClientDetailsOptions): Promise<ApolloQueryResult<MachineDetailsLatestCtrDatum>> =>
    this.client.query({
      query: gql`
        query Machine($id: ID!) {
          machine(id: $id) {
            data {
              id
              cleaningTaskReportLatestGet {
                data {
                  routeName
                  finishedAt
                }
              }
            }
          }
        }
      `,
      variables: {
        id,
      },
    });

  public getLastestRoutine = async ({
    id,
  }: MachineClientDetailsOptions): Promise<ApolloQueryResult<MachineDetailsLatestRoutineDatum>> =>
    this.client.query({
      query: gql`
        query Machine($id: ID!) {
          machine(id: $id) {
            data {
              id
              latestRoutine {
                data {
                  createdAt
                  currentRepeat
                  estimatedEndDate
                  executionId
                  machineId
                  name
                  numberOfRepeats
                  routineId
                  startDate
                  status
                }
              }
            }
          }
        }
      `,
      variables: {
        id,
      },
    });

  public getMachineOperatingTime = async ({
    id,
    timezone,
    period,
  }: MachineClientOperatingTimeOptions): Promise<ApolloQueryResult<GetMachineOperatingTimeForPeriod>> =>
    this.client.query({
      query: gql`
        query MachineOperatingTime($id: ID!, $period: InputPeriod!, $timezone: String!) {
          machine(id: $id) {
            data {
              operatingTimeForPeriod(period: $period) {
                startAt
                endAt
                actualTotalOperatingTimeMs
                operatingTimeIntervals(timezone: $timezone) {
                  startAt
                  endAt
                  actualOperatingTimeMs
                  plannedOperatingTimeMs
                  deviationMs
                }
              }
              type {
                materialNumber
                name
              }
            }
          }
        }
      `,
      variables: {
        id,
        period,
        timezone,
      },
    });

  public listMaterialNumbers = async (): Promise<ApolloQueryResult<MachineVariantsResponse>> =>
    this.client.query({
      query: gql`
        query MachineVariants {
          machineVariants {
            data {
              materialNumber
              id
            }
          }
        }
      `,
    });

  public update = async ({ id, ...input }: MachineClientUpdateOptions): Promise<FetchResult<MachineUpdateResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation MachineUpdate($id: ID!, $input: InputMachineUpdate!) {
          machineUpdate(id: $id, input: $input) {
            data {
              name
              metadata {
                name
                inventoryNumber
                finance {
                  contractNumber
                  financeType
                }
                service {
                  partNumber
                  servicePackage
                }
              }
            }
          }
        }
      `,
      variables: {
        id,
        input,
      },
    });

  public listCoordinates = async ({
    filter,
  }: MachineClientListCoordinatesOptions): Promise<ApolloQueryResult<MachineList>> =>
    this.client.query({
      query: gql`
        query MachinesCoordinates($filter: InputFilterMachinesList) {
          machines(filter: $filter) {
            data {
              id
              name
              materialNumber
              lastActivityAt
              classification
              location {
                accuracy
                latitude
                longitude
              }
              type {
                materialNumber
                name
              }
            }
          }
        }
      `,
      variables: {
        filter,
      },
    });

  public listCoordinatesPictures = async ({
    filter,
  }: MachineClientListCoordinatesOptions): Promise<ApolloQueryResult<MachineListVariantData>> =>
    this.client.query({
      query: gql`
        query MachinesCoordinatesPictures($filter: InputFilterMachinesList) {
          machines(filter: $filter) {
            data {
              id
              variant {
                picture {
                  overview
                  product
                  thumbnail
                }
              }
            }
          }
        }
      `,
      variables: {
        filter,
      },
    });

  public listMachinesWithCleaningReport = async ({
    filter,
    sortOptions,
    paginationOptions,
    period,
  }: MachineClientListMachinesWithCleaningReportOptions): Promise<ApolloQueryResult<MachinesListWithCleaningReport>> =>
    this.client.query({
      query: gql`
        query MachinesListWithCleaningReport(
          $filter: InputFilterMachinesList
          $sortOptions: InputSortOptions
          $paginationOptions: InputPagingOptions!
          $period: InputPeriod!
        ) {
          machines(filter: $filter, sortOptions: $sortOptions, paginationOptions: $paginationOptions) {
            data {
              operatingTimeForPeriod(period: $period) {
                actualTotalOperatingTimeMs
                plannedTotalOperatingTimeMs
                startAt
                endAt
              }
              site {
                id
                name
              }
              id
              name
              classification
              materialNumber
              serialNumber
              lastActivityAt
              type {
                materialNumber
                name
              }
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        filter,
        sortOptions,
        paginationOptions,
        period,
      },
    });

  public listAvailableToFilter = async ({
    filter,
    sortOptions,
    paginationOptions,
  }: MachineClientListOptions): Promise<ApolloQueryResult<MachinesAvailableToFilter>> =>
    this.client.query({
      query: gql`
        query ListMachinesAvailableToFilter(
          $filter: InputFilterMachinesList
          $sortOptions: InputSortOptions
          $paginationOptions: InputPagingOptions!
        ) {
          machines(filter: $filter, sortOptions: $sortOptions, paginationOptions: $paginationOptions) {
            data {
              id
              name
              classification
              type {
                name
              }
            }
          }
        }
      `,
      variables: {
        filter,
        sortOptions,
        paginationOptions,
      },
    });

  public listMachineTypes = async (): Promise<ApolloQueryResult<MachineTypesResponse>> =>
    this.client.query({
      query: gql`
        query MachineTypes {
          machineTypes {
            data {
              materialNumbers
              name
            }
          }
        }
      `,
    });

  public listMachinesWithOperatingTime = async ({
    filter,
    sortOptions,
    paginationOptions,
    period,
  }: MachineClientListMachinesWithOperatingTimeOptions): Promise<ApolloQueryResult<MachinesListWithCleaningReport>> =>
    this.client.query({
      query: gql`
        query MachinesListWithOperatingTime(
          $filter: InputFilterMachinesList
          $sortOptions: InputSortOptions
          $paginationOptions: InputPagingOptions!
          $period: InputPeriod!
        ) {
          machines(filter: $filter, sortOptions: $sortOptions, paginationOptions: $paginationOptions) {
            data {
              operatingTimeForPeriod(period: $period) {
                actualTotalOperatingTimeMs
                plannedTotalOperatingTimeMs
                startAt
                endAt
              }
              id
              name
              classification
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        filter,
        sortOptions,
        paginationOptions,
        period,
      },
    });

  public getFleetOperatingTime = async ({
    filter,
  }: MachineClientFleetOperatingTimeOptions): Promise<ApolloQueryResult<FleetOperatingTimeResponse>> =>
    this.client.query({
      query: gql`
        query FleetOperatingTime($filter: InputFilterFleetOperatingTime!) {
          fleetOperatingTime(filter: $filter) {
            data {
              actualTotalOperatingTimeS
              plannedTotalOperatingTimeS
            }
          }
        }
      `,
      variables: {
        filter,
      },
    });

  public requestExportMachines = async ({
    filter,
    sortOptions,
    timezone,
    lang,
  }: MachineClientRequestExportMachinesOptions): Promise<FetchResult<MachinesExportRequest>> =>
    this.client.mutate({
      mutation: gql`
        mutation CreateMachinesListExportFileJob(
          $filter: InputFilterMachinesList
          $sortOptions: InputSortOptions
          $timezone: String!
          $lang: SupportedLanguage!
        ) {
          machinesListExportRequest(filter: $filter, sortOptions: $sortOptions, timezone: $timezone, lang: $lang) {
            data {
              requestId
            }
          }
        }
      `,
      variables: {
        filter,
        sortOptions,
        timezone,
        lang,
      },
    });

  public requestExportMachinesReport = async ({
    period,
    search,
    filter,
    sortOptions,
    timezone,
    lang,
    columns,
  }: MachineClientRequestExportMachinesReportOptions): Promise<FetchResult<MachinesReportExportRequest>> =>
    this.client.mutate({
      mutation: gql`
        mutation machinesReportExportRequest(
          $period: InputPeriod!
          $search: String
          $filter: InputFilterMachinesReport!
          $sortOptions: InputSortOptions
          $timezone: String!
          $lang: SupportedLanguage!
          $columns: [Column!]
        ) {
          machinesReportExportRequest(
            period: $period
            search: $search
            filter: $filter
            sortOptions: $sortOptions
            timezone: $timezone
            lang: $lang
            columns: $columns
          ) {
            data {
              requestId
            }
          }
        }
      `,
      variables: {
        period,
        search,
        filter,
        sortOptions,
        timezone,
        lang,
        columns,
      },
    });

  public getExportMachinesFile = async ({
    requestId,
  }: MachineClientGetExportMachinesFileOptions): Promise<ApolloQueryResult<MachinesExportGetFile>> =>
    this.client.query({
      query: gql`
        query GetMachineListDownloadUrl($id: String!) {
          machinesExportFile(id: $id) {
            data {
              downloadUrl
              s3Key
              status
            }
          }
        }
      `,
      variables: {
        id: requestId,
      },
    });

  public getClassificationAvailabilityConfig = async (): Promise<
    ApolloQueryResult<IUsermachinesClassificationsCheckResponse>
  > =>
    this.client.query({
      query: gql`
        query UserMachinesClassificationsCheck {
          usermachinesClassificationsCheck {
            hasAccessToGCD
            hasAccessToRobots
          }
        }
      `,
    });

  public listAttachments = async ({
    machineId,
    paginationOptions,
    sortOptions,
  }: MachineClientAttachmentsListOptions): Promise<ApolloQueryResult<MachineAttachmentsListResponse>> =>
    this.client.query({
      query: gql`
        query MachineAttachments(
          $machineId: ID!
          $paginationOptions: InputPagingOptions!
          $sortOptions: InputSortOptions
        ) {
          machine(id: $machineId) {
            data {
              id
              attachments(paginationOptions: $paginationOptions, sortOptions: $sortOptions) {
                data {
                  id
                  name
                  size
                  path
                  createdAt
                  mimeType
                }
                metadata {
                  paginationToken
                  totalCount
                }
              }
            }
          }
        }
      `,
      variables: {
        machineId,
        paginationOptions,
        sortOptions,
      },
    });

  public getMachineRobotConsumptionSummary = async ({
    filter,
  }: MachineClientGetConsumptionsSummaryOptions): Promise<
    ApolloQueryResult<MachineRobotCleaningConsumptionSummaryResponse>
  > =>
    this.client.query({
      query: gql`
        query RobotConsumptionSummary($filter: InputFilterCleaningConsumption!) {
          robotConsumptionSummary(filter: $filter) {
            data {
              battery
              detergents
              water
            }
          }
        }
      `,
      variables: {
        filter,
      },
    });

  public subscribeToMachineUpdate = ({
    customerId,
    machineId,
  }: SubscriptionOnMachineUpdateArgs): {
    observable: Observable<GraphQlSubscriptionMessage<SubscriptionMachineUpdateResult>>;
    throttleDuration: number;
  } => {
    const subscriptionType = MachineSubscriptionType.MACHINE_UPDATE;
    const throttleDuration = GraphQlUtils.getThrottleDurationBySubscriptionType(subscriptionType);

    const observable = API.graphql<GraphQLSubscription<SubscriptionOnMachineUpdateArgs>>({
      query: gql`
        subscription OnMachineUpdate($customerId: String!, $machineId: ID!) {
          onMachineUpdate(customerId: $customerId, machineId: $machineId) {
            publishedAt
            machineId
            event
            customerId
            data
          }
        }
      `,
      variables: {
        customerId,
        machineId,
      },
    });

    return {
      observable: observable as unknown as Observable<GraphQlSubscriptionMessage<SubscriptionMachineUpdateResult>>,
      throttleDuration,
    };
  };

  public listMachineReportSubscriptions = async (): Promise<
    ApolloQueryResult<MachineReportSubscriptionsListResponse>
  > =>
    this.client.query({
      query: gql`
        query machinesReportSubscriptionsList {
          machinesReportSubscriptionsList {
            data {
              customerId
              email
              lang
              reportFileFormats
              reportInterval
              reportType
              userId
            }
          }
        }
      `,
    });

  public resetMachineReportSubscriptions = async ({
    input,
  }: MachineClientResetMachineReportSubscriptionsOptions): Promise<
    FetchResult<MachineReportSubscriptionsResetResponse>
  > =>
    this.client.mutate({
      mutation: gql`
        mutation machinesReportSubscriptionsReset($input: [InputMachinesReportSubscription!]!) {
          machinesReportSubscriptionsReset(input: $input) {
            data {
              customerId
              email
              lang
              reportFileFormats
              reportInterval
              reportType
              userId
            }
          }
        }
      `,
      variables: {
        input,
      },
    });

  public getMachineFilter = async ({
    filter,
    timezone,
  }: MachineClientMachineFilterOptions): Promise<ApolloQueryResult<MachineFilterResponse>> =>
    this.client.query({
      query: gql`
        query MachineFilter($filter: InputMachineFilterFilter, $timezone: String!) {
          machineFilter(filter: $filter, timezone: $timezone) {
            data {
              classifications
              dataStatuses
              financeTypes
              machineTypes {
                materialNumbers
                name
              }
              servicePackages
              subclassifications
              sites {
                name
                siteId
              }
            }
          }
        }
      `,
      variables: {
        filter,
        timezone,
      },
    });
}
