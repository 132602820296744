import styled from 'styled-components';

export const StyledMachineMapPinPopover = styled.div`
  .machine-map-pin-popover {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    gap: 24px;
    border-bottom: 1px solid ${(props): string => props.theme.colors.brighterGrey};

    &:last-child {
      border-bottom: none;
    }
  }

  .ant-popover-inner-content {
    padding: 0 16px;
  }

  font-family: ${(props): string => props.theme.fonts.captionNews};
  font-size: ${(props): string => props.theme.fontSizes.caption};
  line-height: ${(props): string => props.theme.lineHeights.caption};
  font-weight: ${(props): number => props.theme.fontWeights.captionNews};

  .machine-map-pin-popover__info {
    display: flex;
    flex-direction: column;
  }

  .machine-map-pin-popover__link--no-styling {
    color: ${(props): string => props.theme.colors.black};
  }

  .ant-btn-text.machine-map-pin-popover__name-link--no-styling {
    padding: 0;
  }

  .machine-map-pin-popover__image {
    max-height: 75px;
    .ant-image-img {
      width: auto;
    }
  }

  .machine-map-pin-popover__skeleton-image {
    width: 75px;
    height: 75px;
  }

  .machine-map-pin-popover__info-title,
  .machine-map-pin-popover__info-subtitle,
  .machine-map-pin-popover__info-paragraph {
    margin: 0;
  }

  .machine-map-pin-popover__info-title {
    font-weight: bold;
    font-family: ${(props): string => props.theme.fonts.redesign.base.strong};
  }

  .machine-map-pin-popover__info-paragraph {
    color: ${(props): string => props.theme.colors.grey200};
  }

  .machine-map-pin-popover__connection-status {
    display: flex;
    justify-items: center;
    align-items: center;
    flex: 1;
    flex-direction: row-reverse;
  }

  .machine-map-pin-popover__pagination {
    display: flex;
    justify-content: center;
    margin: 10px 0 0 0;

    .ant-pagination-mini {
      display: flex;
      justify-content: center;

      .ant-pagination-item-active a {
        color: ${(props): string => props.theme.colors.black};
        text-decoration: underline;
      }
      .ant-pagination-item {
        display: flex;
        align-items: center;
        border: none;
        a {
          font-family: ${(props): string => props.theme.fonts.p1News};
          font-size: ${(props): string => props.theme.fontSizes.p1};
          line-height: ${(props): string => props.theme.lineHeights.p1};
          font-weight: ${(props): number => props.theme.fontWeights.p1News};
        }
      }

      .ant-pagination-item:hover a,
      .ant-pagination-item-link:hover span,
      .ant-pagination-next:hover button,
      .ant-pagination-prev:hover button {
        color: ${(props): string => props.theme.colors.black};
        font-weight: bold;
      }

      .machine-map-pin-popover__pagination-item-prev,
      .machine-map-pin-popover__pagination-item-next {
        &[disabled],
        &[disabled]:hover {
          path {
            stroke: ${(props): string => props.theme.colors.grey};
          }
        }
      }

      .machine-map-pin-popover__pagination-item-prev {
        margin: 0 15px 0 0;
        transform: rotate(180deg);
      }

      .machine-map-pin-popover__pagination-item-next {
        margin: 0 0 0 15px;
      }
    }
  }
`;
