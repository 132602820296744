import styled from 'styled-components';

export const StyledMachineRobotCleaningRoute = styled.div`
  .robot-cleaning-route__label {
    ${(props): string => props.theme.fontStyles.redesign.sm.delete};
    text-transform: uppercase;
    margin: 0;
    width: auto;
    word-break: break-word;
    white-space: normal;
  }

  .robot-cleaning-route__details {
    ${(props): string => props.theme.fontStyles.redesign.sm.normal};
  }

  .robot-cleaning-route__label {
    margin-bottom: 0px;
  }
`;
