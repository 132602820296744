import { Popover } from 'antd';
import classnames from 'classnames';
import React from 'react';
import { first, isNil } from 'lodash-es';
import { Machine } from '../../interfaces/Machine.types';
import { MachinePictureLoader } from '../MachinePictureLoader/MachinePictureLoader';
import { StyledMachineMapPin } from './MachineMapPin.styles';
import { MachineMapPinPopover } from './MachineMapPinPopover';
import { ReactComponent as MapMarkerGroup } from 'app/assets/images/map/marker-group.svg';
import { ReactComponent as MapCluster } from 'app/assets/images/map/marker-cluster.svg';
import { ReactComponent as MapMarker } from 'app/assets/images/map/map-marker.svg';

export enum MachineMarkerType {
  SINGLE = 'single',
  MULTI = 'multi',
  CLUSTER = 'cluster',
}

export interface ClusterInfo {
  pointCount?: number;
  clusterId: string | number | undefined;
  onClickCluster: (clusterId: string | number | undefined) => void;
}

export interface MachineMapPinProps {
  id?: string | number;
  className?: string;
  lat: number;
  lng: number;
  machines: Machine[];
  popupContainerRef?: HTMLElement;
  visible: boolean;
  onClick?: (machineId: string) => void;
  clusterInfo?: ClusterInfo;
  backgroundColor: string;
  textColor: string;
  type: MachineMarkerType;
}

export const MachineMapPin = ({
  className = '',
  machines = [],
  popupContainerRef,
  onClick,
  visible,
  clusterInfo,
  type,
}: MachineMapPinProps): JSX.Element | null => {
  const mapPinClassName = classnames('machine-map-pin', className);
  const isCluster = type === MachineMarkerType.CLUSTER;
  const isMultiMachineMarker = type === MachineMarkerType.MULTI;
  const firstMachine = first(machines.filter(Boolean));

  if (isCluster) {
    return (
      <StyledMachineMapPin className={mapPinClassName}>
        <div onClick={(): void => clusterInfo?.onClickCluster(clusterInfo.clusterId)}>
          <MapCluster className="machine-map-pin__cluster-marker" />
          <p className="machine-map-pin__point-count">{clusterInfo?.pointCount}</p>
        </div>
      </StyledMachineMapPin>
    );
  }

  if (isNil(firstMachine)) {
    return null;
  }

  return (
    <StyledMachineMapPin className={mapPinClassName}>
      <Popover
        placement="top"
        arrow={{ pointAtCenter: false }}
        open={visible}
        getPopupContainer={(triggerNode): HTMLElement => popupContainerRef ?? triggerNode}
        overlayClassName="machine-map-pin__overlay"
        content={<MachineMapPinPopover machines={machines} />}
        destroyTooltipOnHide={true}
      >
        <div className="machine-map-pin__content" onClick={(): void => onClick && onClick(machines[0].id)}>
          {isMultiMachineMarker ? (
            <div>
              <MapMarkerGroup />
              <p className="machine-map-pin__group-point-count">{machines?.length}</p>
            </div>
          ) : (
            <div className="machine-map-pin__map-marker-container">
              {firstMachine.variant?.picture?.thumbnail ? (
                <img className="machine-map-pin__machine-image" src={firstMachine.variant?.picture?.thumbnail} />
              ) : (
                <MachinePictureLoader
                  className={classnames('machine-map-pin__machine-image', 'machine-map-pin__machine-image--loading')}
                />
              )}
              <MapMarker className="machine-map-pin__map-marker" />
            </div>
          )}
        </div>
      </Popover>
    </StyledMachineMapPin>
  );
};
