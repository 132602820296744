import { Spin, Empty } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortOrders } from '../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { IMachineAttachment } from '../../../interfaces/MachineAttachment.types';
import { MachineAttachment, MachineAttachmentSize } from './MachineAttachment';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';

export interface MachineAttachmentListContentProps {
  machine: Machine;
  attachments: IMachineAttachment[];
  size: MachineAttachmentSize;
  isLoading: boolean;
  sortOrder: SortOrders;
  onSortOrderChange: () => void;
}

export const MachineAttachmentListContent = ({
  machine,
  attachments,
  size,
  isLoading,
}: MachineAttachmentListContentProps): JSX.Element => {
  const { t } = useTranslation();
  const hasAttachments = attachments.length > 0;

  if (isLoading) {
    return (
      <div className="machine-attachment-list__content">
        <div className="machine-attachment-list__load-indicator">
          <Spin size="large" />
        </div>
      </div>
    );
  }

  if (hasAttachments) {
    return (
      <div className="machine-attachment-list__content">
        <div className="machine-attachment-list__attachments">
          {attachments.map((attachment: IMachineAttachment) => (
            <MachineAttachment
              machine={machine}
              attachment={attachment}
              key={attachment.id}
              size={size}
              showExtensionInTitle={false}
              showPreviewIcon={false}
            />
          ))}
        </div>
      </div>
    );
  }

  // Empty list
  return (
    <div className="machine-attachment-list__content">
      <div className="machine-attachment-list__empty-indicator">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('machineAttachmentList.emptyText')} />
      </div>
    </div>
  );
};
