import { useTranslation } from 'react-i18next';
import { useMemo, useEffect } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { MachineRobotsCleaningDataChartHeader } from '../MachineDetailsRobotsCleaningDataChartHeader/MachineDetailsRobotsCleaningDataChartHeader';
import MachineDetailsRobotsBarChart from '../MachineDetailsRobotsBarChart';
import * as machineDetailsPanelRobotSelectors from '../../../../../state/machineDetailsPanelRobot/machineDetailsPanelRobotSelectors';
import { StyledMachineDetailsRobotDistanceDrivenChart } from './MachineDetailsRobotDistanceDrivenChart.styles';
import { CleaningTaskDistanceDrivenByDay } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { MachineDetailsPanelRobotActions } from 'app/modules/machine-inventory/machine-details-panel/state/machineDetailsPanelRobot/machineDetailsPanelRobotSlice';
import { getDatesByPeriod } from 'lib/utils/date-handling/getTimeByPeriod';
import { Numbers } from 'lib/utils/number-formatting/Numbers';
import { DateFormats } from 'app/modules/machine-inventory/interfaces/Robot.types';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { ConvertUnitUtils } from 'app/utils/convert-unit/ConvertUnitUtils';
import { DateTime } from 'lib/utils/date-handling/DateTime';

const DEFAULT_VALUE = {
  distanceDriven: 0,
  finishedAt: '',
};

interface MachineDetailsRobotDistanceDrivenChartProps {
  robot: Machine;
}

export const MachineDetailsRobotDistanceDrivenChart = ({
  robot,
}: MachineDetailsRobotDistanceDrivenChartProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const startAt = useSelector(machineDetailsPanelRobotSelectors.selectPeriodStartDate);
  const endAt = useSelector(machineDetailsPanelRobotSelectors.selectPeriodEndDate);
  const routeName = useSelector(machineDetailsPanelRobotSelectors.selectActiveRoutesName);

  const timeZone = DateTime.getBrowserTimeZone();

  const cleaningDataDistanceDrivenByDay = useSelector(
    machineDetailsPanelRobotSelectors.selectCleaningDataDistanceDrivenByDay
  );
  const periodDistanceDriven = useSelector(machineDetailsPanelRobotSelectors.selectCleaningDataPeriodDistanceDriven);
  const totalDistanceDriven = useSelector(machineDetailsPanelRobotSelectors.selectCleaningDataTotalDistanceDriven);
  const distanceDrivenIsLoading = useSelector(
    machineDetailsPanelRobotSelectors.selectCleaningDataDistanceDrivenIsLoading
  );

  const startTime = startAt ? dayjs(startAt) : null;
  const endTime = endAt ? dayjs(endAt) : null;

  const dates = getDatesByPeriod(startTime, endTime).map(d => d.format(DateFormats.DATE_IN_DAY_MONTH));

  const isUSusers = false;

  useEffect(() => {
    if (!startAt || !endAt) return;
    dispatch(
      MachineDetailsPanelRobotActions.machineDetailsRobotListCleaningDataDistanceDrivenRequest({
        filter: {
          machineId: robot.id || '',
          period: {
            endAt,
            startAt,
          },
          routeName,
          timeZone,
        },
      })
    );
  }, [dispatch, endAt, robot.id, startAt, routeName, timeZone]);

  const periodData = useMemo(() => {
    const period = getDatesByPeriod(startTime, endTime).map(d => d.format(DateFormats.DATE_IN_YEAR_MONTH_DAY));
    const result: CleaningTaskDistanceDrivenByDay[] = [];
    period.forEach(date => {
      const data = cleaningDataDistanceDrivenByDay.find(
        item => dayjs(item.finishedAt).format(DateFormats.DATE_IN_YEAR_MONTH_DAY) === date
      );
      if (!data) {
        result.push({ ...DEFAULT_VALUE, finishedAt: date });
      } else {
        result.push(data);
      }
    });
    return result;
  }, [cleaningDataDistanceDrivenByDay, endTime, startTime]);

  const distanceDriven = periodData.map(item =>
    isUSusers
      ? ConvertUnitUtils.convertMetersToFeetFactor(item.distanceDriven || 0)
      : Numbers.formatNumberDecimalRounded(item.distanceDriven, 1)
  );
  const finishDates = periodData.map(item => item.finishedAt);

  return (
    <StyledMachineDetailsRobotDistanceDrivenChart>
      <Spin size="large" className="robot-cleaned-distance-driven-chart__spin" spinning={distanceDrivenIsLoading}>
        <MachineRobotsCleaningDataChartHeader
          labelNoteSquare={t('machineDetails.robotCleaningData.distanceDriven.note')}
          periodLabel={t('machineDetails.robotCleaningData.distanceDriven.periodDistanceDriven')}
          totalLabel={t('machineDetails.robotCleaningData.distanceDriven.totalDistanceDriven')}
          periodValue={
            isUSusers
              ? ConvertUnitUtils.convertMetersToFeetFactor(periodDistanceDriven || 0)
              : Numbers.formatNumberDecimalRounded(periodDistanceDriven, 1)
          }
          totalValue={
            isUSusers
              ? ConvertUnitUtils.convertMetersToFeetFactor(totalDistanceDriven || 0)
              : Numbers.formatNumberDecimalRounded(totalDistanceDriven, 1)
          }
          unit={isUSusers ? t('common.feetUnit') : t('common.mUnit')}
        />
        <div className="robot-cleaned-distance-driven-chart__wrapper">
          <div className="robot-distance-driven-chart__unit">
            {isUSusers
              ? t('machineDetails.robotCleaningData.distanceDriven.feet')
              : t('machineDetails.robotCleaningData.distanceDriven.meter')}
          </div>
          <MachineDetailsRobotsBarChart
            period={dates}
            cleaningValues={distanceDriven}
            unit={isUSusers ? t('common.feetUnit') : t('common.mUnit')}
            finishDates={finishDates}
          />
        </div>
      </Spin>
    </StyledMachineDetailsRobotDistanceDrivenChart>
  );
};
