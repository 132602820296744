import { PaginationTokens } from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { Machine, MachineUpdateResponse } from '../../interfaces/Machine.types';
import { Reminder } from '../../../reminder/interfaces/Reminder.types';
import { IMachineAttachment } from '../../interfaces/MachineAttachment.types';
import {
  MachineNote,
  Maybe,
  Notification,
  OperatingTimePeriod,
  SortOrders,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';

export const selectData = (state: IState): Optional<Machine> =>
  state.modules['machine-inventory']['machine-details-panel'].data;

export const selectIsLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel'].isLoading;

export const selectIsMetadataLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel'].isMetadataLoading;

export const selectIsPictureLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel'].isPictureLoading;

export const selectIsTelemetryLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel'].isTelemetryLoading;

export const selectIsLatestCtrLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel'].isLatestCtrLoading;

export const selectMachineNotificationsData = (state: IState): Optional<Notification[]> =>
  state.modules['machine-inventory']['machine-details-panel'].notifications.data;

export const selectMachineNotificationsTotalCount = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details-panel'].notifications.totalCount;

export const selectIsMachineNotificationsLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel'].notifications.isLoading;

export const selectMachineNotificationsPaginationTokens = (state: IState): PaginationTokens =>
  state.modules['machine-inventory']['machine-details-panel'].notifications.paginationTokens;

export const selectMachineNotificationsPage = (state: IState): number =>
  state.modules['machine-inventory']['machine-details-panel'].notifications.page;

export const selectMachineNotificationsPageSize = (state: IState): number =>
  state.modules['machine-inventory']['machine-details-panel'].notifications.pageSize;

export const selectMachineNotificationsSortField = (state: IState): Optional<string> =>
  state.modules['machine-inventory']['machine-details-panel'].notifications.sortOptions.field;

export const selectMachineNotificationsSortOrder = (state: IState): Optional<SortOrders> =>
  state.modules['machine-inventory']['machine-details-panel'].notifications.sortOptions.order;

export const selectOperatingTime = (state: IState): Optional<OperatingTimePeriod> =>
  state.modules['machine-inventory']['machine-details-panel'].operatingTime.data;

export const selectOperatingTimeLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel'].operatingTime.isLoading;

export const selectRemindersData = (state: IState): Optional<Reminder[]> =>
  state.modules['machine-inventory']['machine-details-panel'].reminders.data;

export const selectRemindersTotalCount = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details-panel'].reminders.totalCount;

export const selectRemindersIsInitialLoad = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel'].reminders.isInitialLoad;

export const selectRemindersIsLoadingMore = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel'].reminders.isLoadingMore;

export const selectRemindersPaginationTokens = (state: IState): PaginationTokens =>
  state.modules['machine-inventory']['machine-details-panel'].reminders.paginationTokens;

export const selectRemindersPage = (state: IState): number =>
  state.modules['machine-inventory']['machine-details-panel'].reminders.page;

export const selectNotesData = (state: IState): Optional<MachineNote[]> =>
  state.modules['machine-inventory']['machine-details-panel'].notes.data;

export const selectNotesTotalCount = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details-panel'].notes.totalCount;

export const selectNotesIsInitialLoad = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel'].notes.isInitialLoad;

export const selectNotesIsLoadingMore = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel'].notes.isLoadingMore;

export const selectNotesPaginationTokens = (state: IState): PaginationTokens =>
  state.modules['machine-inventory']['machine-details-panel'].notes.paginationTokens;

export const selectNotesPage = (state: IState): number =>
  state.modules['machine-inventory']['machine-details-panel'].notes.page;

export const selectAttachmentsData = (state: IState): Optional<IMachineAttachment[]> =>
  state.modules['machine-inventory']['machine-details-panel'].attachments.data;

export const selectAttachmentsTotalCount = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details-panel'].attachments.totalCount;

export const selectAttachmentsIsLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel'].attachments.isLoading;

export const selectAttachmentsPaginationTokens = (state: IState): PaginationTokens =>
  state.modules['machine-inventory']['machine-details-panel'].attachments.paginationTokens;

export const selectAttachmentsPage = (state: IState): number =>
  state.modules['machine-inventory']['machine-details-panel'].attachments.page;

export const selectAttachmentsPageSize = (state: IState): number =>
  state.modules['machine-inventory']['machine-details-panel'].attachments.pageSize;

export const selectAttachmentsSortField = (state: IState): Maybe<string> =>
  state.modules['machine-inventory']['machine-details-panel'].attachments.sortOptions.field;

export const selectAttachmentsSortOrder = (state: IState): Maybe<SortOrders> =>
  state.modules['machine-inventory']['machine-details-panel'].attachments.sortOptions.order;

export const selectAttachmentsDownloadsIsLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel'].attachments.downloads.isLoading;

export const selectUpdateMachineInfo = (state: IState): Optional<MachineUpdateResponse> =>
  state.modules['machine-inventory']['machine-details-panel'].updateMachineInfo.data;
