import { ColumnsType } from 'antd/lib/table';
import { TFunction } from 'react-i18next';
import { ReactNode } from 'react';
import { i18n as i18next } from 'i18next';
import { Notification } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { NotificationIcon } from 'app/modules/notification/components/NotificationIcon/NotificationIcon';
import { NotificationViewModel } from 'app/modules/notification/NotificationViewModel';
import { SortableColumnTitle } from 'lib/components/Table/SortableColumnTitle/SortableColumnTitle';
import { DATE_FORMAT_PATTERN } from 'lib/utils/date-handling/DateTime.types';

export const getMachineNotificationListColumns = ({
  t,
  i18n,
}: {
  t: TFunction;
  i18n: i18next;
  isSortingEnabled?: boolean;
}): ColumnsType<Notification> => [
  {
    width: 48,
    render: ({ severity, type }): ReactNode => <NotificationIcon severity={severity} type={type} />,
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<Notification>(
        t('machineDetails.notificationList.table.columns.timestamp'),
        false,
        'timestamp',
        sortColumns
      ),
    dataIndex: 'timestamp',
    width: 146,
    key: 'timestamp',
    render: (timestamp): JSX.Element => (
      <div className="machine-details-notification-list__timestamp-container">
        <div className="machine-details-notification-list__timestamp">
          {DateTime.formatDateByLocale(i18n.language, timestamp, DATE_FORMAT_PATTERN.DATE)}
        </div>
        <div className="machine-details-notification-list__timestamp">
          {DateTime.formatDateByLocale(i18n.language, timestamp, DATE_FORMAT_PATTERN.TIME)}
        </div>
      </div>
    ),
  },
  {
    title: t('machineDetails.notificationList.table.columns.description'),
    dataIndex: 'description',
    key: 'description',
    width: 292,
    render: (_: string, notification: Notification): JSX.Element => (
      <>{new NotificationViewModel(notification).description}</>
    ),
  },
];
