import styled from 'styled-components';

export const StyledMachineDetailsRobotRouteImage = styled.div`
  height: 100%;
  border: 1px solid ${(props): string => props.theme.colors.brightGrey};
  display: flex;
  align-items: center;

  .robot-ctr-image__empty-container {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .robot-ctr-image__empty-icon {
      svg {
        width: 40px;
        height: 40px;
      }
      margin-bottom: ${(props): string => props.theme.spacing.sm};
    }

    .robot-ctr-image__empty-text {
      ${(props): string => props.theme.fontStyles.caption.medium};
    }
  }

  .ant-image {
    img {
      max-height: 100%;
      object-fit: contain;
    }
  }

  .robot-ctr-image__preview-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    width: 32px;
    height: 32px;
    background-color: ${(props): string => props.theme.colors.white};
    padding: 0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    svg {
      color: ${(props): string => props.theme.colors.black};
      margin: 3px 0px;
      width: 24px;
      height: 24px;
    }
  }

  .robot-ctr-image__preview-route-image-wrapper {
    max-height: 100%;
    display: flex;
    padding: 20px;
    width: 100%;
  }
`;
