import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledMachineDetailsNonIotBanner } from './MachineDetailsNonIotBanner.styles';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';

export const MachineDetailsNonIotBanner = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <StyledMachineDetailsNonIotBanner>
      <div className="machine-details-non-iot-banner">
        <div className="machine-details-non-iot-banner__content">
          <SvgIcon name="nonIot" className="machine-details-non-iot-banner__icon" />
          <p className="machine-details-non-iot-banner__text">{t('machineDetails.generalInfo.noMachineConnection')}</p>
        </div>
      </div>
    </StyledMachineDetailsNonIotBanner>
  );
};
