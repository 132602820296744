import styled from 'styled-components';

export const StyledMachineMapPin = styled.div`
  width: 40px;
  height: 48px;
  transform: translate(-50%, -100%);
  z-index: 500;
  cursor: pointer;

  .machine-map-pin__point-count {
    margin: 0 0 0 0;
    color: ${(props): string => props.theme.colors.white};
    position: absolute;
    top: 15px;
    left: 0;
    font-size: 14px;
    width: 48px;
    text-align: center;
  }

  .machine-map-pin__map-marker-container {
    display: flex;
    position: relative;
    width: 100%;
    height: 47px;
    transform-origin: 50% 100%;
    transition: 100ms linear;

    :hover {
      transform: scale(1.15);
    }
  }

  .machine-map-pin__content {
    display: flex;
    align-items: center;

    .machine-map-pin__group-point-count {
      margin: 0 0 0 0;
      color: ${(props): string => props.theme.colors.white};
      position: absolute;
      top: 17px;
      left: 10px;
      font-size: 14px;
      width: 41px;
      text-align: center;
    }
  }

  .machine-map-pin__map-marker {
    position: absolute;
    align-items: flex-start;
    color: ${(props): string => props.theme.colors.white};
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3));
  }

  .machine-map-pin__machine-image {
    position: absolute;
    width: 100%;
    z-index: 600;
    align-items: flex-start;
    transform: scale(0.8);
    clip-path: circle(50% at 50% 50%);
  }

  .machine-map-pin__machine-image--loading {
    transform: scale(0.7);
    clip-path: circle(60% at 50% 50%);
    top: 1px;
  }

  .machine-map-pin__overlay {
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 600;
  }
`;
