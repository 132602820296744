import { useTranslation } from 'react-i18next';
import { IMachineCategoryItemData } from '../../interfaces/Machine.types';
import { StyledMachineCategoryIndicatorTooltip } from './MachineCategoryIndicatorTooltip.styles';

interface MachineCategoryIndicatorTooltipProps {
  categoryItem: IMachineCategoryItemData;
}
export const MachineCategoryIndicatorTooltip = ({
  categoryItem,
}: MachineCategoryIndicatorTooltipProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledMachineCategoryIndicatorTooltip className="machine-category-indicator-tooltip">
      {t(categoryItem.title)}
    </StyledMachineCategoryIndicatorTooltip>
  );
};
