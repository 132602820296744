import styled from 'styled-components';

export const StyledMachineRobotCleaningDataChart = styled.div`
  border: 1px solid ${(props): string => props.theme.colors.brightGrey};
  padding: ${(props): string => props.theme.spacing.lg};

  .machine-robot-cleaning-data__sub-header {
    ${(props): string => props.theme.fontStyles.redesign.xl.strong};
    font-weight: 700;
    margin-bottom: ${(props): string => props.theme.spacing.md};
  }

  .machine-robot-cleaning-data__bar {
    display: flex;
    justify-content: right;
  }

  .machine-robot-cleaning-data__tabs {
    width: 100%;

    .ant-tabs-nav {
      margin-bottom: ${(props): string => props.theme.spacing.md};
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: ${(props): string => props.theme.colors.black};
        ${(props): string => props.theme.fontStyles.redesign.base.strong}
      }
    }

    .ant-tabs-tab {
      .ant-tabs-tab-btn {
        color: ${(props): string => props.theme.colors.black};
        ${(props): string => props.theme.fontStyles.redesign.base.normal}
      }
    }

    .ant-tabs-ink-bar {
      background-color: ${(props): string => props.theme.colors.black};
      height: 4px;
    }
  }

  .chart-wrapper {
    height: 392px;
  }
`;
