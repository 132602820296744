import styled from 'styled-components';
import { Card } from 'lib/components/Card/Card';

export const StyledMachineDetailsTaskCompletedHistory = styled(Card)`
  height: 100%;

  .ant-card-head {
    border-bottom: none;

    .ant-card-head-title {
      h3 {
        text-transform: capitalize;
        ${(props): string => props.theme.fontStyles.redesign.xl.strong};
        font-weight: 700;
        margin: 0;
      }
    }
  }

  .machine-details-task-completion-history__loading-overlay {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    justify-content: center;
    align-items: center;
  }
`;
