import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { MachineStatusIndicatorGlobalStyles } from '../MachineStatusIndicator.global.styles';
import { StyledMachineBatteryDischargeStatusIndicator } from './MachineBatteryDischargeStatusIndicator.styles';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { StatusPillProps, StatusPill } from 'lib/components/StatusPill/StatusPill';
import {
  BatteryDischargeStatusItemData,
  BatteryDischargeStatusUnAvailable,
  BatteryDischargeStatusWithUnAvailable,
} from 'app/modules/machine-inventory/interfaces/Machine.types';

export interface MachineBatteryDischargeStatusIndicatorProps {
  className?: string;
  status?: BatteryDischargeStatusWithUnAvailable | null;
  showIcon?: boolean;
  showText?: boolean;
  showDescription?: boolean;
  shortText?: boolean;
  size?: 'small' | 'medium';
}

export const MachineBatteryDischargeStatusIndicator = ({
  className,
  status,
  showIcon,
  showText,
  shortText,
  showDescription,
  size = 'medium',
}: MachineBatteryDischargeStatusIndicatorProps): React.JSX.Element | null => {
  const { t } = useTranslation();

  const statusItem = useMemo(() => {
    if (!status) return BatteryDischargeStatusItemData[BatteryDischargeStatusUnAvailable.UnAvailable];

    return BatteryDischargeStatusItemData[status];
  }, [status]);

  const getStatusPillSize = (): StatusPillProps['size'] => {
    if (size === 'medium') {
      return 'large';
    }

    return 'small';
  };

  return (
    <StyledMachineBatteryDischargeStatusIndicator>
      <div className={classnames(className, 'machine-battery-status', `machine-battery-status--size-${size}`)}>
        <MachineStatusIndicatorGlobalStyles />
        {statusItem && (
          <div className={`machine-battery-status__tooltip-wrapper`}>
            <StatusPill
              color={statusItem.color}
              size={getStatusPillSize()}
              className={`machine-battery-status__pill ${shortText ? 'machine-battery-status__pill--short-text' : ''}`}
            >
              {showIcon && <SvgIcon name={statusItem.icon} className={`machine-battery-status__icon`} />}
              {showText && <span className={`machine-battery-status__text`}>&nbsp;{t(statusItem.title)}</span>}
              {shortText && <span className={`machine-battery-status__short_text`}>{t(statusItem.shortText)}</span>}
            </StatusPill>
          </div>
        )}

        {!statusItem && <div>{t('machineDetails.batteryVoltage.unavailable.title')}</div>}
      </div>
      {showDescription && statusItem.description && (
        <div className="machine-battery-status__description">{t(statusItem?.description)}</div>
      )}
    </StyledMachineBatteryDischargeStatusIndicator>
  );
};
