import styled from 'styled-components';

export const StyledMachineStatusIndicator = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  .machine-connection-status__tooltip-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 44px;
      svg {
        height: 24px;
      }
    }
  }

  .machine-connection-status__icon {
    color: ${(props): string => props.theme.colors.black};
    font-size: 16px;

    &::before {
      margin-left: 0;
    }
  }

  .machine-connection-status__text {
    ${(props): string => props.theme.fontStyles.redesign.sm.strong};
    color: ${(props): string => props.theme.colors.darkGrey};
    margin-left: 4px;
    margin-bottom: -2px;
  }

  &.machine-connection-status--size-small {
    .machine-connection-status__icon {
      min-width: unset;
      svg {
        height: 16px;
        width: 20px;
      }
    }

    .notAvailable {
      svg {
        height: 16px;
        width: 32px;
      }
    }
  }
`;
