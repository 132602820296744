import styled from 'styled-components';

export const StyledLegacyMachineStatusIndicatorTooltip = styled.div`
  display: flex;
  flex-direction: row;

  .legacy-machine-status-indicator-tooltip__icon {
    color: ${(props): string => props.theme.colors.darkGrey};
    margin: 0 8px 0 0;
    width: 32px;
    height: 32px;
    text-align: center;

    svg {
      width: auto;
      height: auto;
    }
  }

  .legacy-machine-status-indicator-tooltip__icon--nonIot,
  .legacy-machine-status-indicator-tooltip__icon--deactivated {
    margin-top: 5px;
  }

  .legacy-machine-status-indicator-tooltip__info {
    flex: 1;
  }

  .legacy-machine-status-indicator-tooltip__title {
    font-family: ${(props): string => props.theme.fonts.captionMedium};
    font-weight: ${(props): number => props.theme.fontWeights.p1Medium};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    margin-bottom: 4px;
  }

  .legacy-machine-status-indicator-tooltip__description {
    font-family: ${(props): string => props.theme.fonts.captionNews};
    font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    margin-bottom: 0;
  }
`;
