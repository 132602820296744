import { singleton } from 'tsyringe';
import {
  RobotClient,
  RobotClientListRobotsKPIsOptions,
  RobotClientListRobotsWithCleanedAreaOptions,
  RobotClientListRobotsWithTotalCleanedHourOptions,
  RobotClientGetRobotDashboardTasksCompletionOptions,
  RobotClientRequestRobotVncOptions,
  RobotClientGetRobotVncStatusOptions,
  RobotClientGetCleaningTaskCleanedAreaByDayListOptions,
  RobotClientGetCleaningTaskCleanedHrsByDayListOptions,
  RobotClientGetCleaningTaskConsumablesByDayListOptions,
  RobotClientGetCleaningTaskDistanceDrivenByDayListOptions,
  RobotClientGetRobotVncStatusV2Options,
} from './RobotClient';
import {
  RobotDashboardKPIsResponse,
  RobotDashboardTotalCleanedAreaResponse,
  RobotDashboardTotalCleanedHourResponse,
  RobotDashboardTasksCompletionStatisticResponse,
  RobotRequestVncResponse,
  RobotGetVncStatusResponse,
  RobotCleaningTaskCleanedAreaByDayListResponse,
  RobotCleaningTaskCleanedHrsByDayListResponse,
  RobotCleaningTaskConsumablesByDayListResponse,
  RobotCleaningTaskDistanceDrivenByDayListResponse,
  RoutesNameListResponse,
  RobotRequestVncResponseV2,
  RobotGetVncStatusV2Response,
} from './interfaces/Robot.types';
import { Optional } from 'lib/types/Optional';
import { QueryRobotCleaningRoutesListArgs } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export type RobotServiceListRobotsWithCleanedAreaOptions = RobotClientListRobotsWithCleanedAreaOptions;
export type RobotServiceListRobotsWithTotalCleanedHourOptions = RobotClientListRobotsWithTotalCleanedHourOptions;
export type RobotServiceListRobotsKPIsOptions = RobotClientListRobotsKPIsOptions;
export type RobotServiceGetRobotDashboardTasksCompletionOptions = RobotClientGetRobotDashboardTasksCompletionOptions;
export type RobotServiceRequestRobotVncOptions = RobotClientRequestRobotVncOptions;
export type RobotServiceGetRobotVncStatusOptions = RobotClientGetRobotVncStatusOptions;
export type RobotServiceGetRobotVncStatusV2Options = RobotClientGetRobotVncStatusV2Options;

export type RobotServiceGetCleaningTaskCleanedAreaByDayListOptions =
  RobotClientGetCleaningTaskCleanedAreaByDayListOptions;
export type RobotServiceGetCleaningTaskCleanedHrsByDayListOptions =
  RobotClientGetCleaningTaskCleanedHrsByDayListOptions;
export type RobotServiceGetCleaningTaskConsumablesByDayListOptions =
  RobotClientGetCleaningTaskConsumablesByDayListOptions;
export type RobotServiceGetCleaningTaskDistanceDrivenByDayListOptions =
  RobotClientGetCleaningTaskDistanceDrivenByDayListOptions;

@singleton()
export class RobotService {
  constructor(private robotClient: RobotClient) {}

  public listRobotsWithCleanedArea = async ({
    filter,
    paginationOptions,
  }: RobotServiceListRobotsWithCleanedAreaOptions): Promise<RobotDashboardTotalCleanedAreaResponse> => {
    const { data } = await this.robotClient.listRobotsWithCleanedArea({
      filter,
      paginationOptions,
    });
    return data;
  };

  public listRobotsWithTotalCleanedHour = async ({
    filter,
    paginationOptions,
  }: RobotServiceListRobotsWithTotalCleanedHourOptions): Promise<RobotDashboardTotalCleanedHourResponse> => {
    const { data } = await this.robotClient.listRobotsWithTotalCleanedHour({
      filter,
      paginationOptions,
    });
    return data;
  };

  public robotsKPIs = async ({
    filter,
    paginationOptions,
  }: RobotServiceListRobotsKPIsOptions): Promise<RobotDashboardKPIsResponse> => {
    const { data } = await this.robotClient.robotsKPIs({
      filter,
      paginationOptions,
    });
    return data;
  };

  public getRobotDashboardTasksCompletion = async ({
    filter,
  }: RobotServiceGetRobotDashboardTasksCompletionOptions): Promise<RobotDashboardTasksCompletionStatisticResponse> => {
    const { data } = await this.robotClient.getRobotDashboardTasksCompletion({
      filter,
    });
    return data;
  };

  public requestRobotVnc = async ({
    machineId,
  }: RobotServiceRequestRobotVncOptions): Promise<Optional<RobotRequestVncResponse>> => {
    const { data } = await this.robotClient.requestRobotVnc({ machineId });
    return data;
  };

  public requestRobotVncV2 = async ({
    machineId,
  }: RobotServiceRequestRobotVncOptions): Promise<Optional<RobotRequestVncResponseV2>> => {
    const { data } = await this.robotClient.requestRobotVncV2({ machineId });
    return data;
  };

  public getRobotVncStatus = async ({
    machineId,
  }: RobotServiceGetRobotVncStatusOptions): Promise<Optional<RobotGetVncStatusResponse>> => {
    const { data } = await this.robotClient.getRobotVncStatus({ machineId });
    return data;
  };

  public getRobotVncStatusV2 = async ({
    machineId,
    sessionId,
  }: RobotServiceGetRobotVncStatusV2Options): Promise<Optional<RobotGetVncStatusV2Response>> => {
    const { data } = await this.robotClient.getRobotVncStatusV2({ machineId, sessionId });
    return data;
  };

  public listRoutesName = async ({ filter }: QueryRobotCleaningRoutesListArgs): Promise<RoutesNameListResponse> => {
    const { data } = await this.robotClient.listRoutesName({ filter });

    return data;
  };

  public getCleaningTaskCleanedAreaByDayList = async ({
    filter,
  }: RobotServiceGetCleaningTaskCleanedAreaByDayListOptions): Promise<
    Optional<RobotCleaningTaskCleanedAreaByDayListResponse>
  > => {
    const { data } = await this.robotClient.getCleaningTaskCleanedAreaByDayList({
      filter,
    });

    return data;
  };

  public getCleaningTaskCleanedHrsByDayList = async ({
    filter,
  }: RobotServiceGetCleaningTaskCleanedHrsByDayListOptions): Promise<
    Optional<RobotCleaningTaskCleanedHrsByDayListResponse>
  > => {
    const { data } = await this.robotClient.getCleaningTaskCleanedHrsByDayList({
      filter,
    });
    return data;
  };

  public getCleaningTaskConsumablesByDayList = async ({
    filter,
  }: RobotServiceGetCleaningTaskConsumablesByDayListOptions): Promise<
    Optional<RobotCleaningTaskConsumablesByDayListResponse>
  > => {
    const { data } = await this.robotClient.getCleaningTaskConsumablesByDayList({
      filter,
    });
    return data;
  };

  public getCleaningTaskDistanceDrivenByDayList = async ({
    filter,
  }: RobotServiceGetCleaningTaskDistanceDrivenByDayListOptions): Promise<
    Optional<RobotCleaningTaskDistanceDrivenByDayListResponse>
  > => {
    const { data } = await this.robotClient.getCleaningTaskDistanceDrivenByDayList({
      filter,
    });
    return data;
  };
}
