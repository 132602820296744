import { singleton } from 'tsyringe';
import { FooClient } from './FooClient';
import { Foo } from './interfaces/Foo';

@singleton()
export class FooService {
  constructor(private fooClient: FooClient) {}

  public list = async (): Promise<Foo[]> => {
    const { data } = await this.fooClient.list({});

    return data;
  };
}
