import { Pagination, Typography } from 'antd';
import classnames from 'classnames';
import { chunk } from 'lodash-es';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RoutePaths } from '../../../../../config/route-paths';
import { DATE_FORMAT_PATTERN } from '../../../../../lib/utils/date-handling/DateTime.types';
import { Machine } from '../../interfaces/Machine.types';
import { LegacyMachineStatusIndicator } from '../LegacyMachineStatusIndicator/LegacyMachineStatusIndicator';
import { StyledMachineMapPinPopover } from './MachineMapPinPopover.styles';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { ReactComponent as RightArrow } from 'lib/assets/images/rightArrow.svg';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';

const CONSTANTS = { pageSize: 3 };
const { Text } = Typography;

export interface MachineMapPinPopoverProps {
  className?: string;
  machines: Machine[];
}

export const MachineMapPinPopover = ({ className = '', machines }: MachineMapPinPopoverProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [paginationIndex, setPaginationIndex] = useState(1);

  const popoverClassName = classnames('machine-map-pin-popover', className);
  const isMachineExactLocation = machines?.length > 1;
  const machinesChunk = chunk(machines, CONSTANTS.pageSize);
  const onPaginationChange = (val: number): void => setPaginationIndex(val);

  const handleClickMachinePinPopover = (machineId: string): void => {
    if (location.pathname.startsWith(RoutePaths.MACHINES)) {
      navigate(RoutePaths.MACHINE_DETAILS.replace(':id', machineId));
    } else {
      dispatch(DrawersActions.showMachineDetailsDrawer({ machineId }));
    }
  };

  return (
    <StyledMachineMapPinPopover>
      {machinesChunk[paginationIndex - 1].map(machine => (
        <div
          key={`MachineMapPinPopover-${machine.id}`}
          className={popoverClassName}
          onClick={(): void => handleClickMachinePinPopover(machine.id)}
        >
          <div className="machine-map-pin-popover__info">
            <div>
              <Text strong className="machine-map-pin-popover__info-title">
                {machine.name}
              </Text>
              <p className="machine-map-pin-popover__info-subtitle">{machine.type?.name}</p>
            </div>
            <div>
              <p className="machine-map-pin-popover__info-paragraph">
                {DateTime.formatDateByLocale(i18n.language, machine.lastActivityAt, DATE_FORMAT_PATTERN.DATE_TIME)}
              </p>
              <p className="machine-map-pin-popover__info-paragraph">
                {machine.location?.accuracy
                  ? `${t('machineMap.pin.accurateTo', { accuracy: machine.location?.accuracy })}`
                  : ''}
              </p>
            </div>
          </div>
          <div className="machine-map-pin-popover__connection-status">
            <LegacyMachineStatusIndicator machine={machine} />
          </div>
        </div>
      ))}
      {isMachineExactLocation && machines.length > 3 && (
        <div className="machine-map-pin-popover__pagination">
          <Pagination
            total={machines.length}
            onChange={onPaginationChange}
            size="small"
            pageSize={CONSTANTS.pageSize}
            responsive
            showLessItems
            itemRender={(_, type, element): React.ReactNode => {
              switch (type) {
                case 'prev':
                  return (
                    <RightArrow className="machine-map-pin-popover__pagination-item-prev" width={12} height={12} />
                  );
                case 'next':
                  return (
                    <RightArrow className="machine-map-pin-popover__pagination-item-next" width={12} height={12} />
                  );

                default:
                  return element;
              }
            }}
          />
        </div>
      )}
    </StyledMachineMapPinPopover>
  );
};
