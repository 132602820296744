import styled from 'styled-components';

export const StyledMachineDetailsMapLegendPopover = styled.div`
  .machine-details-map-legend-popover__item {
    margin: 12px 0;
  }

  .machine-details-map-legend-popover__item-icon-column {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .machine-details-map-legend-popover__item-icon {
    width: 31px;
    height: 31px;
    flex: 1;
    border-radius: 9999px;
  }

  .machine-details-map-legend-popover__item-icon-geofence {
    // Using eight-value hexadecimal color syntax to specify alpha (opacity)
    // https://developer.mozilla.org/en-US/docs/Web/CSS/hex-color
    background-color: rgba(255, 237, 0, 0.5);
    border: 2px solid ${(props): string => props.theme.colors.primary};
  }

  .machine-details-map-legend-popover__item-icon-accuracy {
    // Using eight-value hexadecimal color syntax to specify alpha (opacity)
    // https://developer.mozilla.org/en-US/docs/Web/CSS/hex-color
    background-color: ${(props): string => props.theme.colors.white};
    border: 2px solid ${(props): string => props.theme.colors.darkGrey};
  }

  .machine-details-map-legend-popover__item-heading {
    font-family: ${(props): string => props.theme.fonts.p1Medium};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    font-weight: ${(props): number => props.theme.fontWeights.p1Medium};
  }

  .machine-details-map-legend-popover__item-body {
    font-family: ${(props): string => props.theme.fonts.p1News};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    font-weight: ${(props): number => props.theme.fontWeights.p1News};
  }
`;
