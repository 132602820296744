import { useTranslation } from 'react-i18next';
import { IMachineClassificationItemData } from '../../interfaces/Machine.types';
import { StyledMachineClassificationIndicatorTooltip } from './MachineClassificationIndicatorTooltip.styles';

interface MachineClassificationIndicatorTooltipProps {
  classificationItem: IMachineClassificationItemData;
}
export const MachineClassificationIndicatorTooltip = ({
  classificationItem,
}: MachineClassificationIndicatorTooltipProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledMachineClassificationIndicatorTooltip className="machine-classification-indicator-tooltip">
      {t(classificationItem.title)}
    </StyledMachineClassificationIndicatorTooltip>
  );
};
