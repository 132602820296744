import React, { useRef, useEffect, useState, useMemo } from 'react';
import Chart, { ChartTypeRegistry, TooltipItem } from 'chart.js/auto';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as machineDetailsPanelRobotSelectors from '../../../../state/machineDetailsPanelRobot/machineDetailsPanelRobotSelectors';
import { ChevronBar } from 'lib/components/ChevronBar/ChevronBar';
import { getMonthsByPeriod } from 'lib/utils/date-handling/getTimeByPeriod';
import { Numbers } from 'lib/utils/number-formatting/Numbers';
import { CHART_STYLE, ChartColor, DateFormats } from 'app/modules/machine-inventory/interfaces/Robot.types';

export enum ConsumablesLabel {
  WATER = 'Water',
  DETERGENTS = 'Detergents',
  BATTERY = 'Battery',
}

interface ICleaningValues {
  battery: number;
  detergents: number;
  water: number;
}

interface MachineDetailsRobotsLineBarChartProps {
  period: string[];
  cleaningValues: ICleaningValues[];
  finishDates: string[];
}

const LineBarChart = ({ period, cleaningValues, finishDates }: MachineDetailsRobotsLineBarChartProps): JSX.Element => {
  const { t } = useTranslation();
  const startDate = useSelector(machineDetailsPanelRobotSelectors.selectPeriodStartDate);
  const endDate = useSelector(machineDetailsPanelRobotSelectors.selectPeriodEndDate);

  const titleArray = useMemo(() => getMonthsByPeriod(startDate, endDate), [startDate, endDate]);
  const [titleIndex, setTitleIndex] = useState<number>(0);

  const changeTitleIndex = (i: number): void => {
    setTitleIndex(i);
  };

  const currentPeriod = useMemo(
    () =>
      period.filter(item => {
        const [_, month] = item.split('.');
        return Number(month) === dayjs(titleArray[titleIndex]).month() + 1;
      }),
    [period, titleArray, titleIndex]
  );

  const currentValue = useMemo(() => {
    const result: ICleaningValues[] = [];
    period.map((item, index) => {
      const [_, month] = item.split('.');
      if (Number(month) === dayjs(titleArray[titleIndex]).month() + 1) {
        result.push(cleaningValues[index]);
      }
      return item;
    });
    return result;
  }, [cleaningValues, period, titleArray, titleIndex]);

  const currentFinishedDates = useMemo(
    () => finishDates.filter(item => dayjs(item).month() === dayjs(titleArray[titleIndex]).month()),
    [finishDates, titleArray, titleIndex]
  );

  const chartRef = useRef<HTMLCanvasElement>(null);
  const [chartInstance, setChartInstance] = useState<Chart | null>(null);

  const isUSusers = false;

  const titleCallback = (context: TooltipItem<keyof ChartTypeRegistry>[]): string => {
    let unit;
    //  = context[0].dataset.label === ConsumablesLabel.BATTERY ? t('common.kWhUnit') : t('common.litreUnit');
    if (context[0].dataset.label === ConsumablesLabel.BATTERY) {
      unit = t('common.kWhUnit');
      return `${context[0].raw} ${unit}`;
    }
    if (context[0].dataset.label === ConsumablesLabel.WATER) {
      unit = isUSusers ? t('common.gallonUnit') : t('common.litreUnit');
      return `${context[0].raw} ${unit}`;
    }
    unit = isUSusers ? t('common.fluidOunceUnit') : t('common.litreUnit');
    return `${context[0].raw} ${unit}`;
  };

  const labelCallback = (context: TooltipItem<keyof ChartTypeRegistry>): string =>
    dayjs(currentFinishedDates[context.dataIndex]).format(DateFormats.DATE_IN_MONTH_DAY_YEAR);

  useEffect(() => {
    if (chartInstance) {
      chartInstance.destroy();
    }
    const myChartRef = chartRef.current?.getContext('2d');

    const linePosition = {
      id: 'linePosition',
      beforeDatasetsDraw: (chart: Chart): void => {
        chart.getDatasetMeta(2).data.forEach((dataPoint, index) => {
          // eslint-disable-next-line no-param-reassign
          dataPoint.x = chart.getDatasetMeta(0).data[index].x;
        });
      },
    };

    if (myChartRef) {
      const chart = new Chart(myChartRef, {
        type: 'bar',
        data: {
          labels: currentPeriod,
          datasets: [
            {
              label: t('machineDetails.robotCleaningData.Consumables.water'),
              data: currentValue.map(item => Numbers.formatNumberDecimalRounded(item.water, 1)),
              type: 'bar',
              backgroundColor: ChartColor.CELADON_GREEN,
              order: 1,
              yAxisID: 'litre-axis',
              barThickness: 24,
            },
            {
              label: t('machineDetails.robotCleaningData.Consumables.detergents'),
              data: currentValue.map(item => Numbers.formatNumberDecimalRounded(item.detergents, 1)),
              type: 'bar',
              backgroundColor: ChartColor.LIGHT_SECONDARY,
              order: 1,
              yAxisID: 'litre-axis',
              barThickness: 24,
            },
            {
              label: t('machineDetails.robotCleaningData.Consumables.battery'),
              data: currentValue.map(item => Numbers.formatNumberDecimalRounded(item.battery, 1)),
              backgroundColor: ChartColor.SEMANTIC_SS,
              borderColor: ChartColor.SEMANTIC_SS,
              borderWidth: 4,
              pointRadius: 1,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: ChartColor.WHITE,
              fill: false,
              type: 'line',
              order: 0,
              yAxisID: 'kWh-axis',
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            x: {
              display: true,
              grid: {
                display: false,
              },
              ticks: {
                autoSkip: false,
                count: 1,
                stepSize: 1,
                color: ChartColor.BLACK,
                font: CHART_STYLE.X_TICKS_FONT,
              },
            },
            'kWh-axis': {
              position: 'right',
              type: 'linear',
              grid: {
                display: false,
              },
              ticks: {
                count: 5,
                stepSize: 0.25,
                format: {
                  maximumFractionDigits: 2,
                },
                color: ChartColor.GREY,
                font: CHART_STYLE.Y_TICKS_FONT,
              },
              border: {
                display: false,
              },
            },
            'litre-axis': {
              position: 'left',
              type: 'linear',
              ticks: {
                count: 5,
                stepSize: 5,
                color: ChartColor.GREY,
                font: CHART_STYLE.Y_TICKS_FONT,
              },
              border: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              backgroundColor: ChartColor.WHITE,
              titleColor: ChartColor.BLACK,
              bodyColor: ChartColor.BLACK,
              cornerRadius: 0,
              displayColors: false,
              padding: 8,
              titleFont: CHART_STYLE.TITLE_FONT,
              bodyFont: CHART_STYLE.BODY_FONT,
              callbacks: {
                title: titleCallback,
                label: labelCallback,
              },
              borderColor: ChartColor.BLACK,
              borderWidth: 1,
            },
          },
        },
        plugins: [linePosition],
      });

      setChartInstance(chart);
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  return (
    <div className="chart-wrapper">
      <canvas className="mixed-chart" ref={chartRef} />
      <ChevronBar
        startDate={startDate}
        endDate={endDate}
        titleArray={titleArray}
        titleIndex={titleIndex}
        changeTitleIndex={changeTitleIndex}
      />
    </div>
  );
};

export default LineBarChart;
