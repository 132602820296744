import styled from 'styled-components';

export const StyledMachineRobotConnectionStatusIndicator = styled.div`
  &.robot-connection-status-indicator__wrapper {
    display: block;

    .robot-connection-status-indicator__indicator-container {
      display: inline-block;
    }

    .robot-connection-status-indicator__indicator {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 4px;
      background-color: ${(props): string => props.theme.colors.semanticSuccess};

      &.offline-status,
      &.unknown-status {
        background-color: ${(props): string => props.theme.colors.darkGrey};
      }
    }

    .robot-connection-status-indicator__text {
      margin-top: 2px;
      width: auto;
      word-break: break-word;
      white-space: normal;
      ${(props): string => props.theme.fontStyles.redesign.sm.normal};
      font-size: 10px;
    }
  }
`;
