import styled from 'styled-components';

export const StyledMachineAttachmentList = styled.div`
  font-family: ${(props): string => props.theme.fonts.p1News};
  font-size: ${(props): string => props.theme.fontSizes.p1};
  line-height: ${(props): string => props.theme.lineHeights.p1};
  font-weight: ${(props): number => props.theme.fontWeights.p1News};
  color: ${(props): string => props.theme.colors.black};
  border: 1px solid ${(props): string => props.theme.colors.brightGrey};
  padding: ${(props): string => props.theme.spacing.lg};

  .machine-attachment-list__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 9px;
  }

  .machine-attachment-list__title {
    flex: 1;
    ${(props): string => props.theme.fontStyles.redesign.xl.strong};
    color: ${(props): string => props.theme.colors.black};
    margin-bottom: 0;
  }

  .machine-attachment-list__buttons {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    column-gap: 5px;
  }

  .machine-attachment-list__button {
    padding: 0;
  }

  .machine-attachment-list__create-button {
    width: 18px;
    height: 18px;
    color: ${(props): string => props.theme.colors.black};
  }

  .machine-attachment-list__body {
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-height: 368px;
    overflow-y: auto;
  }

  .machine-attachment-list__body--empty {
    justify-content: center;
    align-items: center;
  }

  .machine-attachment-list__load-indicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .machine-attachment-list__empty-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 14px;
    color: ${(props): string => props.theme.colors.darkGrey};
  }

  .ant-empty {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .ant-empty-image,
  .ant-empty-image svg {
    width: 184px;
    height: 113px;
  }

  .machine-attachment-list__content {
    // TODO: implement
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .machine-attachment-list__button-bar {
    // TODO: implement
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 0 16px 0;
    flex-grow: 0;
  }

  .machine-attachment-list__button {
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    ${(props): string => props.theme.fontStyles.caption.bold}
  }

  .machine-attachment-list__sort-direction-indicator {
    margin-left: 4px;
  }

  .machine-attachment-list__footer {
    // TODO: implement
    display: flex;
    width: 100%;
    flex-basis: 56px;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
  }

  .machine-attachment-list__pagination {
    width: 100%;
    height: 18px;
  }

  .machine-attachment-list__attachments {
    display: flex;
    width: 100%;
    flex-grow: 1;
    flex-direction: row;
    justify-content: start;
    align-content: start;
    flex-wrap: wrap;
    gap: 24px;
    height: 100%;

    // TODO
    overflow: hidden;
  }

  &.machine-attachment-list--size-small {
    width: 491px;
  }

  &.machine-attachment-list--size-medium {
    width: 680px;
  }

  &.machine-attachment-list--size-large {
    width: 1008px;
  }

  &.machine-attachment-list--size-full-width {
    width: 100%;
  }
`;
