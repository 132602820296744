import styled from 'styled-components';

export const StyledMachineCleaningData = styled.div`
  color: ${(props): string => props.theme.colors.black};

  .machine-cleaning-data__title {
    ${(props): string => props.theme.fontStyles.redesign.xl.strong};
    padding: 0 0 11px 0px;
    margin-bottom: 0;
  }

  .machine-cleaning-data__content {
    border: 1px solid ${(props): string => props.theme.colors.brightGrey};
    padding: 25px;
  }

  .machine-cleaning-data__header {
    display: flex;
    .machine-operating-hours-chart__sums {
      display: flex;
      padding-left: 50px;
      .machine-operating-hours-chart__operating-label {
        display: flex;
        text-transform: uppercase;
        font-family: ${(props): string => props.theme.fonts.p1Bold};
        font-size: ${(props): string => props.theme.fontSizes.caption};
        font-weight: ${(props): number => props.theme.fontWeights.p0};
        line-height: ${(props): string => props.theme.lineHeights.caption};
      }
      .machine-operating-hours-chart__operating-value {
        font-family: ${(props): string => props.theme.fonts.heading1};
        font-size: ${(props): string => props.theme.fontSizes.heading1};
        font-weight: ${(props): number => props.theme.fontWeights.heading1};
        line-height: ${(props): string => props.theme.lineHeights.heading1};
        margin-bottom: 0;
      }
      .machine-operating-hours-chart__total-operating-label {
        margin: 0 0 0 40px;
        opacity: 0.5;
      }
    }
  }

  .machine-cleaning-data__loading-indicator {
    min-height: 421px;
  }

  .machine-cleaning-data__tabs {
    margin-left: auto;

    .ant-tabs-content-holder {
      display: none;
    }
  }
`;
