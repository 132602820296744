import { ApolloQueryResult, FetchResult, gql } from '@apollo/client';
import { inject, injectable } from 'tsyringe';
import {
  MachineAttachmentCheckNameResponse,
  MachineAttachmentDeleteResponse,
  MachineAttachmentDownloadResponse,
  MachineAttachmentGetUploadLinkResponse,
} from './interfaces/MachineAttachment.types';
import { GraphQlClient } from 'app/cross-cutting-concerns/communication/clients/GraphQlClient';
import type { RestClient } from 'app/cross-cutting-concerns/communication/clients/RestClientFactory';

export interface MachineAttachmentClientDeleteOptions {
  machineId: string;
  attachmentId: string;
}

export interface MachineAttachmentClientGetUploadLinkOptions {
  machineId: string;
  attachmentName: string;
  mimeType: string;
}

export interface MachineAttachmentClientUploadByUrlOptions {
  url: string;
  formData: FormData;
}

export interface MachineAttachmentClientDownloadOptions {
  machineId: string;
  attachmentId: string;
}

export interface MachineAttachmentClientCheckNameOptions {
  machineId: string;
  attachmentName: string;
}

@injectable()
export class MachineAttachmentClient {
  constructor(
    @inject('GraphQlClient') private graphQlClient: GraphQlClient,
    @inject('RestClient') private restClient: RestClient
  ) {}

  public delete = async ({
    machineId,
    attachmentId,
  }: MachineAttachmentClientDeleteOptions): Promise<FetchResult<MachineAttachmentDeleteResponse>> =>
    this.graphQlClient.mutate({
      mutation: gql`
        mutation MachineAttachmentDelete($machineId: ID!, $attachmentId: ID!) {
          machineAttachmentDelete(machineId: $machineId, attachmentId: $attachmentId) {
            data
          }
        }
      `,
      variables: {
        machineId,
        attachmentId,
      },
    });

  public getUploadLink = async ({
    machineId,
    attachmentName,
    mimeType,
  }: MachineAttachmentClientGetUploadLinkOptions): Promise<ApolloQueryResult<MachineAttachmentGetUploadLinkResponse>> =>
    this.graphQlClient.query({
      query: gql`
        query AttachmentUploadLinkGet(
          $machineId: ID!
          $attachmentName: String!
          $mimeType: String!
          $isOverride: Boolean
        ) {
          attachmentUploadLinkGet(
            machineId: $machineId
            attachmentName: $attachmentName
            mimeType: $mimeType
            isOverride: $isOverride
          ) {
            data {
              url
              fields {
                value
                name
              }
            }
          }
        }
      `,
      variables: {
        machineId,
        attachmentName,
        mimeType,
        isOverride: true,
      },
    });

  public uploadByUrl = async ({ url, formData }: MachineAttachmentClientUploadByUrlOptions): Promise<void> => {
    await this.restClient.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  public download = async ({
    machineId,
    attachmentId,
  }: MachineAttachmentClientDownloadOptions): Promise<ApolloQueryResult<MachineAttachmentDownloadResponse>> =>
    this.graphQlClient.query({
      query: gql`
        query AttachmentDownloadLinkGet($machineId: ID!, $attachmentId: String!) {
          attachmentDownloadLinkGet(machineId: $machineId, attachmentId: $attachmentId) {
            data
          }
        }
      `,
      variables: {
        machineId,
        attachmentId,
      },
    });

  public checkName = async ({
    machineId,
    attachmentName,
  }: MachineAttachmentClientCheckNameOptions): Promise<ApolloQueryResult<MachineAttachmentCheckNameResponse>> =>
    this.graphQlClient.query({
      query: gql`
        query AttachmentNameCheck($machineId: ID!, $attachmentName: String!) {
          attachmentNameCheck(machineId: $machineId, attachmentName: $attachmentName) {
            data {
              exists
            }
          }
        }
      `,
      variables: {
        machineId,
        attachmentName,
      },
    });
}
