import classnames from 'classnames';
import React, { useMemo } from 'react';
import { IMachineCategoryItemData, MachineCategory, MachineCategoryItemData } from '../../interfaces/Machine.types';
import { StyledMachineCategoryIndicator } from './MachineCategoryIndicator.styles';
import { MachineCategoryIndicatorTooltip } from './MachineCategoryIndicatorTooltip';
import { MachineCategoryIndicatorGlobalStyles } from './MachineCategoryIndicatorGlobalStyles';
import { Tooltip } from 'lib/components/Tooltip/Tooltip';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';

export interface MachineCategoryIndicatorProps {
  className?: string;
  category?: MachineCategory | null;
}

export const MachineCategoryIndicator = ({
  className,
  category,
}: MachineCategoryIndicatorProps): React.JSX.Element | null => {
  const effectiveCategory = useMemo(() => {
    if (!category) return undefined;
    if (category === MachineCategory.Robot) return MachineCategory.Robot;

    return MachineCategory.Gcd;
  }, [category]);

  if (!effectiveCategory) {
    return null;
  }

  const categoryItem: IMachineCategoryItemData = MachineCategoryItemData[effectiveCategory];

  return (
    <StyledMachineCategoryIndicator className={classnames(className, 'machine-category-indicator')}>
      <MachineCategoryIndicatorGlobalStyles />
      <Tooltip
        title={<MachineCategoryIndicatorTooltip categoryItem={categoryItem} />}
        overlayClassName="machine-category-indicator-tooltip__overlay"
        arrow={{ pointAtCenter: true }}
      >
        <div className="machine-category-indicator__tooltip-wrapper">
          <SvgIcon
            name={categoryItem.icon}
            className={classnames(
              'machine-category-indicator__icon',
              // eslint-disable-next-line max-len
              `machine-category-indicator__icon--classification-${categoryItem.category.toLowerCase()}`
            )}
          />
        </div>
      </Tooltip>
    </StyledMachineCategoryIndicator>
  );
};
