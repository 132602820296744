import styled from 'styled-components';
import { Card } from 'lib/components/Card/Card';

export const StyledMachineDetailsInfo = styled(Card)`
  font-family: ${(props): string => props.theme.fonts.p1News};
  font-size: ${(props): string => props.theme.fontSizes.p1};
  line-height: ${(props): string => props.theme.lineHeights.p1};
  font-weight: ${(props): number => props.theme.fontWeights.p1News};
  color: ${(props): string => props.theme.colors.black};

  .ant-card-body {
    min-height: 293px;
  }

  .machine-details-info__sub-title {
    ${(props): string => props.theme.fontStyles.caption.bold}
    color: ${(props): string => props.theme.colors.black};
    text-transform: uppercase;
    padding-right: 0.5em;
  }

  .machine-details-info__block {
    min-height: 43.2px;
    margin-top: 20px;
  }

  .machine-details-info__info-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${(props): string => props.theme.spacing.md};
  }

  .machine-details-info__sub-header {
    ${(props): string => props.theme.fontStyles.redesign.xl.strong};
    font-weight: 700;
    margin: 0;
  }

  .machine-details-info__button--no-styling {
    height: unset;
    font-size: unset;
    line-height: unset;
    font-weight: unset;
    padding: unset;
    font-family: unset;
  }

  .machine-details-info__action {
    width: 18px;
    height: 18px;
  }
`;
