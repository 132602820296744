import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StyledMachineDetailsRobotConsumablesChartHeader } from './MachineDetailsRobotConsumablesChartHeader.styles';
import * as machineDetailsRobotSelectors from 'app/modules/machine-inventory/machine-details/state/machineDetailsRobotSelectors';
import { Numbers } from 'lib/utils/number-formatting/Numbers';
import { ConvertUnitUtils } from 'app/utils/convert-unit/ConvertUnitUtils';

export const MachineDetailsRobotConsumablesChartHeader = (): JSX.Element => {
  const { t } = useTranslation();

  const cleaningTaskMetadata = useSelector(machineDetailsRobotSelectors.selectCleaningDataConsumablesPeriod);
  const isUSusers = false;

  return (
    <StyledMachineDetailsRobotConsumablesChartHeader>
      <div className="robot-consumables-chart__chart-info">
        <div className="robot-consumables-chart__chart-info-left">
          <div className="robot-consumables-chart__chart-info-title">
            {t('machineDetails.robotCleaningData.Consumables.periodBattery')}
          </div>
          <div className="robot-consumables-chart__chart-info-content">
            {Numbers.formatNumberDecimalRounded(cleaningTaskMetadata?.periodBattery, 1)}&nbsp;
            <span className="robot-consumables-chart__chart-info-unit">{t('common.kWhUnit')}</span>
          </div>
        </div>
        {isUSusers ? (
          <>
            <div className="robot-consumables-chart__chart-info-between">
              <div className="robot-consumables-chart__chart-info-title">
                {t('machineDetails.robotCleaningData.Consumables.periodWater')}
              </div>
              <div className="robot-consumables-chart__chart-info-content">
                {ConvertUnitUtils.convertLitersToGallons(cleaningTaskMetadata?.periodWater || 0)}&nbsp;
                <span className="robot-consumables-chart__chart-info-unit">{t('common.gallonUnit')}</span>
              </div>
            </div>
            <div className="robot-consumables-chart__chart-info-right">
              <div className="robot-consumables-chart__chart-info-title">
                {t('machineDetails.robotCleaningData.Consumables.periodDetergents')}
              </div>
              <div className="robot-consumables-chart__chart-info-content">
                {ConvertUnitUtils.convertLiterToFluidOunces(cleaningTaskMetadata?.periodDetergents || 0)}&nbsp;
                <span className="robot-consumables-chart__chart-info-unit">{t('common.fluidOunceUnit')}</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="robot-consumables-chart__chart-info-between">
              <div className="robot-consumables-chart__chart-info-title">
                {t('machineDetails.robotCleaningData.Consumables.periodWater')}
              </div>
              <div className="robot-consumables-chart__chart-info-content">
                {Numbers.formatNumberDecimalRounded(cleaningTaskMetadata?.periodWater, 1)}&nbsp;
                <span className="robot-consumables-chart__chart-info-unit">{t('common.litreUnit')}</span>
              </div>
            </div>
            <div className="robot-consumables-chart__chart-info-right">
              <div className="robot-consumables-chart__chart-info-title">
                {t('machineDetails.robotCleaningData.Consumables.periodDetergents')}
              </div>
              <div className="robot-consumables-chart__chart-info-content">
                {Numbers.formatNumberDecimalRounded(cleaningTaskMetadata?.periodDetergents, 1)}&nbsp;
                <span className="robot-consumables-chart__chart-info-unit">{t('common.litreUnit')}</span>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="robot-consumables-chart__chart-note">
        <div className="robot-consumables-chart__chart-note-left">
          <div className="robot-consumables-chart__chart-note-square-left" />
          <p className="robot-consumables-chart__chart-note-text">
            <span>{t('machineDetails.robotCleaningData.Consumables.battery')}</span>
          </p>
        </div>
        <div className="robot-consumables-chart__chart-note-between">
          <div className="robot-consumables-chart__chart-note-square-between" />
          <p className="robot-consumables-chart__chart-note-text">
            <span>{t('machineDetails.robotCleaningData.Consumables.water')}</span>
          </p>
        </div>
        <div className="robot-consumables-chart__chart-note-right">
          <div className="robot-consumables-chart__chart-note-square-right" />
          <p className="robot-consumables-chart__chart-note-text">
            <span>{t('machineDetails.robotCleaningData.Consumables.detergents')}</span>
          </p>
        </div>
      </div>
    </StyledMachineDetailsRobotConsumablesChartHeader>
  );
};
