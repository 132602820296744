import classnames from 'classnames';
import React, { useMemo } from 'react';
import isNil from 'lodash-es/isNil';
import { Optional } from '../../../../../lib/types/Optional';
import { IMachineClassificationItemData, MachineClassificationItemData } from '../../interfaces/Machine.types';
import { StyledMachineClassificationIndicator } from './MachineClassificationIndicator.styles';
import { MachineClassificationIndicatorTooltip } from './MachineClassificationIndicatorTooltip';
import { MachineClassificationIndicatorGlobalStyles } from './MachineClassificationIndicator.global.styles';
import { Tooltip } from 'lib/components/Tooltip/Tooltip';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';

export interface MachineClassificationIndicatorProps {
  className?: string;
  isIoTDevice: Optional<boolean>;
}

export const MachineClassificationIndicator = ({
  className,
  isIoTDevice,
}: MachineClassificationIndicatorProps): React.JSX.Element | null => {
  const effectiveClassification = useMemo(() => {
    if (isNil(isIoTDevice)) return undefined;
    if (isIoTDevice) return 'IOT';

    return 'NON_IOT';
  }, [isIoTDevice]);

  if (!effectiveClassification) {
    return null;
  }

  const classificationItem: IMachineClassificationItemData = MachineClassificationItemData[effectiveClassification];

  return (
    <StyledMachineClassificationIndicator className={classnames(className, 'machine-classification-indicator')}>
      <MachineClassificationIndicatorGlobalStyles />
      <Tooltip
        title={<MachineClassificationIndicatorTooltip classificationItem={classificationItem} />}
        overlayClassName="machine-classification-indicator-tooltip__overlay"
      >
        <div className="machine-classification-indicator__tooltip-wrapper">
          <SvgIcon
            name={classificationItem.icon}
            className={classnames(
              'machine-classification-indicator__icon',
              // eslint-disable-next-line max-len
              `machine-classification-indicator__icon--classification-${classificationItem.classification.toLowerCase()}`
            )}
          />
        </div>
      </Tooltip>
    </StyledMachineClassificationIndicator>
  );
};
