import { CallEffect, ForkEffect, GetContextEffect, PutEffect, SelectEffect, TakeEffect } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getContext,
  call,
  put,
  takeLatest,
  take,
  throttle,
  cancel,
  takeEvery,
  fork,
  retry,
  SagaGenerator,
} from 'typed-redux-saga';
import { EventChannel } from 'redux-saga';
import { isNil } from 'lodash-es';
import {
  RobotCleaningTaskCleanedAreaByDayListResponse,
  RobotCleaningTaskCleanedHrsByDayListResponse,
  RobotCleaningTaskConsumablesByDayListResponse,
  RobotCleaningTaskDistanceDrivenByDayListResponse,
  RobotDashboardTasksCompletionStatisticResponse,
  RoutesNameListResponse,
} from '../../../interfaces/Robot.types';
import { SubscriptionMachineUpdateResult } from '../../../interfaces/Machine.types';
import {
  MachineUpdateConnectionStatus,
  MachineUpdateRobotStatus,
  MachineUpdateTelemetry,
  SubscriptionMachineEvent,
} from '../../../interfaces/MachineSubscription.types';
import {
  MachineDetailsPanelActions,
  MachineDetailsRobotRealTimePropertyChangedAction,
} from '../machineDetailsPanelActions';
import { MachineDetailsPanelRobotActions, SubscribeToMachineUpdateAction } from './machineDetailsPanelRobotSlice';
import {
  MachineDetailsRobotListCleaningDataCleanedAreaRequestAction,
  MachineDetailsRobotListCleaningDataCleanedAreaSuccessAction,
  MachineDetailsRobotListCleaningDataCleanedHrsRequestAction,
  MachineDetailsRobotListCleaningDataCleanedHrsSuccessAction,
  MachineDetailsRobotListConsumablesRequestAction,
  MachineDetailsRobotListConsumablesSuccessAction,
  MachineDetailsRobotListCleaningDataDistanceDrivenRequestAction,
  MachineDetailsRobotListCleaningDataDistanceDrivenSuccessAction,
  MachineDetailsRobotListRoutesNameRequestAction,
  MachineDetailsRobotListRoutesNameSuccessAction,
  MachineDetailsRobotCleaningConsumptionSummaryErrorAction,
  MachineDetailsRobotCleaningConsumptionSummarySuccessAction,
  MachineDetailsRobotCleaningConsumptionSummaryRequestAction,
  MachineDetailsRobotCleaningKPIsErrorAction,
  MachineDetailsRobotCleaningKPIsRequestAction,
  MachineDetailsRobotCleaningKPIsSuccessAction,
  MachineDetailsRobotTaskCompletedHistoryErrorAction,
  MachineDetailsRobotTaskCompletedHistoryRequestAction,
  MachineDetailsRobotTaskCompletedHistorySuccessAction,
  MachineDetailsRobotCleaningTaskReportErrorAction,
  MachineDetailsRobotCleaningTaskReportRequestAction,
  MachineDetailsRobotCleaningTaskReportRouteImageErrorAction,
  MachineDetailsRobotCleaningTaskReportRouteImageRequestAction,
  MachineDetailsRobotCleaningTaskReportRouteImageSuccessAction,
  MachineDetailsRobotCleaningTaskReportSuccessAction,
  MachineDetailsRobotPollGetExportCtrPdfRequestAction,
  MachineDetailsRobotPollGetExportCtrPdfSuccessAction,
  MachineDetailsRobotPollGetExportCtrPdfErrorAction,
  MachineDetailsRobotExportCtrPdfRequestAction,
  MachineDetailsRobotExportCtrPdfSuccessAction,
  MachineDetailsRobotExportCtrPdfErrorAction,
} from './machineDetailsPanelRobotActions.types';
import { IActionCreatorErrorOptions } from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { Optional } from 'lib/types/Optional';
import { SagaUtils } from 'app/cross-cutting-concerns/state-management/utils/SagaUtils';
import { GraphQlSubscriptionMessage } from 'app/cross-cutting-concerns/communication/interfaces/graphql.types';
import { RobotUtils } from 'app/utils/robot/RobotUtils';
import {
  AsyncJobStatus,
  MachineConnectionStatus,
  MachineUpdate,
  RobotStatus,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { CleaningTaskReportListResponse } from 'app/modules/cleaning/interfaces/CleaningTaskReport.types';
import {
  CleaningReportsExportGetFile,
  CleaningReportsExportRobotDetailsRequest,
} from 'app/modules/cleaning/interfaces/CleaningReport.types';
import { CleaningConstants } from 'app/modules/cleaning/CleaningConstants';

export function* getRoutesNameListSaga(
  action: MachineDetailsRobotListRoutesNameRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<RoutesNameListResponse>>
  | PutEffect<MachineDetailsRobotListRoutesNameSuccessAction>
  | PutEffect<PayloadAction<IActionCreatorErrorOptions>>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.listRoutesName, action.payload);
    yield* put(MachineDetailsPanelRobotActions.machineDetailsRobotGetRoutesNameListSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(MachineDetailsPanelRobotActions.machineDetailsRobotGetRoutesNameListError({ error }));
  }
}

export function* getCleaningTaskCleanedAreaByDayListSaga(
  action: MachineDetailsRobotListCleaningDataCleanedAreaRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<RobotCleaningTaskCleanedAreaByDayListResponse>>
  | PutEffect<MachineDetailsRobotListCleaningDataCleanedAreaSuccessAction>
  | PutEffect<PayloadAction<IActionCreatorErrorOptions>>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.getCleaningTaskCleanedAreaByDayList, action.payload);

    yield* put(MachineDetailsPanelRobotActions.machineDetailsRobotListCleaningDataCleanedAreaSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(MachineDetailsPanelRobotActions.machineDetailsRobotListCleaningDataCleanedAreaError({ error }));
  }
}

export function* getCleaningTaskCleanedHrsByDayListSaga(
  action: MachineDetailsRobotListCleaningDataCleanedHrsRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<RobotCleaningTaskCleanedHrsByDayListResponse>>
  | PutEffect<MachineDetailsRobotListCleaningDataCleanedHrsSuccessAction>
  | PutEffect<PayloadAction<IActionCreatorErrorOptions>>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.getCleaningTaskCleanedHrsByDayList, action.payload);
    yield* put(MachineDetailsPanelRobotActions.machineDetailsRobotListCleaningDataCleanedHrsSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(MachineDetailsPanelRobotActions.machineDetailsRobotListCleaningDataCleanedHrsError({ error }));
  }
}

export function* getCleaningTaskConsumablesByDayListSaga(
  action: MachineDetailsRobotListConsumablesRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<RobotCleaningTaskConsumablesByDayListResponse>>
  | PutEffect<MachineDetailsRobotListConsumablesSuccessAction>
  | PutEffect<PayloadAction<IActionCreatorErrorOptions>>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.getCleaningTaskConsumablesByDayList, action.payload);
    yield* put(MachineDetailsPanelRobotActions.machineDetailsRobotListCleaningDataConsumablesSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(MachineDetailsPanelRobotActions.machineDetailsRobotListCleaningDataConsumablesError({ error }));
  }
}

export function* getCleaningTaskDistanceDrivenByDayListSaga(
  action: MachineDetailsRobotListCleaningDataDistanceDrivenRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<RobotCleaningTaskDistanceDrivenByDayListResponse>>
  | PutEffect<MachineDetailsRobotListCleaningDataDistanceDrivenSuccessAction>
  | PutEffect<PayloadAction<IActionCreatorErrorOptions>>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.getCleaningTaskDistanceDrivenByDayList, action.payload);
    yield* put(MachineDetailsPanelRobotActions.machineDetailsRobotListCleaningDataDistanceDrivenSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(MachineDetailsPanelRobotActions.machineDetailsRobotListCleaningDataConsumablesError({ error }));
  }
}

export function* onMachineTelemetryChanged(machineUpdated: MachineUpdate): Generator<PutEffect> {
  const machineUpdatedData = RobotUtils.getMachineUpdatedData(machineUpdated?.data) as Record<string, string>;

  const machineUpdatedStatusData: MachineUpdateTelemetry = {
    ...machineUpdated,
    data: machineUpdatedData,
  };

  yield* put(
    MachineDetailsPanelActions.robotByStatusRealTimePropertyChanged({ updatedData: machineUpdatedStatusData })
  );
}

export function* onMachineStatusChanged(machineUpdated: MachineUpdate): Generator<PutEffect> {
  const machineUpdatedData = RobotUtils.getMachineUpdatedData(machineUpdated?.data) as {
    robotStatus: RobotStatus;
  };

  const machineUpdatedStatusData: MachineUpdateRobotStatus = {
    ...machineUpdated,
    data: machineUpdatedData,
  };

  yield* put(
    MachineDetailsPanelActions.robotByStatusRealTimePropertyChanged({ updatedData: machineUpdatedStatusData })
  );
}

export function* onMachineConnectionStatusChanged(machineUpdated: MachineUpdate): Generator<PutEffect> {
  const machineUpdatedData = RobotUtils.getMachineUpdatedData(machineUpdated?.data) as {
    connectionStatus: MachineConnectionStatus;
  };

  const machineUpdatedStatusData: MachineUpdateConnectionStatus = {
    ...machineUpdated,
    data: machineUpdatedData,
  };
  yield* put(
    MachineDetailsPanelActions.robotByStatusRealTimePropertyChanged({ updatedData: machineUpdatedStatusData })
  );
}

export function* onMachineRoutineDataCHanged(machineUpdated: MachineUpdate): Generator<PutEffect> {
  yield* put(MachineDetailsPanelActions.robotByRoutineRealTimePropertyChanged({ updatedData: machineUpdated }));
}

export function* onEveryMachineUpdateSaga(
  message: GraphQlSubscriptionMessage<SubscriptionMachineUpdateResult>
): Generator<
  GetContextEffect | PutEffect<MachineDetailsRobotRealTimePropertyChangedAction> | SelectEffect | ForkEffect
> {
  const machineUpdated = message.value.data.onMachineUpdate;
  const event = machineUpdated?.event;

  try {
    if (!isNil(event)) {
      switch (event) {
        case SubscriptionMachineEvent.TELEMETRY_CHANGED:
          yield* fork(onMachineTelemetryChanged, machineUpdated);
          break;

        case SubscriptionMachineEvent.MACHINE_STATUS_CHANGED:
          yield* fork(onMachineStatusChanged, machineUpdated);
          break;

        case SubscriptionMachineEvent.MACHINE_CONNECTION_STATUS_CHANGED:
          yield* fork(onMachineConnectionStatusChanged, machineUpdated);
          break;

        case SubscriptionMachineEvent.MACHINE_ROUTINE_CHANGED:
          yield* fork(onMachineRoutineDataCHanged, machineUpdated);
          break;

        default:
          break;
      }
    }
  } catch (error) {
    console.error('machineId', message.value.data.onMachineUpdate.machineId);
    console.error(error);
  }
}

export function* subscribeToMachineUpdate(
  subscribeAction: SubscribeToMachineUpdateAction
): Generator<
  | GetContextEffect
  | CallEffect<EventChannel<GraphQlSubscriptionMessage<SubscriptionMachineUpdateResult>>>
  | ForkEffect<never>
  | TakeEffect
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const { observable, throttleDuration } = machineService.subscribeToMachineUpdate({
      customerId: subscribeAction.payload.customerId,
      machineId: subscribeAction.payload.machineId,
    });

    const channel = yield* call(
      SagaUtils.createChannelFromObservable<GraphQlSubscriptionMessage<SubscriptionMachineUpdateResult>>,
      observable
    );

    yield* throttle(throttleDuration, channel, onEveryMachineUpdateSaga);

    while (true) {
      const unsubscribeAction = yield* take(MachineDetailsPanelRobotActions.unsubscribeFromMachineUpdate);
      if (
        unsubscribeAction.payload.customerId === subscribeAction.payload.customerId &&
        unsubscribeAction.payload.machineId === subscribeAction.payload.machineId
      ) {
        channel.close();
        cancel();
      }
    }
  } catch (err) {
    console.error('Error while subscribing/unsubscribing to subscription:', err);
  }
}

export function* getRobotCleaningConsumptionSummarySaga(
  action: MachineDetailsRobotCleaningConsumptionSummaryRequestAction
): Generator<
  | GetContextEffect
  | CallEffect
  | PutEffect<MachineDetailsRobotCleaningConsumptionSummarySuccessAction>
  | PutEffect<MachineDetailsRobotCleaningConsumptionSummaryErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.getMachineRobotConsumptionSummary, action.payload);
    yield* put(MachineDetailsPanelRobotActions.machineDetailsRobotCleaningConsumptionSummarySuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(
      MachineDetailsPanelRobotActions.machineDetailsRobotCleaningConsumptionSummaryError({
        error,
      })
    );
  }
}

export function* getTasksCompletionHistorySaga(
  action: MachineDetailsRobotTaskCompletedHistoryRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<RobotDashboardTasksCompletionStatisticResponse>
  | PutEffect<MachineDetailsRobotTaskCompletedHistorySuccessAction>
  | PutEffect<MachineDetailsRobotTaskCompletedHistoryErrorAction>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.getRobotDashboardTasksCompletion, action.payload);
    yield* put(MachineDetailsPanelRobotActions.machineDetailsRobotTaskCompletionHistorySuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      MachineDetailsPanelRobotActions.machineDetailsRobotTaskCompletionHistoryError({
        error,
      })
    );
  }
}

export function* getRobotCleaningKPIsSaga(
  action: MachineDetailsRobotCleaningKPIsRequestAction
): Generator<
  | GetContextEffect
  | CallEffect
  | PutEffect<MachineDetailsRobotCleaningKPIsSuccessAction>
  | PutEffect<MachineDetailsRobotCleaningKPIsErrorAction>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.robotsKPIs, action.payload);
    yield* put(MachineDetailsPanelRobotActions.machineDetailsRobotCleaningKPIsSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(
      MachineDetailsPanelRobotActions.machineDetailsRobotCleaningKPIsError({
        error,
      })
    );
  }
}

export function* getRobotCTRListSaga(
  action: MachineDetailsRobotCleaningTaskReportRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<CleaningTaskReportListResponse>
  | PutEffect<MachineDetailsRobotCleaningTaskReportSuccessAction>
  | PutEffect<MachineDetailsRobotCleaningTaskReportErrorAction>,
  void,
  IDependencies
> {
  const { cleaningTaskReportService } = yield* getContext<IDependencies>('dependencies');

  try {
    const { append, ...requestOptions } = action.payload;
    const response = yield* call(cleaningTaskReportService.list, requestOptions);

    const successActionPayload = { ...response, append };
    yield* put(MachineDetailsPanelRobotActions.machineDetailsRobotCleaningTaskReportsSuccess(successActionPayload));
  } catch (error) {
    console.error(error);
    yield* put(
      MachineDetailsPanelRobotActions.machineDetailsRobotCleaningTaskReportsError({
        error,
      })
    );
  }
}

export function* getCleaningReportRobotRouteImageSaga(
  action: MachineDetailsRobotCleaningTaskReportRouteImageRequestAction
): Generator<
  | GetContextEffect
  | CallEffect
  | PutEffect<MachineDetailsRobotCleaningTaskReportRouteImageSuccessAction>
  | PutEffect<MachineDetailsRobotCleaningTaskReportRouteImageErrorAction>,
  void,
  IDependencies
> {
  const { cleaningTaskReportService } = yield* getContext<IDependencies>('dependencies');

  try {
    const {
      cleaningTaskReportRobotRouteImage: { data: robotRouteImageUrl },
    } = yield* call(cleaningTaskReportService.getRobotRouteImageUrl, action.payload);

    const robotRouteImageBlob = yield* call(cleaningTaskReportService.getRobotRouteImageData, {
      robotRouteImageUrl,
    });

    const imageObjectUrl = URL.createObjectURL(robotRouteImageBlob);

    yield* put(
      MachineDetailsPanelRobotActions.machineDetailsRobotCleaningTaskReportRouteImageSuccess({
        url: robotRouteImageUrl,
        imageObjectUrl,
      })
    );
  } catch (error) {
    console.error(error);
    yield* put(
      MachineDetailsPanelRobotActions.machineDetailsRobotCleaningTaskReportRouteImageError({
        error,
      })
    );
  }
}

export function* pollGetMachineRobotDetailsCtrPdfSaga(
  action: MachineDetailsRobotPollGetExportCtrPdfRequestAction
): Generator<
  | GetContextEffect
  | SagaGenerator<CleaningReportsExportGetFile, CallEffect<CleaningReportsExportGetFile>>
  | PutEffect<MachineDetailsRobotPollGetExportCtrPdfSuccessAction>
  | PutEffect<MachineDetailsRobotPollGetExportCtrPdfErrorAction>,
  void,
  any
> {
  const { cleaningReportService } = yield* getContext<IDependencies>('dependencies');
  const getExportCleaningReportsFile = async (): Promise<CleaningReportsExportGetFile> => {
    const getFileResponse = await cleaningReportService.getExportCleaningReportsFile(action.payload);
    const { status, presignedUrl } = getFileResponse?.cleaningReportsExportGetFile.data || {};
    if (status !== AsyncJobStatus.Done) {
      throw new Error('Download link is not ready yet!');
    }

    if (!presignedUrl) {
      throw new Error('Download link is ready but no presignedUrl!');
    }

    return getFileResponse;
  };

  try {
    const response: CleaningReportsExportGetFile = yield retry(
      CleaningConstants.CLEANING_REPORT_EXPORT_POLL_MAX_RETRIES,
      CleaningConstants.CLEANING_REPORT_EXPORT_POLL_INTERVAL,
      getExportCleaningReportsFile
    );

    yield* put(MachineDetailsPanelRobotActions.machineDetailsRobotPollGetExportCtrPdfSuccessAction(response));
  } catch (error) {
    console.error(error);

    yield* put(
      MachineDetailsPanelRobotActions.machineDetailsRobotPollGetExportCtrPdfErrorAction({
        error,
      })
    );
  }
}

export function* requestExportMachineRobotDetailsCtrPdfSaga(
  action: MachineDetailsRobotExportCtrPdfRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | CallEffect<Optional<CleaningReportsExportRobotDetailsRequest> | void>
  | PutEffect<MachineDetailsRobotExportCtrPdfSuccessAction>
  | PutEffect<MachineDetailsRobotExportCtrPdfErrorAction>
  | PutEffect<MachineDetailsRobotPollGetExportCtrPdfRequestAction>,
  void,
  IDependencies
> {
  const { cleaningReportService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(cleaningReportService.requestRobotDetailsReportsExport, action.payload);
    yield* call(toastService.success, {
      message: t('cleaningReportList.toast.success.message'),
      description: t('cleaningReportList.toast.success.description'),
    });
    const requestId = response?.robotDetailsReportsExportRequest?.data?.requestId;
    if (!requestId) {
      throw new Error('No requestId!');
    }

    yield* put(MachineDetailsPanelRobotActions.machineDetailsRobotPollGetExportCtrPdfRequestAction({ requestId }));
    yield* put(MachineDetailsPanelRobotActions.machineDetailsRobotExportCtrPdfSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      MachineDetailsPanelRobotActions.machineDetailsRobotExportCtrPdfError({
        error,
      })
    );
  }
}

export function* machineDetailsPanelRobotSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(MachineDetailsPanelRobotActions.machineDetailsRobotGetRoutesNameListRequest, getRoutesNameListSaga);
  yield* takeLatest(
    MachineDetailsPanelRobotActions.machineDetailsRobotListCleaningDataCleanedAreaRequest,
    getCleaningTaskCleanedAreaByDayListSaga
  );
  yield* takeLatest(
    MachineDetailsPanelRobotActions.machineDetailsRobotListCleaningDataCleanedHrsRequest,
    getCleaningTaskCleanedHrsByDayListSaga
  );
  yield* takeLatest(
    MachineDetailsPanelRobotActions.machineDetailsRobotListCleaningDataConsumablesRequest,
    getCleaningTaskConsumablesByDayListSaga
  );
  yield* takeLatest(
    MachineDetailsPanelRobotActions.machineDetailsRobotListCleaningDataDistanceDrivenRequest,
    getCleaningTaskDistanceDrivenByDayListSaga
  );
  yield* takeLatest(
    MachineDetailsPanelRobotActions.machineDetailsRobotCleaningConsumptionSummaryRequest,
    getRobotCleaningConsumptionSummarySaga
  );
  yield* takeLatest(
    MachineDetailsPanelRobotActions.machineDetailsRobotTaskCompletionHistoryRequest,
    getTasksCompletionHistorySaga
  );
  yield* takeLatest(MachineDetailsPanelRobotActions.machineDetailsRobotCleaningKPIsRequest, getRobotCleaningKPIsSaga);
  yield* takeLatest(MachineDetailsPanelRobotActions.machineDetailsRobotCleaningTaskReportsRequest, getRobotCTRListSaga);
  yield* takeLatest(
    MachineDetailsPanelRobotActions.machineDetailsRobotCleaningTaskReportRouteImageRequest,
    getCleaningReportRobotRouteImageSaga
  );
  yield* takeLatest(
    MachineDetailsPanelRobotActions.machineDetailsRobotPollGetExportCtrPdfRequestAction,
    pollGetMachineRobotDetailsCtrPdfSaga
  );
  yield* takeLatest(
    MachineDetailsPanelRobotActions.machineDetailsRobotExportCtrPdfRequest,
    requestExportMachineRobotDetailsCtrPdfSaga
  );
  yield* takeEvery(MachineDetailsPanelRobotActions.subscribeToMachineUpdate, subscribeToMachineUpdate);
}
