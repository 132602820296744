import styled from 'styled-components';

export const StyledMachineRobotCleaningTaskReport = styled.div`
  border: 1px solid ${(props): string => props.theme.colors.brightGrey};
  padding: ${(props): string => props.theme.spacing.lg};

  .machine-robot-cleaning-task-report__header {
    padding-bottom: ${(props): string => props.theme.spacing.md};
    ${(props): string => props.theme.fontStyles.redesign.xl.strong};
    text-transform: capitalize;
    font-weight: 700;
    margin: 0;
  }

  .machine-robot-cleaning-task-report__statistic-row {
    margin-bottom: ${(props): string => props.theme.spacing.base};
    padding-top: 0;
  }
`;
