import { Col, Row } from 'antd';
import { MachineDetailsNotificationList } from '../../MachineDetailsNotificationList/MachineDetailsNotificationList';
import { StyledMachineDetailsRobotOverview } from './MachineDetailsRobotOverview.styles';
import { MachineDetailsTaskCompletedHistory } from './MachineDetailsTaskCompletionHistory/MachineDetailsTaskCompletionHistory';
import { ThemeConstants } from 'config/theme';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { MachineDetailsMap } from 'app/modules/machine-inventory/components/MachineDetailsMap/MachineDetailsMap';
import { MachineDetailsInfo } from 'app/modules/machine-inventory/machine-details/components/MachineDetailsInfo/MachineDetailsInfo';
import { EditMachineInfoModal } from 'app/modules/machine-inventory/modals/components/EditMachineInfo/EditMachineInfoModal';

interface MachineDetailsRobotOverviewProps {
  robot: Machine;
}

export const MachineDetailsRobotOverview = ({ robot }: MachineDetailsRobotOverviewProps): JSX.Element => (
  <StyledMachineDetailsRobotOverview>
    <Row gutter={[ThemeConstants.BASE_GAP, ThemeConstants.BASE_GAP]}>
      <Col xs={12} sm={12} md={12} lg={12}>
        <MachineDetailsTaskCompletedHistory robot={robot} />
      </Col>

      <Col xs={12} sm={12} md={12} lg={12}>
        <MachineDetailsMap machine={robot} />
      </Col>

      <Col xs={24} sm={24} md={24} lg={24}>
        <MachineDetailsInfo machine={robot} />
      </Col>

      <Col xs={24} sm={24} md={24} lg={24}>
        <MachineDetailsNotificationList machine={robot} />
      </Col>
    </Row>
    <EditMachineInfoModal machine={robot} />
  </StyledMachineDetailsRobotOverview>
);
