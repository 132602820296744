import styled from 'styled-components';

export const StyledMachineAttachment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  .machine-attachment__card {
    display: flex;
    height: 96px;
    padding: 8px;

    color: ${(props): string => props.theme.colors.white};
    background-color: ${(props): string => props.theme.colors.secondary};
  }

  .machine-attachment__card-content {
    width: 100%;
    position: relative;
  }

  .machine-attachment__card-extension {
    width: fit-content;
    position: absolute;
    padding: 8px;
    margin-top: 4px;
    ${(props): string => props.theme.fontStyles.heading4}
    text-transform: uppercase;

    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: all 200ms;
  }

  .machine-attachment__card-menu {
    position: absolute;
    inset: 0 0 auto auto;
    width: auto;
    padding: 0;
    color: ${(props): string => props.theme.colors.white};
    font-size: 18px;

    .ant-dropdown-menu {
      svg {
        color: ${(props): string => props.theme.colors.black};
      }

      path {
        fill: currentColor;
      }

      .ant-dropdown-menu-item {
        padding: 0;
      }
    }
  }

  .machine-attachment__card-menu-icon {
    & > svg {
      transform: rotate(90deg);
    }
  }

  .machine-attachment__button-bar {
    display: flex;
    position: absolute;
    flex-direction: row;
    gap: 8px;
    justify-content: start;
    align-items: center;
    bottom: 0;
    left: 0;
    padding: 8px;
    opacity: 0;
    transition: all 200ms;
  }

  .machine-attachment__button {
    width: 15px;
    height: 17px;
    padding: 0;

    svg {
      color: ${(props): string => props.theme.colors.white};
    }
  }

  .machine-attachment__preview-button {
    width: 19px;
    margin-top: -2px;

    i {
      font-size: 17px;
      line-height: 17px;

      &::before {
        width: 17px;
        height: 17px;
        color: ${(props): string => props.theme.colors.white};
      }
    }
  }

  .machine-attachment__download-button {
    // Icons appear visually very different in size so we increase the
    // calculated size of the download button to make them appear similar
    width: 21px;
    height: 24px;
  }

  .machine-attachment__delete-button {
    margin-top: -4px;
  }

  .machine-attachment-menu__delete-button {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .machine-attachment__info {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-content: center;
    height: 16px;
    gap: 4px;
  }

  .machine-attachment__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    height: 16px;
    padding: 4px 3px 3px 3px;

    color: ${(props): string => props.theme.colors.white};
    background-color: ${(props): string => props.theme.colors.black};
    ${(props): string => props.theme.fontStyles.captionSmall}
    font-size: 10px;

    overflow: hidden;
  }

  .machine-attachment__icon-extension {
    margin-top: 1px;
    text-transform: uppercase;
  }

  .machine-attachment__title {
    margin-top: 2px;
    flex-grow: 1;
    flex-shrink: 1;
    ${(props): string => props.theme.mixins.truncateText}
  }

  &.machine-attachment--size-full-width {
    width: 154px;
  }

  &.machine-attachment--size-large {
    width: 139px;
  }

  &.machine-attachment--size-medium {
    width: 139px;
  }

  &.machine-attachment--size-small {
    width: 131px;
  }

  &:hover {
    .machine-attachment__card-extension {
      top: 0;
      left: 0;
      transform: translateX(0%) translateY(0%);
    }

    .machine-attachment__button-bar {
      opacity: 1;
    }
  }
`;
