import styled from 'styled-components';

export const StyledMachineDetailsMap = styled.div`
  height: 100%;
  min-height: 300px;
  position: relative;

  .machine-details-map__map {
    height: 100%;
  }

  .machine-details-map__map-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    // Code to set the background color of a button to white with a 75% opacity. This
    // is used to make the overlay look disabled.
    background: #ffffffbd;
  }

  p.machine-details-map__map-overlay-text {
    ${(props): string => props.theme.fontStyles.p1.medium}
    color: ${(props): string => props.theme.colors.darkGrey};
    width: 200px;
    text-align: center;
  }

  .machine-details-map-legend__info-icon-container {
    position: absolute;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;

    top: 0;
    left: 0;

    width: 40px;
    height: 40px;
    margin: 10px;
    background-color: ${(props): string => props.theme.colors.white};
    border-radius: 3px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 30%);

    .machine-details-map-legend__info-icon {
      height: 22px;
      width: 22px;
    }
  }
`;
