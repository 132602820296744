import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { LegacyRangePicker } from '../../../../../../lib/components/LegacyRangePicker/LegacyRangePicker';
import { CleaningReportRequestDateRangeRestrictor } from '../../../../cleaning/CleaningReportRequestDateRangeRestrictor';
import { END_DATE, START_DATE } from '../../../../cleaning/utils/OperatingHoursChartUtils';

interface IMachineCleaningDataHeaderForm {
  dateRangeLocal: [Dayjs, Dayjs];
}

interface MachineCleaningDataHeaderProps {
  onRangePickerChange: (dates: [Dayjs | null, Dayjs | null] | null) => void;
  startAt?: Date;
  endAt?: Date;
}

export const MachineCleaningDataHeader = (props: MachineCleaningDataHeaderProps): JSX.Element => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { onRangePickerChange, startAt, endAt } = props;

  useEffect(() => {
    form.setFieldsValue({
      dateRangeLocal: [dayjs(startAt), dayjs(endAt)],
    });
  }, [endAt, form, startAt]);

  // Reset form fields when component is unmounted
  useEffect(() => () => form.resetFields(), [form]);

  const onFinish = (values: IMachineCleaningDataHeaderForm): void => {
    const { dateRangeLocal: dates } = values;

    if (dates && dates[0] && dates[1]) {
      onRangePickerChange(dates);
    }
  };

  return (
    <div className="machine-cleaning-data-filter__filter">
      <div className="machine-cleaning-data-filter__range-picker">
        <Form
          form={form}
          className="machine-cleaning-data-filter__filter-form"
          name="machine-cleaning-data-filter__filter-form"
          layout="horizontal"
          onFinish={onFinish}
          onValuesChange={(): void => {
            form.submit();
          }}
          autoComplete="off"
          validateTrigger="onSubmit"
          initialValues={{
            dateRangeLocal: [dayjs(startAt), dayjs(endAt)],
          }}
        >
          <Form.Item
            name="dateRangeLocal"
            required
            rules={[
              {
                required: true,
                message: t('cleaningReportRequestDateRangeRestrictor.errors.dateRangeRequired'),
              },
              {
                validator: CleaningReportRequestDateRangeRestrictor.validate,
              },
            ]}
          >
            <LegacyRangePicker
              value={[dayjs(startAt || START_DATE).local(), dayjs(endAt || END_DATE).local()]}
              disabledDate={CleaningReportRequestDateRangeRestrictor.disabledDatePredicate}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
