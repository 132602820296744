import styled from 'styled-components';

export const StyledMachineDetailsRobotDistanceDrivenChart = styled.div`
  padding-bottom: 32px;
  .robot-cleaned-distance-driven-chart__spin {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 32px;
  }

  .robot-distance-driven-chart__unit {
    display: flex;
    justify-content: space-between;
    color: ${(props): string => props.theme.colors.grey};
    font-family: ${(props): string => props.theme.fonts.captionNews};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    line-height: ${(props): string => props.theme.lineHeights.caption};
    font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
  }

  .machine-robots-cleaning-data-chart__chart-note {
    margin-top: ${(props): string => props.theme.spacing.base};
  }
`;
