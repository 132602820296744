import styled from 'styled-components';

export const StyledMachineRobotFilter = styled.div`
  width: 100%;

  .machine-robot-filter {
    .ant-select {
      width: 75%;

      .ant-select-selector {
        background-color: ${(props): string => props.theme.colors.white};
      }
    }
  }

  .machine-robot-filter__truncate-text {
    width: 162px;

    .ant-select-selection-overflow-item > * {
      ${(props): string => props.theme.mixins.truncateText}
    }
  }

  .machine-robot-filter__form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: ${(props): string => props.theme.spacing.base};
  }

  .ant-form-item .ant-form-item-control-input {
    min-height: 32px;
  }

  .ant-form-item {
    margin: 0;
  }

  .machine-robot-filter__select-wrapper--route-name {
    width: 200px;
  }

  .ant-select-single {
    .ant-select-arrow {
      margin-top: -5px !important;
    }
  }

  .machine-robot-filter__select {
    width: 75%;
    height: 32px;

    .ant-select-selector {
      .ant-select-selection-overflow {
        display: flex;
        flex-wrap: nowrap;
      }
    }

    .machine-robot-filter__select-option__unavailable {
      opacity: 0.5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .machine-robot-filter__title {
        width: 92px;
        ${(props): string => props.theme.mixins.truncateText}
      }
      .machine-robot-filter__icon-info {
        margin-top: 3px;
      }
      .machine-robot-filter__icon {
        svg {
          height: 16px;
          width: 16px;
        }
      }
    }

    .machine-robot-filter__title {
      ${(props): string => props.theme.mixins.truncateText}
    }

    .ant-select-item-option-selected {
      svg {
        path {
          fill: ${(props): string => props.theme.colors.white};
        }
      }
    }
  }

  .ant-picker-date-panel {
    border: none;
  }

  .ant-picker-range-wrapper {
    .ant-picker-panel-container {
      .ant-picker-panel-layout {
        .ant-picker-presets {
          ul {
            width: 100%;
          }
          li {
            word-wrap: wrap;
          }
        }
      }
    }
  }
`;
