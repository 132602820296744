import React from 'react';
import { useTranslation } from 'react-i18next';
// TODO: Rename MachineOperatingHoursChartHeader
export const MachineOperatingHoursChartHeader = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="machine-operating-hours-chart__header">
      <div className="machine-operating-hours-chart__legend">
        <div className="machine-operating-hours-chart__chart-note">
          <div className="machine-operating-hours-chart__chart-note-left">
            <div className="machine-operating-hours-chart__chart-note-square-left" />
            <p className="machine-operating-hours-chart__chart-note-text">
              {t('machineDetails.operatingHoursChart.actual')}
            </p>
          </div>
          <div className="machine-operating-hours-chart__chart-note-right">
            <div className="machine-operating-hours-chart__chart-note-square-right" />
            <p className="machine-operating-hours-chart__chart-note-text">
              {t('machineDetails.operatingHoursChart.planned')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
