import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { isString } from 'lodash-es';
import { StatusPill, StatusPillProps } from '../../../../../lib/components/StatusPill/StatusPill';
import { IMachineStatusItemData, MachineStatusItemData } from '../../interfaces/Machine.types';
import { getMachineStatus } from '../../utils/getMachineStatus';
import { StyledMachineStatusIndicator } from './MachineStatusIndicator.styles';
import { MachineStatusIndicatorTooltip } from './MachineStatusIndicatorTooltip';
import { MachineStatusIndicatorGlobalStyles } from './MachineStatusIndicator.global.styles';
import { Tooltip } from 'lib/components/Tooltip/Tooltip';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { Optional } from 'lib/types/Optional';
import { DataStatus } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface MachineStatusIndicatorProps {
  className?: string;
  lastActivityAt?: string | null;
  status?: DataStatus;
  showText?: boolean;
  size?: 'small' | 'medium';
}

export const MachineStatusIndicator = ({
  className,
  lastActivityAt,
  status,
  showText,
  size = 'medium',
}: MachineStatusIndicatorProps): React.JSX.Element | null => {
  const { t } = useTranslation();

  const getStatusPillSize = (): StatusPillProps['size'] => {
    if (size === 'medium') {
      return 'large';
    }

    return 'small';
  };

  const statusItem = useMemo((): Optional<IMachineStatusItemData> => {
    let machineStatus = null;

    if (isString(status)) return MachineStatusItemData[status];

    if (!isString(lastActivityAt)) {
      return MachineStatusItemData[DataStatus.NoData];
    }

    machineStatus = getMachineStatus(lastActivityAt);

    if (!machineStatus) {
      return null;
    }

    return machineStatus;
  }, [lastActivityAt, status]);

  if (!statusItem) {
    return null;
  }

  return (
    <StyledMachineStatusIndicator
      className={classnames(className, 'machine-connection-status', `machine-connection-status--size-${size}`)}
    >
      <MachineStatusIndicatorGlobalStyles />
      <Tooltip
        title={<MachineStatusIndicatorTooltip statusItem={statusItem} />}
        overlayClassName="machine-connection-status-tooltip__overlay"
      >
        <div className={`machine-connection-status__tooltip-wrapper`}>
          <StatusPill color={statusItem.color} size={getStatusPillSize()} className={`machine-connection-status__pill`}>
            <SvgIcon name={statusItem.icon} className={`machine-connection-status__icon`} />
          </StatusPill>
          {showText && <span className={`machine-connection-status__text`}>&nbsp; {t(statusItem.title)}</span>}
        </div>
      </Tooltip>
    </StyledMachineStatusIndicator>
  );
};
