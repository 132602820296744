import { ApolloQueryResult, FetchResult, gql } from '@apollo/client';
import { inject, injectable } from 'tsyringe';
import {
  RobotDashboardKPIsResponse,
  RobotDashboardTotalCleanedAreaResponse,
  RobotDashboardTotalCleanedHourResponse,
  RobotDashboardTasksCompletionStatisticResponse,
  RobotCleaningTaskCleanedAreaByDayListResponse,
  RobotRequestVncResponse,
  RobotGetVncStatusResponse,
  RobotCleaningTaskCleanedHrsByDayListResponse,
  RobotCleaningTaskConsumablesByDayListResponse,
  RobotCleaningTaskDistanceDrivenByDayListResponse,
  RoutesNameListResponse,
  RobotRequestVncResponseV2,
  RobotGetVncStatusV2Response,
} from './interfaces/Robot.types';
import { GraphQlClient } from 'app/cross-cutting-concerns/communication/clients/GraphQlClient';
import {
  QueryCleaningTaskCleanedAreaByDayListArgs,
  MachineVncRequestInput,
  MachineVncStatusGetInput,
  QueryRobotCleaningRoutesListArgs,
  QueryRobotDashboardKpIsArgs,
  QueryRobotDashboardTotalCleanedAreaArgs,
  QueryRobotDashboardTotalCleanedHrsArgs,
  QueryRobotTaskCompletionStatisticArgs,
  QueryCleaningTaskCleanedHrsByDayListArgs,
  QueryCleaningTaskConsumablesByDayListArgs,
  QueryCleaningTaskDistanceDrivenByDayListArgs,
  MachineVncStatusGetInputV2,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export type RobotClientListRobotsWithCleanedAreaOptions = QueryRobotDashboardTotalCleanedAreaArgs;
export type RobotClientListRobotsWithTotalCleanedHourOptions = QueryRobotDashboardTotalCleanedHrsArgs;
export type RobotClientListRobotsKPIsOptions = QueryRobotDashboardKpIsArgs;
export type RobotClientGetRobotDashboardTasksCompletionOptions = QueryRobotTaskCompletionStatisticArgs;
export type RobotClientRequestRobotVncOptions = MachineVncRequestInput;
export type RobotClientGetRobotVncStatusOptions = MachineVncStatusGetInput;
export type RobotClientGetRobotVncStatusV2Options = MachineVncStatusGetInputV2;

export type RobotClientGetCleaningTaskCleanedAreaByDayListOptions = QueryCleaningTaskCleanedAreaByDayListArgs;
export type RobotClientGetCleaningTaskCleanedHrsByDayListOptions = QueryCleaningTaskCleanedHrsByDayListArgs;
export type RobotClientGetCleaningTaskConsumablesByDayListOptions = QueryCleaningTaskConsumablesByDayListArgs;
export type RobotClientGetCleaningTaskDistanceDrivenByDayListOptions = QueryCleaningTaskDistanceDrivenByDayListArgs;

@injectable()
export class RobotClient {
  constructor(@inject('GraphQlClient') private graphQlClient: GraphQlClient) {}

  public listRobotsWithCleanedArea = async ({
    filter,
    paginationOptions,
  }: RobotClientListRobotsWithCleanedAreaOptions): Promise<ApolloQueryResult<RobotDashboardTotalCleanedAreaResponse>> =>
    this.graphQlClient.query({
      query: gql`
        query robotDashboardTotalCleanedArea(
          $filter: InputFilterRobotDashboardTotalCleanedAreaGet!
          $paginationOptions: InputPagingOptions!
        ) {
          robotDashboardTotalCleanedArea(filter: $filter, paginationOptions: $paginationOptions) {
            metadata {
              totalCount
              paginationToken
            }
            data {
              cleanedArea
              machineId
              machineName
            }
          }
        }
      `,
      variables: {
        filter,
        paginationOptions,
      },
    });

  public listRobotsWithTotalCleanedHour = async ({
    filter,
    paginationOptions,
  }: RobotClientListRobotsWithTotalCleanedHourOptions): Promise<
    ApolloQueryResult<RobotDashboardTotalCleanedHourResponse>
  > =>
    this.graphQlClient.query({
      query: gql`
        query robotDashboardTotalCleanedHrs(
          $filter: InputFilterRobotDashboardTotalCleanedHrsGet!
          $paginationOptions: InputPagingOptions!
        ) {
          robotDashboardTotalCleanedHrs(filter: $filter, paginationOptions: $paginationOptions) {
            metadata {
              totalCount
              paginationToken
            }
            data {
              cleanedHrs
              machineId
              machineName
            }
          }
        }
      `,
      variables: {
        filter,
        paginationOptions,
      },
    });

  public robotsKPIs = async ({
    filter,
    paginationOptions,
  }: RobotClientListRobotsKPIsOptions): Promise<ApolloQueryResult<RobotDashboardKPIsResponse>> =>
    this.graphQlClient.query({
      query: gql`
        query RobotDashboardKPIs($filter: InputFilterRobotDashboardKPIsGet!, $paginationOptions: InputPagingOptions!) {
          robotDashboardKPIs(filter: $filter, paginationOptions: $paginationOptions) {
            data {
              distanceDriven
              taskCoverage
              tasksCompleted
              totalCleanedArea
              totalCleaningHrs
            }
          }
        }
      `,
      variables: {
        filter,
        paginationOptions,
      },
    });

  public getRobotDashboardTasksCompletion = async ({
    filter,
  }: RobotClientGetRobotDashboardTasksCompletionOptions): Promise<
    ApolloQueryResult<RobotDashboardTasksCompletionStatisticResponse>
  > =>
    this.graphQlClient.query({
      query: gql`
        query robotTaskCompletionStatistic($filter: InputFilterRobotTaskCompletionStatisticGet!) {
          robotTaskCompletionStatistic(filter: $filter) {
            data {
              completed
              interrupted
              stopped
            }
          }
        }
      `,
      variables: {
        filter,
      },
    });

  public requestRobotVnc = async ({
    machineId,
  }: RobotClientRequestRobotVncOptions): Promise<FetchResult<RobotRequestVncResponse>> =>
    this.graphQlClient.mutate({
      mutation: gql`
        mutation requestMachineVnc($input: MachineVncRequestInput!) {
          machineVncRequest(input: $input) {
            data {
              url
              expiresAt
              machineId
            }
          }
        }
      `,
      variables: {
        input: {
          machineId,
        },
      },
    });

  public requestRobotVncV2 = async ({
    machineId,
  }: RobotClientRequestRobotVncOptions): Promise<FetchResult<RobotRequestVncResponseV2>> =>
    this.graphQlClient.mutate({
      mutation: gql`
        mutation machineVncRequestV2($input: MachineVncRequestInput!) {
          machineVncRequestV2(input: $input) {
            data {
              token
              region
              expiresAt
              sessionId
            }
          }
        }
      `,
      variables: {
        input: {
          machineId,
        },
      },
    });

  public getRobotVncStatus = async ({
    machineId,
  }: RobotClientGetRobotVncStatusOptions): Promise<ApolloQueryResult<RobotGetVncStatusResponse>> =>
    this.graphQlClient.query({
      query: gql`
        query machineVncStatusGet($input: MachineVncStatusGetInput!) {
          machineVncStatusGet(input: $input) {
            data {
              status
              code
            }
          }
        }
      `,
      variables: {
        input: {
          machineId,
        },
      },
    });

  public getRobotVncStatusV2 = async ({
    machineId,
    sessionId,
  }: RobotClientGetRobotVncStatusV2Options): Promise<ApolloQueryResult<RobotGetVncStatusV2Response>> =>
    this.graphQlClient.query({
      query: gql`
        query machineVncStatusGetV2($input: MachineVncStatusGetInputV2!) {
          machineVncStatusGetV2(input: $input) {
            data {
              status
              code
            }
          }
        }
      `,
      variables: {
        input: {
          machineId,
          sessionId,
        },
      },
    });

  public listRoutesName = async ({
    filter,
  }: QueryRobotCleaningRoutesListArgs): Promise<ApolloQueryResult<RoutesNameListResponse>> =>
    this.graphQlClient.query({
      query: gql`
        query RobotCleaningRoutesList($filter: InputFilterRobotCleaningRoutesList!) {
          robotCleaningRoutesList(filter: $filter) {
            data {
              unavailableRoute {
                recentFinishedAt
                routeName
              }
              validRoute {
                recentFinishedAt
                routeName
              }
            }
          }
        }
      `,
      variables: {
        filter,
      },
    });

  public getCleaningTaskCleanedAreaByDayList = async ({
    filter,
  }: RobotClientGetCleaningTaskCleanedAreaByDayListOptions): Promise<
    ApolloQueryResult<RobotCleaningTaskCleanedAreaByDayListResponse>
  > =>
    this.graphQlClient.query({
      query: gql`
        query CleaningTaskCleanedAreaByDayList($filter: InputFilterCleaningTaskCleanedAreaByDayList!) {
          cleaningTaskCleanedAreaByDayList(filter: $filter) {
            data {
              cleanedAreaList {
                cleanedArea
                finishedAt
              }
              cleanedAreaSummary {
                totalCleanedArea
                periodCleanedArea
              }
            }
          }
        }
      `,
      variables: {
        filter,
      },
    });

  public getCleaningTaskCleanedHrsByDayList = async ({
    filter,
  }: RobotClientGetCleaningTaskCleanedHrsByDayListOptions): Promise<
    ApolloQueryResult<RobotCleaningTaskCleanedHrsByDayListResponse>
  > =>
    this.graphQlClient.query({
      query: gql`
        query CleaningTaskCleanedHrsByDayList($filter: InputFilterCleaningTaskCleanedHrsByDayList!) {
          cleaningTaskCleanedHrsByDayList(filter: $filter) {
            data {
              cleanedHrsList {
                cleanedHrs
                date
              }
              cleanedHrsSummary {
                periodCleanedHrs
                totalCleanedHrs
              }
            }
          }
        }
      `,
      variables: {
        filter,
      },
    });

  public getCleaningTaskConsumablesByDayList = async ({
    filter,
  }: RobotClientGetCleaningTaskConsumablesByDayListOptions): Promise<
    ApolloQueryResult<RobotCleaningTaskConsumablesByDayListResponse>
  > =>
    this.graphQlClient.query({
      query: gql`
        query CleaningTaskConsumablesByDayList($filter: InputFilterCleaningTaskConsumablesByDayList!) {
          cleaningTaskConsumablesByDayList(filter: $filter) {
            data {
              consumablesList {
                finishedAt
                consumptions {
                  battery
                  detergents
                  water
                }
              }
              consumablesSummary {
                periodBattery
                periodDetergents
                periodWater
              }
            }
          }
        }
      `,
      variables: {
        filter,
      },
    });

  public getCleaningTaskDistanceDrivenByDayList = async ({
    filter,
  }: RobotClientGetCleaningTaskDistanceDrivenByDayListOptions): Promise<
    ApolloQueryResult<RobotCleaningTaskDistanceDrivenByDayListResponse>
  > =>
    this.graphQlClient.query({
      query: gql`
        query CleaningTaskDistanceDrivenByDayList($filter: InputFilterCleaningTaskDistanceDrivenByDayList!) {
          cleaningTaskDistanceDrivenByDayList(filter: $filter) {
            data {
              distanceDrivenList {
                distanceDriven
                finishedAt
              }
              distanceDrivenSummary {
                totalDistanceDriven
                periodDistanceDriven
              }
            }
          }
        }
      `,
      variables: {
        filter,
      },
    });
}
