import { Col, Row } from 'antd';
import { MachineRobotNameBanner } from './MachineRobotNameBanner/MachineRobotNameBanner';
import { MachineRobotStatus } from './MachineRobotStatus/MachineRobotStatus';
import { MachineRobotFilter } from './MachineRobotFilter/MachineRobotFilter';
import { MachineRobotCleaningTaskReport } from './MachineRobotCleaningTaskReport/MachineRobotCleaningTaskReport';
import { MachineRobotCleaningDataChart } from './MachineRobotCleaningDataChart/MachineRobotCleaningDataChart';
import { StyledMachineDetailsRobotCleaningData } from './MachineDetailsRobotCleaningData.styles';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { ThemeConstants } from 'config/theme';

interface MachineDetailsRobotPanelCleaningDataProps {
  robot: Machine;
}

export const MachineDetailsRobotCleaningData = ({ robot }: MachineDetailsRobotPanelCleaningDataProps): JSX.Element => (
  <StyledMachineDetailsRobotCleaningData>
    <Row gutter={[ThemeConstants.XL_GAP, ThemeConstants.BASE_GAP]}>
      <Col xs={12} sm={12} md={12} lg={12}>
        <MachineRobotNameBanner robot={robot} />
      </Col>

      <Col xs={12} sm={12} md={12} lg={12}>
        <MachineRobotStatus robot={robot} />
      </Col>

      <Col xs={18} sm={18} md={18} lg={18}>
        <MachineRobotFilter robot={robot} />
      </Col>

      <Col xs={24} sm={24} md={24} lg={24}>
        <MachineRobotCleaningTaskReport robot={robot} />
      </Col>

      <Col xs={24} sm={24} md={24} lg={24}>
        <MachineRobotCleaningDataChart robot={robot} />
      </Col>
    </Row>
  </StyledMachineDetailsRobotCleaningData>
);
