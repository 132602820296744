import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ThemeConstants } from '../../../../../../../config/theme';
import { MachineCleaningData } from '../../../../components/MachineCleaningData/MachineCleaningData';
import { MachineDetailsMap } from '../../../../components/MachineDetailsMap/MachineDetailsMap';
import { MachineNameBanner } from '../../../../components/MachineNameBanner/MachineNameBanner';
import { MachineDetailsInfo } from '../../../../machine-details/components/MachineDetailsInfo/MachineDetailsInfo';
import { MachineDetailsNonIotBanner } from '../../../../machine-details/components/MachineDetailsNonIotBanner/MachineDetailsNonIotBanner';
import { MachineDetailsNotificationList } from '../../MachineDetailsNotificationList/MachineDetailsNotificationList';
import { MachineNoteList } from '../../../../machine-details/components/MachineNoteList/MachineNoteList';
import { MachineReminderList } from '../../../../machine-details/components/MachineReminderList/MachineReminderList';
import { MachineDetailsPanelStatus } from '../../MachineDetailsPanelStatus/MachineDetailsPanelStatus';
import { StyledMachineDetailsOverviewGcd } from './MachineDetailsOverviewGcd.styles';
import * as machineListSelectors from 'app/modules/machine-inventory/machine-list/state/machineListSelectors';
import { EditMachineInfoModal } from 'app/modules/machine-inventory/modals/components/EditMachineInfo/EditMachineInfoModal';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { Optional } from 'lib/types/Optional';
import { FeatureFlagSelectors } from 'app/cross-cutting-concerns/feature-flags/state/featureFlagSelectors';

export const MachineDetailsOverviewGcd = (machine: Optional<Machine>, id: string): React.JSX.Element => {
  const { t } = useTranslation();
  const isIoTDevice = machine?.isIoTDevice;
  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const activePeriodFilter = useSelector(machineListSelectors.selectActivePeriodFilter);

  if (!machine) return t('machineDetails.error.machineNotFound');

  return (
    <StyledMachineDetailsOverviewGcd>
      <Row gutter={[ThemeConstants.BASE_GAP, ThemeConstants.BASE_GAP]}>
        {!isIoTDevice && (
          <Col xs={24} sm={24} md={24} lg={24}>
            <MachineDetailsNonIotBanner />
          </Col>
        )}

        <Col xs={24} sm={24} md={24} lg={24}>
          <div className="machine-details-panel-gcd__main-data">
            <div className="machine-details-panel-gcd__main-data-left">
              <MachineNameBanner machine={machine} />
              <MachineDetailsPanelStatus machine={machine} className="machine-details-panel-gcd__status" />
            </div>
            <div className="machine-details-panel-gcd__main-data-right">
              <div className="machine-details-panel-gcd__map">
                <MachineDetailsMap machine={machine} />
              </div>
            </div>
          </div>
        </Col>

        {features.MACHINE_NOTIFICATIONS && isIoTDevice && (
          <Col xs={24} sm={24} md={24} lg={24}>
            <MachineDetailsNotificationList machine={machine} />
          </Col>
        )}

        <Col xs={24} sm={24} md={24} lg={24} className="machine-details-panel-gcd__info">
          <MachineDetailsInfo machine={machine} />
        </Col>

        {features.MACHINE_DETAILS_OPERATING_HOURS_CHART && isIoTDevice && (
          <Col xs={24} sm={24} md={24} lg={24}>
            <MachineCleaningData id={id} machine={machine} period={activePeriodFilter} />
          </Col>
        )}
        {features.MACHINE_REMINDER_LIST && (
          <Col span={12}>
            <MachineReminderList machine={machine} />
          </Col>
        )}
        {features.MACHINE_NOTE_LIST && (
          <Col span={12}>
            <MachineNoteList machine={machine} />
          </Col>
        )}
      </Row>
      <EditMachineInfoModal machine={machine} />
    </StyledMachineDetailsOverviewGcd>
  );
};
